import { RepositoryFactory } from "@/repository/RepositoryFactory";
const lessonRepository = RepositoryFactory.get("lesson");
import { errorHandlingAndMessage } from "@/services/ErrorService";
import i18n from "@/i18n";

const state = {
  showSearchModal: false,
  searchQuery: "",
  searchResult: [],
  searchStepId: null,
  searchKind: null,
  searchTarget: null,
  searchTargetId: null,
  actions: [],
  linearSteps: [],
  nonLinearSteps: [],
};

const getters = {};

const actions = {
  SEARCH: ({ commit, state, dispatch }, payload) => {
    commit("SEARCH_QUERY", payload.query.query);
    return new Promise((resolve, reject) => {
      lessonRepository
        .search(payload.lessonId, payload.query)
        .then((result) => {
          const actions = result.data.actions;
          const linearSteps = result.data.steps.filter((step) => {
            return step.type === "linear";
          });
          const nonLinearSteps = result.data.steps.filter((step) => {
            return step.type === "nonLinear";
          });

          commit("SEARCH", { actions, linearSteps, nonLinearSteps });

          resolve();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_while_fetching_search_results"
            )
          );
          reject(err);
        });
    });
  },
  SHOW_SEARCH_MODAL: ({ commit, dispatch }) => {
    commit("SHOW_SEARCH_MODAL");
  },
  HIDE_SEARCH_MODAL: ({ commit, dispatch }) => {
    commit("HIDE_SEARCH_MODAL");
  },
  SET_PICKED_RESULT: ({ commit, dispatch }, payload) => {
    commit("SET_PICKED_RESULT", payload);
  },
  CLEAR_PICKED_RESULT: ({ commit, dispatch }, payload) => {
    commit("CLEAR_PICKED_RESULT", payload);
  },
  CLEAR_SEARCH: ({ commit, dispatch }) => {
    commit("CLEAR_SEARCH");
  },
};

const mutations = {
  SHOW_SEARCH_MODAL: (state, payload) => {
    state.showSearchModal = true;
  },
  HIDE_SEARCH_MODAL: (state, payload) => {
    state.showSearchModal = false;
  },
  SEARCH: (state, payload) => {
    state.actions = payload.actions;
    state.linearSteps = payload.linearSteps;
    state.nonLinearSteps = payload.nonLinearSteps;
  },
  SET_PICKED_RESULT: (state, payload) => {
    state.searchTarget = payload.searchTarget;
    state.searchKind = payload.searchKind;
    state.searchTargetId = payload.searchTargetId;
    state.searchStepId = payload.searchStepId;
  },
  CLEAR_PICKED_RESULT: (state, payload) => {
    state.searchStepId = null;
    state.searchKind = null;
    state.searchTarget = null;
    state.searchTargetId = null;
  },
  CLEAR_SEARCH: (state, payload) => {
    state.actions = [];
    state.linearSteps = [];
    state.nonLinearSteps = [];
  },
  SEARCH_QUERY: (state, payload) => {
    state.searchQuery = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
