import { showNotification } from "@/services/notificationService";

export default {
  methods: {
    copyId() {
      const targetId =
        this.activeType === "step" ? this.activeStep.id : this.activeAction.id;
      const cb = navigator.clipboard;

      cb.writeText(targetId).then(() =>
        showNotification(
          this.$t("notifications.copy_id"),
          this.$t("notifications.copied_id_to_clipboard"),
          "success"
        )
      );
    },

    showFullTitles() {
      this.$store.dispatch("keyboardShortcutStore/TOGGLE_FULL_TITLES");
    },

    expandSteps() {
      this.$store.dispatch("keyboardShortcutStore/TOGGLE_COLLAPSE_ALL", 2);
    },
    collapseSteps() {
      this.$store.dispatch("keyboardShortcutStore/TOGGLE_COLLAPSE_ALL", 0);
    },
    copyActive() {
      this.$store.dispatch("keyboardShortcutStore/COPY_TO_CLIPBOARD", {
        lessonId: this.activeLesson.id,
        type: "copy",
        target: this.activeType === "action" ? "action" : this.activeStep.kind,
        stepId: this.activeStep.id,
        actionId: this.activeAction.id,
        sort: this.activeStep.sort,
      });
    },
    cutActive() {
      this.$store.dispatch("keyboardShortcutStore/COPY_TO_CLIPBOARD", {
        type: "cut",
        target: this.activeType === "action" ? "action" : this.activeStep.kind,
        lessonId: this.activeLesson.id,
        stepId: this.activeStep.id,
        actionId: this.activeAction.id,
        sort: this.activeStep.sort,
      });
    },
    pasteClipboard() {
      let payload;
      // determine payload, sort for steps, destinationId and sort for actions
      // switch between all possible combinations to catch edge cases
      switch (this.clipboard.target) {
        case "linear":
          switch (this.activeStep.kind) {
            case "linear":
              payload = this.activeStep.sort;
              break;
            case "nonLinear":
              const target = this.mainSteps.filter((step) =>
                step.children.some((child) => child.id === this.activeStep.id)
              )[0];
              payload =
                target.children[target.children.length - 1].sequence + 1;
              break;
          }
          break;

        case "nonLinear":
        case "setup":
          switch (this.activeStep.kind) {
            case "linear":
              const target = this.mainSteps.filter(
                (step) => step.linear.id === this.activeStep.id
              )[0];

              payload = target.children.length
                ? target.children[target.children.length - 1].sequence + 1
                : this.activeStep.sort + 1;
              break;
            case "setup":
            case "nonLinear":
              payload = this.activeStep.sort;
              break;
          }
          break;

        case "action":
          payload = {
            destinationStepId:
              this.activeType === "action"
                ? this.activeAction.stepId
                : this.activeStep.id,
            sort:
              this.activeType === "action"
                ? this.activeAction.sort
                : this.activeStep.actionAmount,
          };
          break;
      }

      this.$store.dispatch(
        "keyboardShortcutStore/PASTE_FROM_CLIPBOARD",
        payload
      );
    },
  },
};
