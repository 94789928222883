<template>
  <div
    id="details-card"
    class="mx-10 flex flex-col backgroundCard w-90% p-2 mobile:mx-1"
  >
    <div class="flex flex-row p-5 justify-between mobile:flex-col">
      <div class="flex flex-col flex-3">
        <div class="flex flex-col">
          <p
            class="font-medium text-24 text-lightAlineaColor leading-none mb-15"
          >
            {{ $t("results.score") }}
          </p>
          <div
            class="relative h-16 w-80% bg-primaryBackgroundOpacity mb-1 border-0.5 border-gray-400 overflow-hidden mobile:w-100"
          >
            <div
              class="relative h-full bg-primaryColor mb-1 justify-end items-center flex pr-10 text-lightAlineaColor font-medium text-17"
              :style="{ width: `${scorePercentage}%` }"
            >
              <p
                class="absolute z-20"
                :class="scorePercentage > 95 ? 'right-3' : 'right-0'"
              >
                {{ scorePercentage }}%
              </p>
              <div
                class="absolute top-0 right-0 bg-primaryColor h-full w-10 skew-20 -mr-3"
              ></div>
            </div>

            <div
              class="absolute top-0 left-0 h-full mb-1 justify-end items-center flex opacity-75 pr-10 border-dashed border-r-2 border-red-600"
              :style="{ width: `${passingPercentage}%` }"
              v-tooltip="{
                content: `Passing percentage: ${passingPercentage}%`,
                boundary: 'window',
                placement: 'right-start',
              }"
            ></div>
          </div>

          <p
            v-if="avgPassingPercentage"
            class="font-light text-16 leading-none text-lightAlineaColor leading-none mt-2"
          >
            {{
              `${$t(
                "results.the_average_score_of_this_level_is"
              )} ${avgPassingPercentage}%`
            }}
          </p>
        </div>
        <div class="flex flex-col">
          <p
            class="font-medium text-24 text-lightAlineaColor leading-none mb-15 mt-30"
          >
            {{ $t("results.points") }}
          </p>
          <div
            class="h-16 w-80% bg-secondaryBackgroundOpacity mb-1 border-0.5 border-gray-400 overflow-hidden mobile:w-100"
          >
            <div
              class="relative h-full bg-secondaryColor mb-1 justify-end items-center flex pr-10 text-lightAlineaColor font-medium text-17"
              :style="{ width: `${gameSession.scorePercentage}%` }"
            >
              <p
                class="absolute z-20"
                :class="
                  gameSession.scorePercentage > 95 ? 'right-3' : 'right-0'
                "
              >
                {{ gameSession.score }}
              </p>
              <div
                class="absolute top-0 right-0 bg-secondaryColor h-full w-10 skew-20 -mr-3"
              ></div>
            </div>
          </div>
          <p
            v-if="avgPassingPercentage"
            class="font-light text-16 leading-none text-lightAlineaColor leading-none mt-2"
          >
            {{
              `${$t(
                "results.the_average_score_of_this_level_is"
              )} ${averageScore} points`
            }}
          </p>
        </div>
      </div>
      <div
        class="flex flex-col flex-3 mobile:flex-row mobile:justify-between mobile:pt-30"
      >
        <div class="flex flex-1 flex-col">
          <p
            class="font-medium text-24 text-lightAlineaColor leading-none mb-15"
          >
            {{ $t("results.time") }}
          </p>
          <p
            class="font-extralight text-29 text-lightAlineaColor leading-none flex items-center mb-1"
          >
            {{ calcTime(gameSession.startTime, gameSession.stopTime) }}
          </p>
          <p class="font-light text-16 text-lightAlineaColor leading-none">
            {{ $t("results.levels") }}
          </p>
        </div>
        <div class="flex flex-1 flex-col">
          <p
            class="font-medium text-24 text-lightAlineaColor leading-none mb-15 mt-30 mobile:mt-0"
          >
            {{ $t("results.time") }}
          </p>
          <p
            class="font-extralight text-29 text-lightAlineaColor leading-none flex items-center mb-1"
          >
            {{ avgTimeSpend ? avgTimeSpend : "-" }}
          </p>
          <p class="font-light text-16 text-lightAlineaColor leading-none">
            {{ $t("results.avg_time") }}
          </p>
        </div>
      </div>

      <TrophyList
        v-if="gameSession.passed_and_finished"
        :lesson-id="gameSession.lessonId"
      />
    </div>
    <!--    <HighscoreRow-->
    <!--      :score-percentage="gameSession.passingPercentage"-->
    <!--      :score="gameSession.score"-->
    <!--      :date="gameSession.stopTime"-->
    <!--    />-->
    <div v-if="!gameSession.lessonId" class="flex m-5">
      <div class="inline-flex rounded-md shadow-sm bg-red-400 p-2">
        <p
          class="font-normal text-16 leading-none text-lightAlineaColor leading-none"
        >
          {{
            $t(
              "results.the_original_lesson_where_this_result_was_scored_has_been_removed"
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { addSeconds, differenceInSeconds, parseISO } from "date-fns";
import TrophyList from "@/components/results/TrophyList";

export default {
  name: "ScoreDetailsVue",
  components: {
    TrophyList,
  },
  props: {
    gameSession: {
      type: Object,
      default: () => {},
    },
    avgPassingPercentage: {
      type: Number,
      default: 0,
    },
    avgTimeSpend: {
      type: String,
      default: "",
    },
    failed: {
      type: Number,
      default: 0,
    },
    passed: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      scorePercentage: 0,
      passingPercentage: 0,
    };
  },
  computed: {
    averageScore: function () {
      const avg = (this.avgPassingPercentage / 100) * this.gameSession.score;
      return Number.isInteger(avg) ? avg : avg.toFixed(2);
    },
  },
  mounted() {
    this.scorePercentage = this.gameSession.scorePercentage
      ? this.gameSession.scorePercentage
      : 0;
    this.passingPercentage = this.gameSession.passingPercentage
      ? this.gameSession.passingPercentage
      : 0;
  },
  methods: {
    calcTime(start, end) {
      const seconds = differenceInSeconds(parseISO(end), parseISO(start));
      if (isNaN(seconds)) {
        return "-";
      } else {
        const helper = addSeconds(new Date(0), seconds);
        function addZero(i) {
          if (i < 10) {
            i = "0" + i;
          }
          return i;
        }

        const hours = addZero(helper.getMinutes());
        const minutes = addZero(helper.getSeconds());

        return `${hours}m ${minutes}s`;
      }
    },
  },
};
</script>

<style scoped></style>
