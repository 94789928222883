<template>
  <div
    class="flex flex-col content-between flex-1 px-4 py-5 sm:p-6 overflow-hidden"
  >
    <div class="flex flex flex-col h-full w-full">
      <div class="flex flex h-full -mb-12 flex-row w-full">
        <div class="overflow-hidden h-full w-full">
          <div
            v-if="loading"
            class="flex flex-row px-3 py-4 justify-center items-center"
            style="height: 500px"
          >
            <Loader />
          </div>
          <div v-else class="flex flex-row shadow h-full">
            <div class="container h-full rounded-md overflow-hidden">
              <table
                class="table-fixed text-left w-full bg-white h-[95%] divide-y divide-gray-200 text-gray-900"
              >
                <thead class="bg-gray-50 flex text-white w-full">
                  <tr class="flex w-full">
                    <th
                      class="p-4 w-1/2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("general.name") }}
                    </th>
                    <th
                      class="p-4 w-1/6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("general.exported_on") }}
                    </th>
                    <th
                      class="p-4 w-1/6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("general.file_type") }}
                    </th>
                    <th
                      class="p-4 w-1/6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody
                  class="bg-white divide-y divide-gray-200 flex flex-col items-center justify-between overflow-y-scroll w-full h-full"
                >
                  <tr
                    v-for="file in recentFiles"
                    :key="file.id"
                    class="flex w-full"
                  >
                    <td class="w-1/2 px-4 py-4 text-sm break-words">
                      {{ file.name }}
                    </td>
                    <td class="w-1/6 px-6 py-4 text-sm break-words">
                      {{ formatDate(file.created_at) }}
                    </td>
                    <td class="w-1/6 px-6 py-4 text-sm break-words">
                      {{ getExtension(file.name) }}
                    </td>
                    <td class="w-1/6 px-4 py-4">
                      <div class="flex justify-end px-8">
                        <a
                          :href="file.uri"
                          rel="noopener noreferrer"
                          download
                          target="_blank"
                          v-tooltip="{
                            content: $t('general.download'),
                            boundary: 'window',
                          }"
                          class="flex items-center justify-center border border-transparent h-12 w-12 rounded-md px-4 py-2 text-base leading-6 font-medium text-white font-medium bg-blue-500 text-lightAlineaColor focus:outline-none transition ease-in-out duration-150 hover:bg-blue-400 focus:outline-none"
                        >
                          <font-awesome-icon icon="download" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/views/editor/components/Loader";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { format } from "date-fns";
const translationRepository = RepositoryFactory.get("translation");

export default {
  name: "SelectMedia",
  components: {
    Loader,
  },
  props: {
    mediaModalType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      recentFiles: [],
    };
  },
  mounted() {
    this.getMedia();
  },
  methods: {
    formatDate(date) {
      return format(
        new Date(date),
        this.$i18n.locale === "en" ? "dd MMM yyyy HH:mm" : "dd-MM-yyyy HH:mm"
      );
    },
    getExtension(fileName) {
      let arr = fileName.split(".");
      return arr[arr.length - 1];
    },
    selectFile() {
      this.$emit("selectFile", this.selectedMedia);
    },
    close() {
      this.$emit("toggleMediaModal");
    },
    getMedia() {
      translationRepository
        .getRecentFiles()
        .then((result) => {
          this.recentFiles = result.data.data;
          this.loading = false;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_fetching_media")
          );
        });
    },
  },
};
</script>

<style></style>
