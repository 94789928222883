<template>
  <button
    type="button"
    :data-cy="cy"
    :class="[enabledButton, typeButton]"
    class="inline-flex items-center justify-center font-medium rounded-md cursor-pointer transition ease-in-out duration-150 text-sm leading-5"
    @click.stop="loading || invalid ? '' : $emit('clickAction')"
  >
    <div class="px-4 py-2 flex flex-row" v-if="!loading">
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        class="text-19"
        :class="[iconStyle]"
      />
      <p>
        {{ title }}
      </p>
    </div>
    <font-awesome-icon v-else icon="spinner" class="mx-4 my-2 text-19" />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      default: "",
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "button",
    },
    buttonType: {
      type: String,
      required: false,
      default: "primary",
      validate: (val) => {
        return (
          ["primary", "secondary", "tertiary", "cancel", "accept"].indexOf(
            val
          ) !== -1
        );
      },
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    cy: {
      type: String,
      default: "",
    },
  },
  emits: ["clickAction"],
  computed: {
    enabledButton: function () {
      if (this.invalid) {
        return "opacity-25";
      } else {
        return "";
      }
    },
    iconStyle: function () {
      if (this.title) {
        return "mr-3";
      } else {
        return "";
      }
    },
    typeButton: function () {
      switch (this.buttonType) {
        case "primary":
          return "bg-secondaryColor text-gray-100 border-1 border-transparent hover:text-gray-200 active:bg-gray-700 shadow-md shadow-lg hover:shadow-none";
        case "secondary":
          return "bg-primaryColor text-gray-100 border-1 border-transparent hover:text-gray-200 active:bg-gray-700 shadow-lg hover:shadow-none";
        case "tertiary":
          return "bg-transparent text-gray-100 border-1 border-transparent hover:bg-gray-100 hover:text-primaryColor active:bg-gray-700 shadow-none hover:shadow-lg";
        default:
          return "";
      }
    },
  },
};
</script>
