<template>
  <ModalContainer @cancel="close">
    <div
      class="flex flex-col justify-between w-1/2 bg-gray-50 p-4 rounded-md"
      v-click-outside="close"
    >
      <p
        class="flex self-start text-gray-700 font-normal text-20 tracking-widest pb-4"
      >
        {{ $t("editor.add_chapter").toUpperCase() }}
      </p>
      <Form
        class="flex flex-col h-full justify-between"
        @keypress.enter="addChapter"
        @submit="addChapter"
        v-slot="{ errors }"
      >
        <div class="flex flex-col">
          <InputField
            title="Name"
            placeholder="Name"
            v-model="chapter.name"
            required-input
            rules="required|min:4|max:255"
          />
          <InputField
            class="mt-3"
            title="Intro"
            placeholder="Intro"
            v-model="chapter.introduction"
            required-input
            rules="required|min:4|max:255"
          />
          <InputField
            class="my-3"
            title="Description"
            placeholder="Description"
            v-model="chapter.description"
            required-input
            rules="required|min:4|max:255"
          />
          <CheckBox
            :title="$t('editor.active')"
            v-model="chapter.active"
          ></CheckBox>
        </div>
        <!-- <ImageField class="mt-3" @click.native="showMediaModal = true" /> -->

        {{ errors }}
        <div class="flex flex-row justify-end">
          <Button
            class="mr-3"
            type="submit"
            :loading="loading"
            button-type="primary"
            :title="$t('editor.save')"
            :invalid="Object.keys(errors).length !== 0"
          />
          <Button
            type="button"
            button-type="tertiary"
            :title="$t('editor.cancel')"
            @clickAction="close"
          />
        </div>
      </Form>
    </div>
  </ModalContainer>
</template>

<script>
import InputField from "@/components/form/InputField";
import Button from "@/components/form/Button";
import { mapState } from "vuex";
import { showNotification } from "@/services/notificationService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import CheckBox from "@/components/form/Checkbox";
const trainingRepository = RepositoryFactory.get("training");
import ModalContainer from "@/components/helpers/ModalContainer";

export default {
  name: "AddChapter",
  components: { InputField, Button, ModalContainer, CheckBox },
  props: {
    gameId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      chapter: {
        name: "",
        introduction: "",
        description: "",
        active: true,
        sort: 0,
      },
    };
  },
  computed: {
    ...mapState("trainingStore", ["trainingId", "versionId"]),
  },
  methods: {
    addChapter() {
      this.chapter.parent = this.gameId;
      this.loading = true;
      trainingRepository
        .newChapter(this.trainingId, this.versionId, this.chapter)
        .then((resp) => {
          this.loading = false;
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
          showNotification(
            this.$t("notifications.chapter_edited"),
            this.$t("notifications.chapter_is_successfully_edited"),
            "success"
          );
          this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "none");
        });
    },
    close() {
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "none");
    },
  },
};
</script>

<style></style>
