<template>
  <span
    :class="[enabledButton, buttonWidth, shadow]"
    class="inline-flex"
    @click.stop="loading || invalid ? '' : $emit('clickAction')"
  >
    <button
      v-tooltip="{
        content: hint,
        placement: 'top',
        boundary: 'window',
        delay: {
          show: 500,
          hide: 300,
        },
      }"
      :type="type"
      :disabled="loading || invalid"
      class="flex items-center justify-center border border-transparent font-medium focus:outline-none transition ease-in-out duration-150"
      :class="[
        typeButton,
        buttonWidth,
        borderRadius,
        buttonSize,
        buttonHover,
        cursor,
      ]"
      :data-cy="cy"
    >
      <div class="flex justify-center relative" v-if="!loading">
        <p class="uppercase" v-if="title">{{ title }}</p>
        <font-awesome-icon
          v-if="icon"
          :icon="icon"
          class="text-white"
          :class="iconType"
        />
        <slot></slot>
      </div>
      <div v-else class="flex justify-center relative">
        <p class="uppercase mr-2" v-if="loadingText">{{ loadingText }}</p>
        <font-awesome-icon icon="spinner" class="text-white fa-spin" />
      </div>
    </button>
  </span>
</template>

<script>
export default {
  name: "Button",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    loadingText: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "button",
    },
    cy: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: "m",
      validate: (val) => {
        return ["s", "m", "l"].indexOf(val) !== -1;
      },
    },
    radius: {
      type: String,
      required: false,
      default: "md",
      validate: (val) => {
        return ["full", "md"].indexOf(val) !== -1;
      },
    },
    buttonType: {
      type: String,
      required: false,
      default: "primary",
      validate: (val) => {
        return (
          [
            "primary",
            "secondary",
            "tertiary",
            "accept",
            "editor-primary",
            "editor-secondary",
            "green",
            "delete",
          ].indexOf(val) !== -1
        );
      },
    },
    hover: {
      type: String,
      default: "",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clickAction"],
  computed: {
    enabledButton: function () {
      if (this.invalid) {
        return "opacity-25";
      } else {
        return "";
      }
    },
    borderRadius: function () {
      if (this.radius === "full") {
        return "rounded-full";
      }
      if (this.radius === "none") {
        return "";
      } else {
        return "rounded-md";
      }
    },
    buttonWidth: function () {
      if (this.width === "full") {
        return "w-full";
      }
      if (this.width === "large") {
        return "w-16";
      } else {
        return "w-auto";
      }
    },
    typeButton: function () {
      switch (this.buttonType) {
        case "primary":
          return "bg-secondaryColor text-lightAlineaColor hover:bg-primaryColor active:bg-secondaryOpacityButton";
        case "secondary":
          return "bg-transparent text-primaryBackgroundOpacity border border-primaryColor hover:border-secondaryOpacityButton hover:text-secondaryOpacityButton active:border-secondaryColor active:text-secondaryColor";
        case "lightPrimary":
          return "bg-primaryColor text-lightAlineaColor hover:bg-secondaryOpacityButton active:bg-secondaryOpacityButton";
        case "lightSecondary":
          return "bg-blue-700 text-lightAlineaColor hover:bg-secondaryOpacityButton active:bg-secondaryOpacityButton";
        case "tertiary":
          return "bg-gray-400 text-lightAlineaColor";
        case "accept":
          return "bg-green-400 text-darkAlineaColor";
        case "editor-primary":
          return "bg-blue-700 text-gray-100";
        case "editor-secondary":
          return "bg-blue-500 text-gray-100";
        case "green":
          return "bg-green-500 text-gray-100";
        case "transparent":
          return "bg-transparent text-lightAlineaColor";
        case "trashcan":
          return "bg-transparent text-gray-100";
        case "delete-s":
          return "bg-transparent text-gray-100";
        default:
          return "";
      }
    },
    buttonSize: function () {
      switch (this.size) {
        case "s":
          return "h-8 px-2.5 py-1.5 text-xs leading-4 font-medium rounded text-white";
        case "m":
          return "h-10 px-4 py-2 text-sm leading-5 font-medium text-white";
        case "l":
          return "h-12 px-4 py-2 text-base leading-6 font-medium text-white";
        case "full":
          return "h-8 mr-1 w-full px-2.5 py-1.5 text-xs leading-4 font-medium rounded text-white";
        default:
          return "";
      }
    },
    buttonHover: function () {
      switch (this.hover) {
        case "green":
          return "hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50";
        case "red":
          return "hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50";
        case "blue":
          return "hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50";
        case "lightBlue":
          return "hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50";
        case "light":
          return "hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50";
        default:
          return "";
      }
    },
    shadow: function () {
      if (this.buttonType === "tertiary") {
        return "";
      } else if (
        this.buttonType === "trashcan" ||
        this.buttonType === "transparent"
      ) {
        return "";
      } else {
        return "shadow-sm";
      }
    },
    iconType: function () {
      if (this.buttonType === "delete") {
        return "text-red-500 w-6 h-6";
      } else if (this.icon && this.title) {
        return "text-white ml-2";
      } else if (this.buttonType === "delete-s") {
        return "text-red-500";
      }
      return "";
    },
    cursor: function () {
      if (this.invalid || this.loading) {
        return "cursor-default";
      } else {
        return "cursor-pointer";
      }
    },
  },
};
</script>

<style>
.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip .tooltip-inner {
  background: #1657a4;
  color: white;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
}
.tooltip .tooltip-arrow {
  border-color: #1657a4;
}
</style>
