<template>
  <div
    id="container"
    class="flex justify-center items-center h-screen w-screen lg:py-loginPaddingTopBottom lg:px-loginPaddingLeftRight bg-loginBackgroundColor"
  >
    <div
      id="card"
      class="flex flex-row mobile:h-full w-full max-w-800 h-550 bg-white shadow-lightBg"
    >
      <!-- left side of card -->
      <LeftContainer />
      <!-- right side of card -->
      <div
        id="right"
        class="flex flex-1 flex-col bg-standardBgColor"
        @submit.prevent
        @keypress.enter.prevent="setNewPassword"
      >
        <div class="flex flex-2 flex-row w-full justify-center items-center">
          <p class="text-title text-darkAlineaColor mobile:hidden">
            {{ $t("auth.reset").toUpperCase() }}
          </p>
          <p
            class="text-title text-darkAlineaColor mobile:items-center mobile:justify-center mobile:font-bold mobile:-mt-6 desktop:items-end desktop:mb-inputPadding desktop:ml-inputPadding desktop:hidden"
          >
            {{ $t("auth.virtualstudio") }}
          </p>
        </div>

        <div
          id="form-container"
          class="flex flex-9 mobile:flex-6 justify-center items-center flex-col px-inputPaddingLeftRight"
        >
          <Form
            @submit="setNewPassword"
            @keypress.enter="setNewPassword"
            v-slot="{ errors }"
          >
            <InputField
              class="mt-inputPadding w-full"
              :title="$t('auth.password')"
              type="password"
              placeholder=""
              rules="required|min:8|min_one_upper_case|min_one_number"
              v-model="password"
              :required-input="true"
              @update:modelValue="showError = false"
            />

            <InputField
              class="mt-inputPadding"
              :title="$t('auth.repeat_password')"
              type="password"
              placeholder=""
              rules="required|min:8|min_one_upper_case|min_one_number"
              v-model="repeatPassword"
              :required-input="true"
              @update:modelValue="showError = false"
            />

            <ErrorMessage
              v-if="showError"
              class="mt-2"
              :error-message="responseError"
              :password-validation="passwordValidation"
            />

            <Button
              class="mb-inputPadding float-right max-w-400"
              :title="$t('auth.reset')"
              button-type="primary"
              width="full"
              type="submit"
              :invalid="Object.keys(errors).length !== 0"
              :loading="loading"
            />
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/form/InputField";
import Button from "@/components/form/Button";
import ErrorMessage from "@/components/helpers/ErrorMessage";
import LeftContainer from "@/components/authentication/LeftContainer";
import passwordValidation from "@/mixins/passwordValidation";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const authRepository = RepositoryFactory.get("auth");

export default {
  name: "ResetPassword",
  components: { InputField, Button, LeftContainer, ErrorMessage },
  mixins: [passwordValidation],
  props: {
    token: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    password: "",
    repeatPassword: "",
    responseError: "",
    showError: false,
    loading: false,
    enabled: true,
    success: null,
  }),
  methods: {
    setNewPassword() {
      this.loading = true;
      if (this.password !== "" && this.repeatPassword !== "") {
        if (this.password === this.repeatPassword) {
          if (this.passwordValidation.valid) {
            const payload = {
              token: this.token,
              password: this.password,
            };
            authRepository
              .newPassword(payload)
              .then(() => {
                this.loading = false;
                this.$router.push({ name: "login" });
              })
              .catch(() => {
                this.responseError = this.$t(
                  "auth.the_reset_link_is_no_longer_valid"
                );
                this.showError = true;
                this.clearInput();
              });
          } else {
            this.responseError = this.$t("auth.password_is_not_valid");
            this.showError = true;
            this.clearInput();
          }
        } else {
          this.responseError = this.$t("auth.passwords_do_not_match");
          this.showError = true;
          this.clearInput();
        }
      } else {
        this.responseError = this.$t("auth.some_required_fields_are_empty");
        this.showError = true;
        this.clearInput();
      }
    },
    clearInput() {
      this.loading = false;
      this.password = "";
      this.repeatPassword = "";
    },
  },
};
</script>

<style scoped></style>
