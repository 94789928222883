<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <ListHeader
        :buttons="editButtonSettings"
        @editCustomer="
          $router.push({
            name: 'customersEdit',
          })
        "
      />
      <div class="backgroundRow shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <div
            class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
          >
            <div class="ml-4 mt-2 flex flex-row">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-100">
                  {{ $t("customers.customers_details") }}
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-300">
                  {{ $t("customers.customers_details_and_application") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-5 sm:p-0">
          <dl>
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 backgroundRow rounded-none"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("customers.name") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ customer.name }}
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("customers.client_id") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ customer.client_id }}
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 backgroundRow rounded-none"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("customers.enabled") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2"
              >
                <span
                  :class="{
                    'bg-green-300 text-green-600': customer.enabled,
                    'bg-red-300 text-red-600': !customer.enabled,
                  }"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-300 text-green-600"
                >
                  {{ customer.enabled ? "enabled" : "disabled" }}
                </span>
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("customers.start_date") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ formatDate(customer.start_date) }}
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 backgroundRow rounded-none"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("customers.stop_date") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ formatDate(customer.stop_date) }}
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-none"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("customers.activation_code") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ customer.activation_code }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <!-- <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <div class="border-b border-gray-200 bg-gray-100 px-4 py-5 sm:px-6">
          <div
            class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
          >
            <div class="ml-4 mt-2 flex flex-row">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ $t("customers.played_lessons") }}
              </h3>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div
            class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-b border-gray-200"
            ></div>
          </div>
        </div>
      </div> -->
      <ListHeader
        :buttons="buttonSettings"
        :search="searchSettings"
        @addNewUser="showAddNewUserModal = true"
        @addExistingUser="showAddExistingUserModal = true"
        @searchAction="searchAction"
      />
      <div class="backgroundRow shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 sm:px-6">
          <div
            class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
          >
            <div class="ml-4 mt-2 flex flex-row">
              <h3 class="text-lg leading-6 font-medium text-gray-100">
                {{ $t("customers.customers_users") }}
              </h3>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-blue-200 text-blue-900 ml-2"
              >
                {{ userCount }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div
            class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div class="align-middle inline-block min-w-full overflow-hidden">
              <table class="min-w-full">
                <thead class="backgroundRow">
                  <tr>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("customers.firstname") }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("customers.lastname") }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("customers.email") }}
                    </th>
                    <th class="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRowUsers
                    v-for="user in customers[currentPage - 1]"
                    :key="user.id"
                    :firstname="user.firstname"
                    :lastname="user.lastname"
                    :email="user.email"
                    @delete="deleteUserFromCustomer(user)"
                    @details="toUserDetails(user)"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        class="justify-self-end"
        :total-pages="totalPages"
        :current-page="currentPage"
        :max-result="maxResult"
        @next="currentPage++"
        @previous="currentPage--"
        @toPage="toPage"
        @showAmount="changeUsersToShow"
      />
    </div>
    <CreateUserModal
      v-if="showAddNewUserModal"
      @update="getCustomer()"
      @close="showAddNewUserModal = false"
    />
    <AddUserModal
      v-if="showAddExistingUserModal"
      @update="getCustomer()"
      @close="showAddExistingUserModal = false"
    />
  </PageContentContainer>
</template>

<script>
import { format } from "date-fns";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import TableRowUsers from "./components/TableRowUsers";
import CreateUserModal from "@/components/helpers/modals/CreateUserModal";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { showNotification } from "@/services/notificationService";
import { errorHandlingAndMessage } from "@/services/ErrorService";
const customerRepository = RepositoryFactory.get("customer");
const userRepository = RepositoryFactory.get("user");
import ListHeader from "@/components/helpers/ListHeader";
import Pagination from "@/components/helpers/Pagination";
import AddUserModal from "@/components/helpers/modals/dealerModals/AddUserModal";

export default {
  name: "CustomersDetail",
  components: {
    PageContentContainer,
    TableRowUsers,
    CreateUserModal,
    AddUserModal,
    ListHeader,
    Pagination,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  data: () => {
    return {
      customer: {},
      customers: [],
      showAddNewUserModal: false,
      showAddExistingUserModal: false,
      showAddUserButton: false,
      userCount: 0,
      customerUsers: [],
      maxResult: localStorage.getItem("maxresult-customers-details") || "7",
      searchInput: "",
      totalPages: null,
      totalCustomersUsers: null,
      currentPage: 1,
      buttonSettings: [
        {
          title: "button.new_user",
          cy: "addNewUserToDealer",
          action: "addNewUser",
        },
        {
          title: "button.existing",
          cy: "addExistingUserToDealer",
          action: "addExistingUser",
        },
      ],
      editButtonSettings: [
        {
          title: "customers.edit",
          cy: "editCustomer",
          action: "editCustomer",
        },
      ],
      searchSettings: {
        cy: "searchBar",
      },
    };
  },

  computed: {},

  mounted() {
    this.getCustomer();
  },
  methods: {
    formatDate(date) {
      if (date) {
        return format(
          new Date(date),
          this.$i18n.locale === "en" ? "dd MMM yyyy" : "dd-MM-yyyy"
        );
      } else {
        return "";
      }
    },

    deleteUserFromCustomer(user) {
      user.dealer_id = "null";
      user.customer_id = "null";
      userRepository
        .updateUser(user)
        .then(() => {
          this.getCustomer();
          showNotification(
            this.$t("notifications.user_deleted"),
            this.$t("notifications.user_is_successfully_deleted"),
            "success"
          );
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_deleting_the_user")
          );
        });
    },

    toUserDetails(user) {
      this.$router.push({ name: "usersDetail", params: { id: user.id } });
    },

    getCustomer() {
      customerRepository
        .getCustomer(this.id)
        .then((result) => {
          this.customer = result.data.data;
          this.userCount = result.data.data.users.data.length;
          this.totalPages = Math.round(
            result.data.data.users.data.length / this.maxResult
          );
          this.totalCustomersUsers = result.data.data.users.data;
          this.getPages(this.totalCustomersUsers);
        })
        .catch(() => {
          this.$router.push({
            name: "customers",
          });
        });
    },
    // getUsers() {
    //   userRepository
    //     .getUsers({
    //       page: 1,
    //       sort: "",
    //       sortfield: "",
    //       maxResult: 30,
    //       query: ""
    //     })
    //     .then(result => {
    //       this.showAddUserButton = this.users.length !== 0;
    //     })
    //     .catch(err => {
    //       errorHandlingAndMessage(
    //         err,
    //         this.$t("notifications.something_went_wrong_receiving_users")
    //       );
    //       this.showAddUserButton = false;
    //     });
    // },
    searchAction(s) {
      this.customers = [];
      this.currentPage = 1;
      const searchInput = s;
      const result = this.totalCustomersUsers.filter((customer) => {
        if (
          customer.firstname.toUpperCase().includes(searchInput.toUpperCase())
        ) {
          return customer;
        }
      });
      this.totalPages = Math.round(result.length / this.maxResult);
      this.getPages(result);
    },
    toPage(p) {
      this.currentPage = p;
    },
    changeUsersToShow(val) {
      this.maxResult = val;
      localStorage.setItem("maxresult-customers-details", val);
      this.getCustomer();
    },
    getPages(customer) {
      this.customers = [];
      for (let i = 0, len = customer.length; i < len; i += this.maxResult) {
        this.customers.push(customer.slice(i, i + this.maxResult));
      }
    },
  },
};
</script>

<style></style>
