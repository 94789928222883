<template>
  <ModalContainer @cancel="cancel">
    <div class="bg-white shadow sm:rounded-lg" v-click-outside="cancel">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("layout.redirected") }}
        </h3>
        <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>
            {{
              $t(
                "layout.you_have_been_redirected_to_our_new_application_please_use_this_link_in_the_future_to_login"
              )
            }}
          </p>
        </div>
        <div class="mt-5">
          <Button
            type="primary"
            :title="$t('layout.activate')"
            @clickAction="cancel"
          />
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer";
import Button from "../../helpers/Button";

export default {
  name: "RedirectMessageModal",
  components: {
    ModalContainer,
    Button,
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
