<template>
  <!--  <tr-->
  <!--    @click="editCharacter()"-->
  <!--    class="hover:bg-rowHover focus:bg-gray-100 cursor-pointer"-->
  <!--  >-->
  <tr>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm leading-5 font-medium text-gray-100">
            {{ gameCharacter.name }}
          </div>
        </div>
      </div>
    </td>

    <td
      v-for="(locale, indexLocale) in locales"
      :key="`locales-${indexLocale}`"
      class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200"
    >
      <div
        v-for="(voice, indexVoice) in gameCharacter.voices"
        :key="`gameCharacter-${indexVoice}`"
      >
        {{ locale.tag === indexVoice ? voice : null }}
      </div>
    </td>
    <td
      class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium items-end"
    >
      <Button
        button-type="transparent"
        icon="edit"
        hover="blue"
        class="mr-1"
        @clickAction="$emit('edit')"
      />
      <!--      <Button-->
      <!--        icon="trash"-->
      <!--        size="m"-->
      <!--        hover="red"-->
      <!--        button-type="trashcan"-->
      <!--        @clickAction="$emit('delete')"-->
      <!--      />-->
    </td>
  </tr>
</template>

<script>
import Button from "@/components/form/Button";

export default {
  components: {
    Button,
  },
  props: {
    gameCharacter: {
      type: Object,
      required: true,
    },
    locales: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
