<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm leading-5 font-medium text-gray-100">
            {{ version }}
          </div>
        </div>
      </div>
    </td>
    <td
      class="px-6 py-4 whitespace-nowrap"
      v-for="(platform, i) in platforms"
      :key="i"
    >
      <div v-if="applicationsContain(platform.slug).length">
        <a
          :href="applicationsContain(platform.slug)[0].file"
          rel="noopener noreferrer"
        >
          <Button
            :hint="$t('general.download')"
            button-type="green"
            hover="green"
            icon="download"
            size="s"
            width="large"
            class="mr-1"
          />
        </a>
        <Button
          :hint="$t('general.delete')"
          button-type="transparent"
          hover="red"
          icon="trash"
          size="full"
          @clickAction="toggleModalDeleteBuild(platform.slug)"
        />
      </div>
      <div v-else>
        <div class="">
          <input
            type="file"
            name="file"
            :id="`${id}-${i}`"
            class="hidden"
            @change="uploadFile($event.target.files, platform.slug)"
          />
          <label
            :for="`${id}-${i}`"
            class="fileContainer cursor-pointer h-8 px-2.5 py-1.5 text-xs leading-4 font-medium rounded-md text-white w-16 flex justify-center items-center font-medium bg-blue-700 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
          >
            <font-awesome-icon
              icon="upload"
              class="text-white cursor-pointer"
            />
          </label>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      <span v-if="published" class="inline-flex w-auto shadow-sm mr-2">
        <span
          class="flex items-center justify-center border border-transparent font-medium focus:outline-none transition ease-in-out duration-150 bg-green-500 w-auto rounded-md h-8 px-2.5 py-1.5 text-xs leading-4 font-medium rounded text-white"
        >
          <span class="flex justify-center relative">
            <font-awesome-icon
              icon="paper-plane"
              class="text-white"
              v-tooltip="{
                content: 'Published',
                placement: 'top',
                boundary: 'window',
                delay: {
                  show: 500,
                  hide: 300,
                },
              }"
            />
          </span>
        </span>
      </span>
      <Button
        v-else
        button-type="trashcan"
        :hint="$t('general.publish')"
        hover="green"
        class="mr-2"
        icon="paper-plane"
        size="s"
        @clickAction="publishTraining()"
      />
      <Button
        :button-type="synced ? 'green' : 'trashcan'"
        :hint="synced ? 'Synced' : 'Sync now'"
        :hover="synced ? 'green' : 'red'"
        class="mr-2"
        icon="sync"
        size="s"
        @clickAction="checkSyncProgress"
      />
    </td>
    <td
      class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium"
    >
      <Button
        button-type="trashcan"
        :hint="$t('versions.delete_version')"
        hover="red"
        icon="trash"
        size="s"
        @clickAction="showModalDeleteVersion = true"
      />
      <AcceptModal
        class="text-left"
        v-if="showModalDeleteVersion"
        :header-text="$t('versions.delete_version')"
        :sub-header-text="
          $t('versions.are_you_sure_you_want_to_delete_this_version')
        "
        :body-text="$t('versions.deleting_versions_happens_in_the_background')"
        :accept-button-text="$t('general.accept')"
        :cancel-button-text="$t('general.cancel')"
        @accept="deleteVersion()"
        @cancel="showModalDeleteVersion = false"
      />
    </td>
    <SyncStatusModal
      v-if="showModalSync"
      :training-id="trainingId"
      :version="versionToCheck"
      @close="showModalSync = false"
    />
    <UploadMessageModal
      v-if="showUpload"
      header-text="Uploading"
      sub-header-text="Your file is being uploaded..."
      :progress="uploadPercentage"
      :total="uploadTotalSize"
      :done="uploadCompletedSize"
    />
    <AcceptModal
      v-if="showModalDeleteBuild"
      :header-text="$t('versions.delete_build')"
      :sub-header-text="$t('versions.are_you_sure_you_want_to_delete_build')"
      :accept-button-text="$t('general.accept')"
      :cancel-button-text="$t('general.cancel')"
      @accept="deleteBuild"
      @cancel="showModalDeleteBuild = false"
    />
  </tr>
</template>

<script>
import Button from "../form/Button";
import { showNotification } from "@/services/notificationService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import TrainingRepository from "@/repository/TrainingRepository";
import SyncStatusModal from "@/views/editor/components/modals/SyncStatusModal";
import AcceptModal from "@/components/helpers/modals/AcceptModal";
import UploadMessageModal from "@/components/helpers/modals/UploadMessageModal";
import axios from "axios";
import { errorHandlingAndMessage } from "@/services/ErrorService";

const trainingRepository = RepositoryFactory.get("training");
const uploadRepository = RepositoryFactory.get("upload");

export default {
  name: "TableRow",
  components: { Button, SyncStatusModal, AcceptModal, UploadMessageModal },
  props: {
    id: {
      type: String,
      default: "",
    },
    version: {
      type: String,
      default: "",
    },
    applications: {
      type: Array,
      default: () => [],
    },
    published: {
      type: Boolean,
      default: true,
    },
    synced: {
      type: Boolean,
      default: true,
    },
    trainingId: {
      type: String,
      required: true,
    },
    platforms: {
      type: Array,
      required: true,
    },
  },
  emits: ["getVersions"],
  data() {
    return {
      versionToCheck: "",
      showModalSync: false,
      showModalDeleteBuild: false,
      showModalDeleteVersion: false,
      showUpload: false,
      showDownload: false,
      uploadPercentage: 0,
      uploadTotalSize: 0,
      uploadCompletedSize: 0,
    };
  },
  methods: {
    checkSyncProgress() {
      this.versionToCheck = this.id;
      this.showModalSync = true;
    },
    applicationsContain(name) {
      return this.applications.filter((val) => val.platform_slug === name);
    },
    uploadFile(event, slug) {
      this.showUpload = true;

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadPercentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          this.uploadTotalSize = progressEvent.total;
          this.uploadCompletedSize = progressEvent.loaded;
        },
      };

      uploadRepository
        .createSignedUrl(event[0]["name"])
        .then(async (result) => {
          await axios
            .put(result.data.data.url, event[0], config)
            .then(() => {
              this.updateTrainingVersion(
                result.data.data.url.split("?")[0],
                slug
              );
            })
            .catch((err) => {
              this.showUpload = false;
              errorHandlingAndMessage(
                err,
                this.$t(
                  "notifications.something_went_wrong_uploading_this_file"
                )
              );
            });
        })
        .catch((err) => {
          this.showUpload = false;
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_uploading_this_file")
          );
        });
    },
    updateTrainingVersion(url, slug) {
      const payload = {
        uploaded_file_url: url,
      };
      TrainingRepository.updateVersion(this.trainingId, this.id, slug, payload)
        .then(() => {
          showNotification(
            this.$t("notifications.upload_version"),
            this.$t("notifications.successfully_uploaded_version"),
            "success"
          );
          this.$emit("getVersions");
        })
        .catch(() => {
          showNotification(
            this.$t("notifications.upload_version"),
            this.$t("notifications.something_went_wrong_uploading_the_version"),
            "error"
          );
        })
        .finally(() => {
          this.showUpload = false;
        });
    },
    deleteVersion() {
      this.showModalDeleteVersion = false;
      TrainingRepository.deleteVersion(this.trainingId, this.id)
        .then((result) => {
          showNotification(
            this.$t("notifications.delete_version"),
            this.$t("notifications.successfully_deleted_the_version"),
            "success"
          );
          this.$emit("getVersions");
        })
        .catch((err) => {
          showNotification(
            this.$t("notifications.delete_version"),
            this.$t("notifications.something_went_wrong_deleting_the_version"),
            "error"
          );
        })
        .finally(() => {
          this.showModalDeleteVersion = false;
        });
    },
    deleteBuild() {
      TrainingRepository.deleteBuild(this.trainingId, this.id, this.slug)
        .then((result) => {
          showNotification(
            this.$t("notifications.delete_build"),
            this.$t("notifications.successfully_deleted_the_build"),
            "success"
          );
          this.$emit("getVersions");
        })
        .catch((err) => {
          showNotification(
            this.$t("notifications.delete_build"),
            this.$t("notifications.something_went_wrong_deleting_the_build"),
            "error"
          );
        })
        .finally(() => {
          this.toggleModalDeleteBuild();
        });
    },
    toggleModalDeleteBuild(slug) {
      this.slug = slug;
      this.showModalDeleteBuild = !this.showModalDeleteBuild;
    },
    publishTraining() {
      TrainingRepository.publishTraining(this.trainingId, this.id)
        .then((result) => {
          showNotification(
            this.$t("notifications.publish_training"),
            this.$t("notifications.successfully_published_training"),
            "success"
          );
          this.$emit("getVersions");
        })
        .catch((err) => {
          showNotification(
            this.$t("notifications.publish_training_error"),
            this.$t(
              "notifications.something_went_wrong_publishing_the_training"
            ),
            "error"
          );
        });
    },
  },
};
</script>

<style>
.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
</style>
