<template>
  <div class="w-full mt-1 pb-2">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <span
        :class="{ 'bg-gray-500': !newValue, 'bg-green-400': newValue }"
        class="mt-1 relative inline-block flex-no-shrink h-6 w-11 border-2 border-transparent outline-none rounded-full cursor-pointer transition-colors ease-in-out duration-200"
        role="checkbox"
        tabindex="0"
        @click="toggle()"
        @keydown.space.prevent="toggle()"
      >
        <span
          aria-hidden="true"
          :class="{ 'translate-x-5': newValue, 'translate-x-0': !newValue }"
          class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
        ></span>
      </span>
    </div>
  </div>
</template>

<script>
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import { useField } from "vee-validate";

export default {
  components: { ErrorPopup },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Title",
    },
    defaultValue: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    inputType: {
      type: String,
      default: "text",
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newValue: false,
    };
  },
  watch: {
    modelValue: {
      handler(val) {
        this.newValue = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.newValue = this.modelValue ?? this.defaultValue;
  },
  methods: {
    toggle() {
      this.newValue = !this.newValue;
      this.$emit("update:modelValue", this.newValue);
    },
  },
};
</script>

<style></style>
