<template>
  <ModalContainer>
    <div
      class="bg-gray-50 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-auto z-10"
    >
      <div class="flex justify-center">
        <div class="px-4 py-5 sm:p-6">
          <h3
            class="flex text-lg leading-6 font-medium text-gray-900 justify-center"
          >
            {{ headerText }}
          </h3>
          <div
            v-if="!closingText"
            class="mt-2 max-w-xl text-sm leading-5 text-gray-500"
          >
            <p>
              {{ subHeaderText }}
            </p>
          </div>
          <div v-if="downloadLoading" class="flex justify-center mt-5">
            <Loader />
          </div>
          <div
            class="mt-2 max-w-xl text-sm leading-5 text-gray-500 flex justify-center"
          >
            <p>
              {{ message }}
            </p>
          </div>
          <div
            v-if="closingText"
            class="flex flex-col mt-2 max-w-xl text-sm leading-5 text-gray-500 justify-center text-center mt-5"
          >
            <p>
              {{ $t("download.this_form_will_close_in_5_seconds") }}
            </p>
            <p>
              {{
                target === "translation"
                  ? $t("download.the_download_link_can_be_found_in_slack")
                  : ""
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex bg-gray-50 py-6 sm:px-6 justify-center">
        <Button
          type="cancel"
          :title="downloaded ? $t('general.close') : $t('general.cancel')"
          @clickAction="$emit('close')"
        />
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../../../../components/helpers/ModalContainer";
import Loader from "@/views/editor/components/Loader";
import Button from "../../../../components/form/Button";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { mapState } from "vuex";
const stepRepository = RepositoryFactory.get("step");
const lessonRepository = RepositoryFactory.get("lesson");

export default {
  name: "ExportModal",
  components: { ModalContainer, Loader, Button },
  props: {
    headerText: {
      type: String,
      default: "",
    },
    subHeaderText: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "",
    },
    stepId: {
      type: String,
      default: "",
    },
    lessonId: {
      type: String,
      default: "",
    },
  },
  emits: ["close"],
  data() {
    return {
      downloadLoading: true,
      downloaded: false,
      closingText: false,
      readyMessage: false,
    };
  },
  computed: {
    message: function () {
      if (this.downloadLoading) {
        return this.$t("editor.preparing_download");
      } else {
        return this.$t("editor.your_download_is_ready");
      }
    },
    ...mapState("translationStore", ["entityName", "entityId"]),
  },
  mounted() {
    this.initializeExport();
  },
  methods: {
    initializeExport() {
      if (this.target === "step") {
        this.exportStep();
      }
      if (this.target === "lesson") {
        this.exportLesson();
      }
      if (this.target === "translation") {
        this.exportTranslation();
      }
    },

    exportTranslation() {
      const entity = {
        entityId: this.entityId,
        entityName: this.entityName,
      };
      this.$store
        .dispatch("translationStore/EXPORT_TRANSLATION", entity)
        .then((result) => {
          if (result.status === 202) {
            this.readyMessage = true;
            this.downloaded = true;
            this.closeModal();
          }
        })
        .finally(() => (this.downloadLoading = false));
    },

    exportStep() {
      return stepRepository
        .exportStep(this.lessonId, this.stepId)
        .then(async (result) => {
          window.open(result.data.url, "_blank");
          this.downloaded = true;
          this.closeModal();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_while_exporting_this_json"
            )
          );
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    exportLesson() {
      lessonRepository
        .exportLesson(this.lessonId)
        .then(async (result) => {
          window.open(result.data.url, "_blank");
          this.downloaded = true;
          this.closeModal();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_while_exporting_this_json"
            )
          );
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },

    closeModal() {
      this.closingText = true;
      setTimeout(() => this.$emit("close"), 5000);
    },
  },
};
</script>

<style></style>
