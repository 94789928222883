<template>
  <ModalContainer @cancel="close">
    <div
      v-if="gameCharacterEdit"
      class="flex flex-col justify-between w-1/2 bg-gray-50 p-4 rounded-md"
    >
      <p
        class="flex self-start text-gray-700 font-normal text-20 tracking-widest pb-4"
      >
        {{
          create
            ? $t("editor.add_game_character").toUpperCase()
            : $t("editor.edit_game_character").toUpperCase()
        }}
      </p>
      <!--      <ValidationObserver class="w-full h-full" v-slot="{ invalid }">-->
      <form
        @keypress.enter.prevent="saveGameCharacter"
        data-cy="editDealerForm"
      >
        <InputField
          class="mt-inputPadding w-full"
          :title="$t('general.name')"
          rules="required|min:2|max:100"
          :required-input="true"
          type="text"
          :placeholder="$t('general.enter_name')"
          v-model="gameCharacterEdit.name"
          :disabled="!create"
        />
        <div v-for="locale in locales" :key="locale">
          <label
            class="block text-sm font-medium leading-5 text-gray-700 mb-1 mt-3"
          >
            {{ locale.description }}
          </label>
          <div class="flex flex-row flex-1 w-full">
            <v-select
              class="select w-full"
              v-model="gameCharacterEdit.voices[locale.tag]"
              :options="voices[locale.tag]"
              :reduce="(voice) => voice.name"
            >
              <template #selected-option="option">
                {{ option.name }} ({{ option.language_codes[0] }} -
                {{ $t("general." + option.gender) }} -
                {{ $t("tts." + option.cloud_service) }} -
                {{ option.voice_engines.join(", ") }})
              </template>
              <template #option="option">
                {{ option.name }} ({{ option.language_codes[0] }} -
                {{ $t("general." + option.gender) }} -
                {{ $t("tts." + option.cloud_service) }} -
                {{ option.voice_engines.join(", ") }})
              </template>
            </v-select>
            <div class="flex justify-center items-center w-10">
              <div v-if="gameCharacterEdit.voices[locale.tag]">
                <font-awesome-icon
                  v-if="playing === locale.tag"
                  icon="pause-circle"
                  class="text-gray-500 text-15"
                  @click="stopPreview"
                />
                <font-awesome-icon
                  v-else
                  icon="fa-circle-play"
                  class="text-gray-500 text-15"
                  @click="playPreview(locale.tag)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8 pt-5">
          <i>{{ $t("tts.neural_note") }}</i><br />
          <br />
          <i>{{ $t("tts.gamecharacter_change_regenerate_note") }}</i>
          <div class="flex justify-end">
            <Button
              class="mr-3"
              :loading="loading"
              button-type="primary"
              :title="$t('editor.save')"
              :invalid="invalid"
              @clickAction="saveGameCharacter"
            />
            <Button
              type="button"
              button-type="secondary"
              :title="$t('editor.cancel')"
              @click.native="close"
            />
          </div>
        </div>
      </form>
      <!--      </ValidationObserver>-->
    </div>
  </ModalContainer>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import Button from "@/components/form/Button";
const trainingRepository = RepositoryFactory.get("training");
import ModalContainer from "@/components/helpers/ModalContainer";
import InputField from "@/components/form/InputField";
import { mapState } from "vuex";
import { showNotification } from "@/services/notificationService";
import { errorHandlingAndMessage } from "@/services/ErrorService";
const ttsRepository = RepositoryFactory.get("tts");
const gameCharacterRepository = RepositoryFactory.get("gameCharacters");

export default {
  name: "TrophyModal",
  components: {
    InputField,
    Button,
    ModalContainer,
  },
  props: {
    gameCharacter: {
      type: Object,
      default: () => {},
    },
    create: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMediaModal: false,
      trophy: {
        name: "",
        description: "",
      },
      loading: false,
      voices: {},
      playing: false,
      gameCharacterEdit: null,
      value: false,
      selectedVoice: "",
      audio: null,
    };
  },
  computed: {
    ...mapState("translationStore", ["locales"]),
    ...mapState("trainingStore", ["trainingId", "versionId", "chapter"]),
    previewVoice: function () {
      return {
        en_US: `Hi, I'm ${this.selectedVoice}, and I will be your help throughout the game. You work in a chemical factory which is a large and hazardous environment. In this game you will face various hazards that must be prevented. Your task is to be safe and to create a safe environment for you and your co-workers.`,
        es_ES: `Hola, soy ${this.selectedVoice} y seré tu ayuda durante todo el juego. Trabaja en una fábrica de productos químicos que es un entorno grande y peligroso. En este juego te enfrentarás a varios peligros que debes prevenir. Su tarea es estar seguro y crear un entorno seguro para usted y sus compañeros de trabajo.`,
        nl_NL: `Hallo, ik ben ${this.selectedVoice} en ik zal je helpen tijdens het spel. Je werkt in een chemische fabriek wat een grote en gevaarlijke omgeving is. In dit spel kom je verschillende gevaren tegen die voorkomen moeten worden. Het is jouw taak om veilig te zijn en een veilige omgeving te creëren voor jou en je collega's.`,
        de_DE: `Hallo, ich bin ${this.selectedVoice}, und ich werde deine Hilfe während des gesamten Spiels sein. Sie arbeiten in einer chemischen Fabrik, die eine große und gefährliche Umgebung ist. In diesem Spiel begegnen Sie verschiedenen Gefahren, die verhindert werden müssen. Ihre Aufgabe ist es, sicher zu sein und eine sichere Umgebung für Sie und Ihre Mitarbeiter zu schaffen.`,
        fr_FR: `Bonjour, je suis ${this.selectedVoice} et je serai votre aide tout au long du jeu. Vous travaillez dans une usine chimique qui est un environnement vaste et dangereux. Dans ce jeu, vous ferez face à divers dangers qui doivent être évités. Votre tâche est d'être en sécurité et de créer un environnement sûr pour vous et vos collègues.`,
      };
    },
  },
  async mounted() {
    if (!this.gameCharacter) {
      await this.$store.dispatch("translationStore/GET_LOCALES");
    }
    this.getVoices();
  },
  methods: {
    async setGameCharacter() {
      if (this.gameCharacter && !this.create) {
        this.gameCharacterEdit = this.gameCharacter;
      } else if (!this.gameCharacterEdit && !this.create) {
        await gameCharacterRepository
          .getGameCharacter(this.$route.params.gameCharacterId)
          .then((result) => {
            this.gameCharacterEdit = result.data.data;
            if (this.gameCharacterEdit.voices.length === 0) {
              this.gameCharacterEdit.voices = {};
            }
          })
          .catch((err) => {
            throw err;
          });
      } else if (this.create) {
        this.gameCharacterEdit = {
          name: "",
          voices: {},
        };
      }

      if (
        this.gameCharacterEdit.voices.length === 0 &&
        this.gameCharacterEdit.voices instanceof Array
      ) {
        this.gameCharacterEdit.voices = {};
      }

      this.loading = false;
    },

    getVoices() {
      ttsRepository
        .getVoices()
        .then((result) => {
          this.voices = result.data;
          this.setGameCharacter();
        })
        .catch((err) => {});
    },

    createGameCharacter() {
      gameCharacterRepository
        .createGameCharacter(this.gameCharacterEdit)
        .then((result) => {
          showNotification(
            this.$t("notifications.character_created"),
            this.$t("notifications.character_is_successfully_created"),
            "success"
          );
          this.close();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_creating_this_character"
            )
          );
        });
    },

    updateGameCharacter() {
      gameCharacterRepository
        .updateGameCharacter(this.gameCharacterEdit)
        .then(() => {
          showNotification(
            this.$t("notifications.character_edited"),
            this.$t("notifications.character_is_successfully_edited"),
            "success"
          );
          this.close();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_updating_this_character"
            )
          );
        });
    },

    saveGameCharacter() {
      if (this.create) {
        this.createGameCharacter();
      } else {
        this.updateGameCharacter();
      }
    },

    playPreview(locale) {
      if (this.playing) {
        this.stopPreview();
      }
      this.playing = locale;
      if (locale) {
        this.selectedVoice = this.gameCharacterEdit.voices[locale];
        this.synthesizeToUrl(locale);
      }
    },

    stopPreview() {
      if (this.playing) {
        this.playing = null;
        this.audio.pause();
      }
    },

    synthesizeToUrl(locale) {
      const payload = {
        ssml: true,
        text: `<speak>${this.previewVoice[locale]}</speak>`,
        locale: locale,
        voice_id: this.gameCharacterEdit.voices[locale],
      };
      ttsRepository
        .synthesizeToUrl(payload)
        .then((result) => {
          const audioSrc = result.data.url;
          if (audioSrc === null) {
            showNotification(
              this.$t("notifications.text_to_speech"),
              this.$t(
                "notifications.something_went_wrong_while_converting_text_to_speech"
              ),
              "error"
            );
          } else {
            this.audio = new Audio(audioSrc);
            this.audio.addEventListener(
              "ended",
              function () {
                this.stopPreview();
              }.bind(this),
              false
            );
            this.audio.play();
          }
        })
        .catch(() => {
          showNotification(
            this.$t("notifications.text_to_speech"),
            this.$t(
              "notifications.something_went_wrong_while_converting_text_to_speech"
            ),
            "error"
          );
        });
    },

    close() {
      this.stopPreview();

      this.$emit("close");
    },
  },
};
</script>

<style>
.select .vs__dropdown-toggle,
.select .style-chooser__title,
.select .vs-state-active-color {
  height: 2rem;
}
</style>
