<template>
  <td class="px-6 py-4 whitespace-nowrap">
    <div class="flex items-center">
      <div class="flex flex-row">
        <div
          class="flex bg-gray-600 overflow-hidden shadow rounded-l-lg pl-2 pr-2 justify-center items-center"
        >
          <div class="w-96 sm:p-2">
            <div v-if="fileSrc">
              <div v-if="isImage">
                <img
                  class="h-auto w-100vh pt-2 pb-2"
                  alt="Image"
                  :src="fileSrc"
                  @click="toggleImageModal"
                />
              </div>
              <div v-if="isFile">
                <label
                  class="flex flex-col items-center justify-center border h-48 w-full bg-transparent hover:bg-gray-500 cursor-pointer border border-gray-500 shadow-md rounded"
                  ><a
                    class="flex justify-center items-center text-gray-400 h-full w-100vh pt-2 pb-2"
                    :href="fileSrc"
                    target="_blank"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <font-awesome-icon
                        class="text-5xl text-gray-400"
                        icon="file-download"
                      />
                      <span class="mt-3">
                        {{ $t("general.download") }}
                      </span>
                    </div>
                  </a>
                </label>
              </div>
              <div v-if="isAudio">
                <vue-plyr :key="fileSrc">
                  <audio>
                    <source :src="fileSrc" type="audio/mp3" />
                    <source :src="fileSrc" type="audio/ogg" />
                    <source :src="fileSrc" type="audio/wav" />
                  </audio>
                </vue-plyr>
              </div>
              <div v-if="isVideo">
                <vue-plyr :key="fileSrc" class="pt-2 pb-2">
                  <video class="h-auto w-100vh pt-6 pb-2" :src="fileSrc">
                    <source :src="fileSrc" type="video/mp4" />
                  </video>
                </vue-plyr>
              </div>
            </div>
            <div v-else>
              <label
                class="flex items-center justify-center border h-48 w-full bg-transparent hover:bg-gray-500 cursor-pointer border border-gray-500 shadow-md rounded"
                @click="toggleMediaModal"
              >
                <span class="flex flex-row text-gray-400">
                  <font-awesome-icon class="text-5xl" :icon="icon" />
                  <font-awesome-icon class="ml-1" icon="plus" />
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center shadow bg-gray-500">
          <div class="whitespace-nowrap text-sm leading-5 text-gray-20">
            <label
              class="flex items-center justify-center border border-transparent h-12 px-4 py-2 text-base leading-6 font-medium text-white font-medium bg-transparent text-lightAlineaColor focus:outline-none transition ease-in-out duration-150 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
              @click="toggleMediaModal"
            >
              <font-awesome-icon icon="upload" />
            </label>
          </div>
          <div
            v-if="fileSrc"
            class="whitespace-nowrap text-sm leading-5 text-gray-20"
          >
            <a
              :href="fileSrc"
              rel="noopener noreferrer"
              download
              target="_blank"
              class="flex items-center justify-center border border-transparent h-12 px-4 py-2 text-base leading-6 font-medium text-white font-medium bg-transparent text-lightAlineaColor focus:outline-none transition ease-in-out duration-150 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
            >
              <font-awesome-icon icon="download" />
            </a>
          </div>
          <div
            v-if="fileSrc && !required"
            class="whitespace-nowrap text-sm leading-5 text-gray-20"
          >
            <label
              class="flex items-center justify-center border border-transparent h-12 px-4 py-2 text-base leading-6 font-medium text-white font-medium bg-transparent text-lightAlineaColor focus:outline-none transition ease-in-out duration-150 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
              @click="toggleDeleteModal"
            >
              <font-awesome-icon icon="trash" />
            </label>
          </div>
          <AcceptModal
            v-if="showDeleteModal"
            :header-text="$t('translation.delete_media')"
            :sub-header-text="
              isDefaultMedia
                ? $t(
                    'translation.this_media_translation_belongs_to_the_default_language_deleting_this_will_delete_all_media'
                  )
                : $t('translation.are_you_sure_you_want_to_delete_this_media')
            "
            :accept-button-text="
              isDefaultMedia ? $t('button.delete_all') : $t('button.delete')
            "
            :cancel-button-text="$t('button.cancel')"
            @accept="deleteMedia"
            @cancel="toggleDeleteModal"
          />
          <MediaModal
            v-if="showMediaModal"
            :media-modal-type="mediaModalType"
            :required="false"
            @toggleMediaModal="toggleMediaModal"
            @finishUpload="finishUpload"
            @selectFile="selectFile"
            @handleUploadError="handleUploadError"
            @finalizeUpload="finalizeUpload"
          />
        </div>
      </div>
    </div>
    <ImageModal
      v-if="showImageModal"
      :image="fileSrc"
      @close="toggleImageModal"
    />
  </td>
</template>

<script>
import AcceptModal from "@/components/helpers/modals/AcceptModal";
import ImageModal from "@/views/editor/components/modals/ImageModal";
import MediaModal from "@/views/editor/components/modals/mediaModal/MediaModal";
import uploadMediaHelper from "@/mixins/uploadMediaHelper";

export default {
  name: "MediaViewer",
  components: { AcceptModal, ImageModal, MediaModal },
  mixins: [uploadMediaHelper],
  props: {
    media: {
      type: Object,
      default: () => {},
    },
    id: {
      type: [Number, String],
      default: 0,
    },
    mediaType: {
      type: String,
      required: true,
    },
    defaultLocale: {
      type: String,
      required: true,
    },
    selectedLocale: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteModal: false,
      showImageModal: false,
      currentMediaType: "",
      image: ["JsonImage", "image"],
      video: ["JsonVideo", "video"],
      audio: ["JsonAudio", "audio"],
      file: ["file"],
    };
  },
  computed: {
    isDefaultMedia: function () {
      return this.defaultLocale === this.selectedLocale;
    },
    mediaModalType: function () {
      if (this.image.includes(this.mediaType)) {
        return "image";
      }
      if (this.video.includes(this.mediaType)) {
        return "video";
      }
      if (this.audio.includes(this.mediaType)) {
        return "audio";
      }
      return "file";
    },
    icon: function () {
      if (this.image.includes(this.mediaType)) {
        return "image";
      }
      if (this.video.includes(this.mediaType)) {
        return "video";
      }
      if (this.audio.includes(this.mediaType)) {
        return "music";
      }
      return "file-upload";
    },
    mediaFormat: function () {
      if (this.image.includes(this.mediaType)) {
        return "image/*";
      }
      if (this.video.includes(this.mediaType)) {
        return "video/*";
      }
      if (this.audio.includes(this.mediaType)) {
        return "audio/*";
      }
      return "";
    },
    isVideo: function () {
      return this.video.includes(this.mediaType);
    },
    isImage: function () {
      return this.image.includes(this.mediaType);
    },
    isAudio: function () {
      return this.audio.includes(this.mediaType);
    },
    isFile: function () {
      return this.file.includes(this.mediaType);
    },
  },
  watch: {
    media: {
      handler(val) {
        this.fileSrc = val?.data?.uri;
      },
      deep: true,
    },
    fileLoading: {
      handler(val) {
        this.$emit("setLoading", val);
      },
      deep: true,
    },
    mediaType: {
      handler(val) {
        this.currentMediaType = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.fileSrc = this.media?.data?.uri;
  },
  methods: {
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    deleteMedia() {
      this.$emit("deleteMedia");
      this.toggleDeleteModal();
    },
    setFileInfo(file) {
      if (file instanceof File) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    toggleImageModal() {
      this.showImageModal = !this.showImageModal;
    },
  },
};
</script>
