<template>
  <Form>
    <div class="backgroundCard px-8 pt-6 pb-8" :class="`${size}`">
      <div class="flex flex-col">
        <div class="mb-2">
          <span class="block text-white text-sm text-left font-bold mb-2">
            {{ $t("download.select_a_date_range") }}
          </span>
          <div
            class="flex justify-start items-center"
            :class="orientation === 'col' ? 'flex-col' : 'flex-row'"
          >
            <!--            <ValidationProvider-->
            <!--              name="start"-->
            <!--              rules="required|date_start_end:@end"-->
            <!--            >-->
            <v-date-picker
              v-model="newStart"
              :locale="locale"
              :available-dates="{
                start: null,
                end: newEnd ? newEnd : new Date(),
              }"
              :input-debounce="500"
              mode="date"
              :masks="
                locale === 'en'
                  ? { input: ['DD MMM YYYY'] }
                  : { input: ['DD-MM-YYYY'] }
              "
              :popover="{ visibility: 'focus' }"
              @dayclick="generate"
            >
              <template #default="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
            <!--            </ValidationProvider>-->

            <span class="flex-shrink-0 m-2">
              <svg
                class="w-4 h-4 stroke-current text-white"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
            <!--            <ValidationProvider-->
            <!--              name="end"-->
            <!--              rules="required|date_end_start:@start"-->
            <!--            >-->
            <v-date-picker
              v-model="newEnd"
              :locale="locale"
              :available-dates="{ start: newStart, end: new Date() }"
              :input-debounce="500"
              mode="date"
              :masks="
                locale === 'en'
                  ? { input: ['DD MMM YYYY'] }
                  : { input: ['DD-MM-YYYY'] }
              "
              :popover="{ visibility: 'focus' }"
              @dayclick="generate"
            >
              <template #default="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
            <!--            </ValidationProvider>-->
          </div>
        </div>
        <!--      <div class="flex flex-col justify-center items-start">-->
        <!--        <div-->
        <!--          class="rounded-md bg-red-50 p-2 w-auto mb-2"-->
        <!--          v-if="-->
        <!--            (errors.start || errors.end) &&-->
        <!--              (errors.start.length || errors.end.length)-->
        <!--          "-->
        <!--        >-->
        <!--          <div class="flex">-->
        <!--            <div class="flex-shrink-0">-->
        <!--              <svg-->
        <!--                class="h-5 w-5 text-red-400"-->
        <!--                fill="currentColor"-->
        <!--                viewBox="0 0 20 20"-->
        <!--              >-->
        <!--                <path-->
        <!--                  fill-rule="evenodd"-->
        <!--                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"-->
        <!--                  clip-rule="evenodd"-->
        <!--                />-->
        <!--              </svg>-->
        <!--            </div>-->
        <!--            <div class="ml-2">-->
        <!--              <div class="text-sm leading-5 text-red-700">-->
        <!--                {{ $t("reporting.the_end_date_is_before_start_date") }}-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
      </div>
    </div>
  </Form>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    locale: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "w-260",
    },
    orientation: {
      type: String,
      default: "row",
    },
  },
  data() {
    return {
      val: {},
      start: "",
      end: "",
    };
  },
  computed: {
    newStart: {
      get() {
        return this.start;
      },
      set(val) {
        val.setHours(0, 0, 0);
        this.start = val;
      },
    },
    newEnd: {
      get() {
        return this.end;
      },
      set(val) {
        val.setHours(23, 59, 59);
        this.end = val;
      },
    },
  },
  mounted() {
    this.start = this.value.start;
    this.end = this.value.end;
  },
  methods: {
    generate() {
      const range = {
        start: this.start,
        end: this.end,
      };
      this.$emit("return", range);
    },
  },
};
</script>

<style></style>
