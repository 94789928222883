<template>
  <div class="sm:mt-0 sm:col-span-2 mr-1">
    <div class="max-w-full rounded-md shadow-sm">
      <div class="flex flex-row justify-between mb-1">
        <label class="block text-sm font-medium leading-5 text-gray-700">
          {{ title }}
        </label>
        <span
          v-if="rules.includes('minArrayLength')"
          class="text-sm leading-5 text-gray-700"
        >
          {{ $t("game.required") }}
        </span>
      </div>
      <v-select
        :model-value="inputValue"
        :options="options"
        label="name"
        :reduce="(trophy) => parseInt(trophy.id)"
        multiple
        :selectable="(trophy) => selectableTrophies(trophy.id)"
        @update:modelValue="updateValue"
        @change="handleChange"
        @focusin="setFocus(true)"
        @focusout="setFocus(false)"
        :class="{
          '!border-red-300 !text-red-900 !placeholder-red-300 !focus:border-red-300 !focus:ring-red':
            !focus && errorMessage,
        }"
      ></v-select>
      <div
        v-if="!focus && errorMessage"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div v-if="!focus && errorMessage" class="h-6 mt-px">
      <p class="text-sm text-red-600">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "TrophySelect",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    selectedTrophies: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.title, props.rules, {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  data() {
    return {
      newSelectedTrophies: {
        type: Array,
        default: () => [],
      },
      focus: false,
    };
  },
  watch: {
    selectedTrophies: {
      handler(val) {
        this.newSelectedTrophies = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.newSelectedTrophies = this.selectedTrophies;
  },
  methods: {
    selectableTrophies(trophy) {
      return (
        !this.modelValue.some(
          (selectedTrophies) => selectedTrophies === parseInt(trophy)
        ) &&
        !this.newSelectedTrophies.some(
          (selectedTrophies) => selectedTrophies === parseInt(trophy)
        )
      );
    },
    updateValue(e) {
      this.$emit("update:modelValue", e);
    },
    setFocus(boolean) {
      this.focus = boolean;
    },
  },
};
</script>
