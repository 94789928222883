import Repository from "./Repository";

const resource = "/api/v3/lessons";

export default {
  getLessonMedia(lessonId, locale, page, maxResults) {
    const includes = "media,unity_package_action_property,action.step";
    return Repository.get(
      `${resource}/${lessonId}/media?include=${includes}&page=${page}&max_results=${maxResults}`,
      {
        headers: {
          "Accept-Language": locale,
        },
      }
    );
  },

  editLessonMedia(lessonId, actionPropertyId, locale, payload) {
    const includes = "media,unity_package_action_property,action.step";
    return Repository.patch(
      `${resource}/${lessonId}/media/${actionPropertyId}?include=${includes}`,
      payload,
      {
        headers: {
          "Accept-Language": locale,
        },
      }
    );
  },
};
