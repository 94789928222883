<template>
  <tr
    @click="$router.push({ name: 'customersDetail', params: { id: id } })"
    class="hover:bg-rowHover focus:bg-gray-100 cursor-pointer"
  >
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm leading-5 font-medium text-gray-100">
            {{ name }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ clientId }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <span
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-300 text-green-600"
      >
        Active
      </span>
    </td>
    <!-- <td class="px-6 py-4 whitespace-nowrap  text-sm leading-5 text-gray-200">
      {{ nrUsers }}
    </td> -->
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ startdate }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ stopdate }}
    </td>
    <td
      class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium"
    >
      <Button
        button-type="transparent"
        icon="edit"
        hover="blue"
        class="mr-1"
        @clickAction="$emit('edit')"
      />
      <!--      <Button-->
      <!--        icon="trash"-->
      <!--        size="m"-->
      <!--        hover="red"-->
      <!--        button-type="trashcan"-->
      <!--        @clickAction="$emit('delete')"-->
      <!--      />-->
    </td>
  </tr>
</template>

<script>
import Button from "@/components/form/Button";

export default {
  name: "TableRow",
  components: {
    Button,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    clientId: {
      type: String,
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
    enabled: {
      type: String,
      default: "",
    },
    nrUsers: {
      type: String,
      default: "",
    },
    startdate: {
      type: String,
      default: "",
    },
    stopdate: {
      type: String,
      default: "/",
    },
  },
};
</script>

<style></style>
