<template>
  <div class="flex flex-col w-full content-between">
    <div class="flex justify-end">
      <button
        @click="close"
        class="text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 cursor-pointer hover:bg-gray-300"
      >
        <svg class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div class="flex justify-center overflow-y-hidden border-b-0.5 pb-5">
      <div
        v-if="mediaType === 'image'"
        class="flex justify-center h-10/12 px-4"
      >
        <img
          :src="selectedMedia.uri"
          :alt="selectedMedia.name"
          class="max-w-full w-auto align-middle border-none"
        />
      </div>

      <div v-if="mediaType === 'audio'">
        <vue-plyr :key="selectedMedia.uri">
          <audio controls playsinline>
            <source :src="selectedMedia.uri" type="audio/mp3" />
            <source :src="selectedMedia.uri" type="audio/ogg" />
            <source :src="selectedMedia.uri" type="audio/wav" />
          </audio>
        </vue-plyr>
      </div>

      <div v-if="mediaType === 'video'" class="max-h-1/2">
        <vue-plyr :key="selectedMedia.uri">
          <video>
            <source :src="selectedMedia.uri" type="video/mp4" />
          </video>
        </vue-plyr>
      </div>
    </div>

    <div class="flex flex-1 flex-row mt-5">
      <div class="flex flex-col ml-3">
        <div class="flex flex-row">
          <span class="text-gray-500 w-1/4 text-14">
            {{ `${$t("general.file_name")}: ` }}
          </span>
          <span class="text-gray-700 w-3/4 leading-2 text-14 break-all">
            {{ selectedMedia.name }}
          </span>
        </div>
        <div class="flex flex-row">
          <span class="text-gray-500 w-1/4 text-14">
            {{ `${$t("general.size")}: ` }}
          </span>
          <span class="text-gray-700 w-3/4 leading-2 text-14">
            {{ formatBytes(selectedMedia.size) }}
          </span>
        </div>
        <div class="flex flex-row">
          <span class="text-gray-500 w-1/4 text-14">
            {{ `${$t("general.url")}: ` }}
          </span>
          <span class="text-gray-700 w-3/4 leading-2 text-14 break-all">
            {{ selectedMedia.uri }}
          </span>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-end mt-5">
      <div
        v-if="selectedMedia.uri"
        class="inline-flex inline-flex rounded-md shadow-sm mr-3 w-auto shadow-sm items-end"
      >
        <a
          :href="selectedMedia.uri"
          rel="noopener noreferrer"
          download
          target="_blank"
          class="flex items-center justify-center border border-transparent font-medium focus:outline-none transition ease-in-out duration-150 bg-green-500 text-gray-100 w-auto rounded-md h-10 px-4 py-2 text-sm leading-5 font-medium text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
        >
          <font-awesome-icon icon="download" />
        </a>
      </div>

      <Button
        class="items-end"
        type="button"
        button-type="editor-primary"
        hover="blue"
        title="Select"
        @clickAction="selectFile"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/form/Button";
import formatBytes from "@/mixins/formatBytes";
export default {
  name: "MediaViewer",
  components: { Button },
  mixins: [formatBytes],
  props: {
    selectedMedia: {
      type: Object,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
  },
  emits: ["selectFile", "close"],
  methods: {
    selectFile() {
      this.$emit("selectFile");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
