<template>
  <div class="flex justify-center flex-col items-center h-550" v-if="loading">
    <Loader />
    <p class="text-17 font-medium text-white leading-none mt-6">
      {{ $t("reporting.loading_content") }}...
    </p>
  </div>
  <div v-else>
    <div class="flex flex-row justify-start mt-30">
      <DatePicker
        :value="selectedDate"
        :locale="this.$i18n.locale"
        size="w-auto"
        @return="updateSelectedDate"
      />
    </div>
    <div class="flex flex-col backgroundCard mt-15 w-full">
      <highcharts class="pt-30" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { Chart } from "highcharts-vue";
import { format, subDays } from "date-fns";
import { chartStylingOptions } from "@/config";
import { monthIndexes } from "@/helpers/dateHelpers";
import DatePicker from "../../components/reporting/DatePicker";
import Loader from "../../components/helpers/Loader";
import Highcharts from "highcharts";

const reportingRepository = RepositoryFactory.get("reporting");

export default {
  name: "Playtime",
  components: {
    highcharts: Chart,
    DatePicker,
    Loader,
  },
  data() {
    return {
      selectedDate: {
        start: subDays(new Date(), 365),
        end: new Date(),
      },
      sortedSeries: { one: null },
      gameData: [],
      users: null,
      loading: true,
    };
  },
  computed: {
    chartOptions() {
      return {
        ...chartStylingOptions,
        title: {
          text: "",
        },
        chart: {
          backgroundColor: "#ffffff00",
          type: ["column"],
          marginRight: 40,
          animation: false,
        },
        series: [
          {
            name: this.$t("reporting.active_users"),
            type: "column",
            color: "#7cb5ec",
            borderWidth: 0,
            data: this.sortedSeries.one,
            dataLabels: {
              style: {
                color: "white",
                fontSize: "11px",
                fontWeight: "medium",
                textOutline: `1px ${process.env.VUE_APP_SECONDARY_COLOR}`,
              },
              enabled: true,
              crop: false,
              overflow: "none",
              inside: false,
            },
          },
        ],
        xAxis: {
          type: "datetime",
          lineColor: "white",
          gridLineColor: "black",
          labels: {
            style: {
              color: "#ffffff",
            },
            formatter: function () {
              return Highcharts.dateFormat("%b %Y", this.value);
            },
          },
        },
        yAxis: {
          gridLineColor: "#ffffff00",
          title: {
            style: {
              color: "white",
            },
            text: this.$t("reporting.number_of_users"),
          },
          labels: {
            style: {
              color: "#ffffff",
            },
          },
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `${format(this.x, "MMMM")} : ${this.y}`;
          },
        },
      };
    },
  },
  watch: {
    selectedDate: {
      handler() {
        this.gameData = [];
        this.loading = true;
        this.fetch();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      reportingRepository
        .getMonthlyNumberOfUsersPlayedLevel(
          format(this.selectedDate.start, "yyyy-MM-dd"),
          format(this.selectedDate.end, "yyyy-MM-dd")
        )
        .then((result) => {
          this.createSeries(result.data.total, this.gameData, 0);
        });
    },
    createSeries(result, data, series) {
      result.map((r) => {
        data.push({
          x: new Date(r.year, monthIndexes[r.month.toLowerCase()]),
          y: parseInt(r.users),
        });
      });
      this.sortedSeries.one = data.sort(function (a, b) {
        return new Date(a.x) - new Date(b.x);
      });
      this.loading = false;
    },
    updateSelectedDate(date) {
      this.selectedDate = date;
    },
  },
};
</script>
