<template>
  <div
    class="w-64 shadow rounded-md mr-4 cursor-move"
    :class="subchapter.active && parentActive ? 'bg-blue-100' : 'bg-gray-100'"
    style="height: fit-content"
  >
    <div class="py-2 px-3">
      <div v-if="subchapter" class="flex flex-row justify-between items-center">
        <h3
          class="text-lg font-semibold cursor-move"
          :class="
            subchapter.active && parentActive
              ? 'text-blue-900'
              : 'text-gray-500'
          "
          @click="toggle()"
        >
          {{ subchapter.name }}
        </h3>
        <div class="relative inline-block text-left">
          <div class="flex">
            <OptionMenu
              class="mr-4"
              :options="optionsChapter"
              color="text-gray-400"
              :separation-line="1"
              icon="cog"
              @editSubChapter="editSubChapter(subchapter, chapter)"
              @importLesson="currentAction = 'importLesson'"
              @addLevel="addLevel(subchapter, chapter)"
              @deleteSubChapter="
                currentId = subchapter.id;
                currentAction = 'deleteSubchapter';
              "
            />
            <button
              class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              aria-label="Options"
              id="options-menu"
              @click="toggle()"
            >
              <font-awesome-icon
                icon="chevron-up"
                class="rotate"
                :class="rotate"
              />
            </button>
          </div>
        </div>
      </div>
      <!-- Backlog draggable component. Pass arrBackLog to list prop -->
      <draggable
        v-if="showLevel && subchapter"
        :id="subchapter.id"
        @end="changed($event)"
        class="kanban-column flex flex-col pt-2 flex-1"
        :list="subchapter.children"
        item-key="id"
        group="levels"
      >
        <template #item="{ element }">
          <div :id="element.id">
            <div
              v-if="element"
              class="flex items-center px-2 py-1 rounded-md mb-1 text-16 shadow justify-between hover:bg-blue-500 hover:text-gray-50 cursor-move"
              :class="
                ({
                  'border-red-500 border-2': element.error_state,
                },
                element.active && subchapter.active && parentActive
                  ? 'bg-white text-black'
                  : 'bg-gray-100 text-gray-500')
              "
              :key="element.id"
              :id="element.id"
            >
              <div
                class="flex flex-row justify-between flex-1"
                @click="
                  $router.push({
                    name: 'timeline',
                    params: {
                      id: element.id,
                    },
                  })
                "
              >
                <p>
                  {{ element.name }}
                </p>
                <ErrorPopup
                  v-if="element.error_state"
                  :title="$t('editor.this_lesson_contains_an_error')"
                  icon-size="s"
                  class="flex items-center justify-center mr-1 ml-1 cursor-default"
                />
              </div>
              <div class="relative inline-block text-left">
                <div class="flex">
                  <OptionMenu
                    class="mr-1 ml-2"
                    :options="optionsLevel"
                    color="text-gray-400"
                    :separation-line="1"
                    icon="cog"
                    @editLevel="editLevel(subchapter, chapter, element)"
                    @deleteLevel="
                      currentId = element.id;
                      currentAction = 'deleteLevel';
                    "
                    @downloadExport="
                      currentId = element.id;
                      currentAction = 'exportLesson';
                    "
                    @levelTranslations="lessonMediaTranslations(element.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>
      <div v-if="currentAction === 'deleteLevel'">
        <AcceptModal
          :header-text="$t('editor.delete_level')"
          :sub-header-text="
            $t('editor.are_you_sure_you_want_to_delete_this_level')
          "
          :accept-button-text="$t('general.accept')"
          :cancel-button-text="$t('general.cancel')"
          :loading="deleteLoading"
          @accept="deleteLevel(currentId)"
          @cancel="currentAction = ''"
        />
      </div>
      <div v-if="currentAction === 'exportLesson'">
        <ExportModal
          :header-text="$t('editor.export_lesson')"
          :sub-header-text="$t('editor.export_this_lesson_as_json')"
          target="lesson"
          :lesson-id="currentId"
          @close="currentAction = ''"
        />
      </div>
      <div v-if="currentAction === 'importLesson'">
        <ImportModal
          :header-text="$t('editor.import_lesson')"
          :sub-header-text="$t('editor.upload_json_to_lesson')"
          target="lesson"
          :training-id="trainingId"
          :version-id="versionId"
          :subchapter-id="subchapter.id"
          @close="currentAction = ''"
        />
      </div>
      <div v-if="currentAction === 'deleteSubchapter'">
        <AcceptModal
          :header-text="$t('editor.delete_subchapter')"
          :sub-header-text="
            $t('editor.are_you_sure_you_want_to_delete_this_subchapter')
          "
          :accept-button-text="$t('general.accept')"
          :cancel-button-text="$t('general.cancel')"
          @accept="deleteSubChapter(subchapter.id)"
          @cancel="showDeleteModal = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import OptionMenu from "@/components/helpers/OptionMenu";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { showNotification } from "@/services/notificationService";
import ExportModal from "@/views/editor/components/modals/ExportModal";
import ImportModal from "@/views/editor/components/modals/ImportModal";
import AcceptModal from "@/components/helpers/modals/AcceptModal";
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
const trainingRepository = RepositoryFactory.get("training");

export default {
  name: "Subchapter",
  components: {
    draggable,
    OptionMenu,
    ExportModal,
    ImportModal,
    AcceptModal,
    ErrorPopup,
  },
  props: {
    subchapter: {
      type: Object,
      default: () => {},
    },
    chapter: {
      type: Object,
      default: () => {},
    },
    parentActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showLevel: this.subchapter.active,
      showExportModal: false,
      showImportModal: false,
      showDeleteModal: false,
      deleteLoading: false,
      currentId: null,
      currentAction: "",
      optionsLevel: [
        { option: "editLevel", name: "Edit level" },
        { option: "deleteLevel", name: "Delete level" },
        { option: "levelTranslations", name: "Media Translations" },
        { option: "downloadExport", name: "Export as JSON" },
      ],
    };
  },
  computed: {
    ...mapState("trainingStore", [
      "training_version",
      "chapters",
      "showLevelModal",
      "versionId",
      "trainingId",
    ]),
    optionsChapter: function () {
      if (this.subchapter.children) {
        return [
          { option: "importLesson", name: "Import lesson" },
          { option: "addLevel", name: "Add level" },
          { option: "editSubChapter", name: "Edit subchapter" },
        ];
      } else {
        return [
          { option: "importLesson", name: "Import lesson" },
          { option: "addLevel", name: "Add level" },
          { option: "editSubChapter", name: "Edit subchapter" },
          { option: "deleteSubChapter", name: "Delete subchapter" },
        ];
      }
    },
    rotate: function () {
      return this.showLevel ? "up" : "down";
    },
  },
  methods: {
    addLevel(subchapter, chapter) {
      this.$store.dispatch("trainingStore/SET_ACTIVE_CHAPTER", chapter);
      this.$store.dispatch("trainingStore/SET_ACTIVE_SUBCHAPTER", subchapter);
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "addLevel");
    },
    editLevel(subchapter, chapter, level) {
      this.$store.dispatch("trainingStore/SET_ACTIVE_CHAPTER", chapter);
      this.$store.dispatch("trainingStore/SET_ACTIVE_SUBCHAPTER", subchapter);
      this.$store.dispatch("trainingStore/SET_ACTIVE_LEVEL", level);
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "editLevel");
    },
    deleteLevel(id) {
      this.deleteLoading = true;
      trainingRepository
        .deleteLesson(this.trainingId, this.versionId, id)
        .then(() => {
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
          showNotification(
            this.$t("notifications.level_deleted"),
            this.$t("notifications.level_is_successfully_deleted"),
            "success"
          );
          this.currentAction = "";
        })
        .catch((err) => {
          showNotification(
            err,
            this.$t(
              "notifications.something_went_wrong_while_deleting_this_level"
            ),
            "error"
          );
        })
        .finally(() => (this.deleteLoading = false));
    },
    editSubChapter(subchapter, chapter) {
      this.$store.dispatch("trainingStore/SET_ACTIVE_CHAPTER", chapter);
      this.$store.dispatch("trainingStore/SET_ACTIVE_SUBCHAPTER", subchapter);
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "editSubChapter");
    },
    deleteSubChapter(subchapterId) {
      trainingRepository
        .deleteChapter(this.trainingId, this.versionId, subchapterId)
        .then(() => {
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
          showNotification(
            this.$t("notifications.sub_chapter_deleted"),
            this.$t("notifications.sub_chapter_is_successfully_deleted"),
            "success"
          );
        })
        .catch((err) => {
          showNotification(
            err,
            this.$t(
              "notifications.something_went_wrong_while_deleting_this_subchapter"
            ),
            "error"
          );
        });
    },
    toggle() {
      this.showLevel = !this.showLevel;
    },
    async changed(event) {
      const oldParentId = event.from.id;
      const newParentId = event.to.id;
      const newIndex = event.newDraggableIndex;
      const oldIndex = event.oldDraggableIndex;
      if (newParentId !== oldParentId || newIndex !== oldIndex) {
        const lesson = {
          sort: newIndex,
          parent: newParentId,
        };
        trainingRepository
          .editLevel(this.trainingId, this.versionId, event.item.id, lesson)
          .then(() => {
            this.$store.dispatch("trainingStore/GET_CHAPTERS");
            showNotification(
              this.$t("notifications.sort_level"),
              this.$t("notifications.level_is_successfully_sorted"),
              "success"
            );
          })
          .catch(() => {
            showNotification(
              this.$t("notifications.sort_level"),
              this.$t(
                "notifications.something_went_wrong_while_sorting_this_level"
              ),
              "error"
            );
          });
      }
    },

    toggleExportModal() {
      this.showExportModal = !this.showExportModal;
    },

    lessonMediaTranslations(levelId) {
      this.$router.push({
        name: "lessonTranslations",
        params: { id: levelId },
      });
    },
  },
};
</script>

<style>
.kanban-column {
  min-height: 160px;
}
</style>
