<template>
  <!--  <ValidationProvider-->
  <!--    :name="title"-->
  <!--    :rules="`${requiredInput ? 'required' : ''}`"-->
  <!--    v-slot="{ errors }"-->
  <!--  >-->
  <div class="w-full mt-1">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div class="flex justify-between items-center">
        <v-select
          class="choice-select"
          v-model="inputValue"
          :options="options"
          :clearable="!requiredInput"
          label="name"
          @update:modelValue="updateValue"
        >
          <template #search="{ attributes, events }">
            <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
              @focusin="disableShortcuts(true)"
              @focusout="disableShortcuts(false)"
            />
          </template>
        </v-select>
        <font-awesome-icon
          icon="clipboard"
          class="text-white w-5 h-5 outline-none ml-1 cursor-pointer hover:text-blue-500"
          @click="copyValue"
        />
      </div>
      <div v-if="showCopied">
        <p class="text-sm text-green-500 mt-1">
          {{ $t("editor.copied_to_clipboard") }}
        </p>
      </div>
      <div
        v-if="errorMessage"
        class="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>

    <div class="h-6 mt-px">
      <p v-if="errorMessage" class="text-sm text-red-600">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import { useField } from "vee-validate";
import { disableShortcuts } from "@/services/disableShortcutsService";

export default {
  name: "Choice",
  components: { ErrorPopup },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
      required: false,
    },
    defaultValue: {
      type: [String, Number],
      default: "",
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "update"],
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.title, props.requiredInput ? "required" : "", {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      inputValue,
      meta,
    };
  },
  data() {
    return {
      showCopied: false,
    };
  },
  mounted() {
    // This is needed to set the already selected TTS voice or other sibling related data
    this.$emit("update", {
      propertyName: this.title,
      value: this.inputValue,
    });
  },
  methods: {
    updateValue() {
      this.$emit("update", {
        propertyName: this.title,
        value: this.inputValue,
      });
      this.$emit("update:modelValue", this.inputValue);
    },
    disableShortcuts(boolean) {
      disableShortcuts(boolean);
    },
    copyValue() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      clipboardData.writeText(this.inputValue);
      this.showCopied = true;
      setTimeout(() => (this.showCopied = false), 1500);
    },
  },
};
</script>

<style>
.choice-select {
  width: 100%;
  color: white;
}
.choice-select .vs__dropdown-toggle,
.choice-select .style-chooser__title,
.choice-select .vs-state-active-color,
.choice-select .vs-dropdown-bg,
.choice-select .vs__dropdown-option,
.choice-select .vs__dropdown-menu {
  background: #252525;
  border-color: rgb(107, 114, 128);
  color: white;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.25rem;
  padding: 0.5rem 0.5rem 0.5rem;
}

.choice-select .vs__selected {
  color: white;
}

.choice-select .vs__selected-options {
  overflow: hidden;
  overflow-wrap: normal;
  word-break: break-word;
  width: 100%;
  color: white;
}

.choice-select .vs__dropdown-option {
  width: 100%;
}

.choice-select .vs__clear,
.choice-select .vs__open-indicator {
  fill: grey;
}
</style>
