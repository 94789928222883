<template>
  <div class="flex justify-center flex-col items-center h-550" v-if="loading">
    <Loader />
    <p class="text-17 font-medium text-white leading-none mt-6">
      {{ $t("reporting.loading_content") }}...
    </p>
  </div>
  <div v-else>
    <div class="flex flex-row justify-start mt-30">
      <DatePicker
        :value="selectedDate"
        :locale="this.$i18n.locale"
        size="w-auto"
        @return="updateSelectedDate"
      />
    </div>
    <div class="flex flex-col backgroundCard mt-15 w-full">
      <div class="flex flex-row justify-end items-center">
        <div class="mt-35 text-17 font-medium text-gray-50 leading-none mr-3">
          {{ $t("reporting.sort") }}
        </div>
        <div
          class="mr-35 mt-35 w-140 self-end flex bg-standardBgColor items-center relative rounded-full"
        >
          <select
            class="appearance-none w-full h-10 text-darkAlineaColor px-4 rounded-full font-medium leading-tight"
            id="grid-state"
            v-model="selectedKey"
          >
            <option value="DESC">
              {{ $t("reporting.top") }}
            </option>
            <option value="ASC">
              {{ $t("reporting.bottom") }}
            </option>
          </select>
          <div
            class="pointer-events-none items-center mr-2 absolute right-0 text-darkAlineaColor"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
      <highcharts class="pt-30" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import i18n from "@/i18n";
import { Chart } from "highcharts-vue";
import { format, subDays } from "date-fns";
import Loader from "@/components/helpers/Loader";
import { chartStylingOptions } from "@/config";
import DatePicker from "@/components/reporting/DatePicker";
const reportingRepository = RepositoryFactory.get("reporting");

export default {
  name: "Playtime",
  components: {
    highcharts: Chart,
    DatePicker,
    Loader,
  },
  data() {
    return {
      selectedDate: {
        start: subDays(new Date(), 365),
        end: new Date(),
      },
      selectedKey: "DESC",
      topUsers: [],
      topTimes: [],
      loading: true,
    };
  },
  computed: {
    chartOptions() {
      return {
        ...chartStylingOptions,
        title: {
          text: "",
        },
        chart: {
          height: 600,
          backgroundColor: "#ffffff00",
          type: "bar",
          marginRight: 40,
          animation: false,
        },
        series: [
          {
            data: this.topTimes,
            borderWidth: 0,
            dataLabels: {
              style: {
                color: "white",
                fontSize: "11px",
                fontWeight: "medium",
                textOutline: `1px ${process.env.VUE_APP_SECONDARY_COLOR}`,
              },
              enabled: true,
              crop: false,
              overflow: "none",
              inside: false,
            },
          },
        ],

        xAxis: {
          type: "datetime",
          labels: {
            style: {
              color: "#ffffff",
            },
          },
          categories: this.topUsers,
        },
        yAxis: {
          type: "datetime",
          gridLineColor: "#ffffff00",
          title: {
            text: this.$t("reporting.hours"),
            style: {
              color: "white",
            },
          },
          labels: {
            style: {
              color: "#ffffff",
            },
            formatter: function () {
              const hours = parseInt(
                this.value / 3600000 < 0 ? 0 : this.value / 3600000
              );
              return `${hours.toLocaleString(
                i18n.global.locale.value
              )}${i18n.global.t("reporting.h")}`;
              // ":" +
              // ("0" + secs1).slice(-2)
            },
          },
        },
        legend: {
          itemStyle: {
            color: "white",
          },
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            const hours = parseInt(this.y / 3600000);
            const mins = parseInt((this.y % 3600000) / 60000);
            return (
              this.x +
              ":    " +
              hours.toLocaleString(i18n.global.locale.value) +
              " " +
              i18n.global.t("reporting.hours") +
              " " +
              mins.toLocaleString(i18n.global.locale.value) +
              " " +
              i18n.global.t("reporting.minutes")
            );
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              formatter: function () {
                const hours = parseInt(this.y / 3600000);
                const mins = parseInt((this.y % 3600000) / 60000);
                return (
                  `${hours.toLocaleString(
                    i18n.global.locale.value
                  )}${i18n.global.t("reporting.h")}` +
                  " " +
                  `${mins.toLocaleString(
                    i18n.global.locale.value
                  )}${i18n.global.t("reporting.m")}`
                );
              },
            },
          },
        },
      };
    },
  },
  watch: {
    selectedDate: {
      handler() {
        this.topUsers = [];
        this.topTimes = [];
        this.loading = true;
        this.fetch();
      },
      deep: true,
    },
    selectedKey: {
      handler(val) {
        this.topUsers = [];
        this.topTimes = [];
        this.loading = true;
        this.fetch();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      reportingRepository
        .getTopPlaytime(
          format(this.selectedDate.start, "yyyy-MM-dd"),
          format(this.selectedDate.end, "yyyy-MM-dd"),
          this.selectedKey
        )
        .then((result) => {
          this.setAxis(this.sort(result.data.total).reverse());
          this.loading = false;
        });
    },

    setAxis(result) {
      result.forEach((r) => {
        this.topUsers.push(`${r.firstName} ${r.surName}`);
        if (r.Seconds === null) {
          this.topTimes.push(0);
        } else {
          this.topTimes.push(parseInt(r.Seconds) * 1000);
        }
      });
    },

    sort(results) {
      return results.sort((a, b) => {
        const valA = parseInt(a.Seconds);
        const valB = parseInt(b.Seconds);

        let comparison = 0;
        if (valA > valB) {
          comparison = 1;
        } else if (valA < valB) {
          comparison = -1;
        }

        return comparison;
      });
    },

    updateSelectedDate(date) {
      this.selectedDate = date;
    },
  },
};
</script>
