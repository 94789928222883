<template>
  <v-date-picker
    class="date-picker"
    :mode="mode"
    v-model="pickerDate"
    :masks="
      locale === 'en' ? { input: ['DD MMM YYYY'] } : { input: ['DD-MM-YYYY'] }
    "
    :popover="{ visibility: 'focus' }"
  >
    <template #default="{ inputValue, inputEvents }">
      <div v-if="title || requiredInput" class="flex justify-between mb-1">
        <span class="block text-sm font-medium leading-5 text-gray-100 mb-1">
          {{ title }}
        </span>
        <span
          v-if="requiredInput"
          class="text-sm font-medium leading-5 text-gray-100"
        >
          {{ $t("game.required") }}
        </span>
      </div>
      <input
        class="form-input block w-full py-2 px-3 border border-gray-300 text-gray-100 bg-rowHover rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        :class="[
          {
            'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
              errors.length,
          },
        ]"
        type="text"
        :placeholder="locale === 'en' ? 'DD MMM YYYY' : 'DD-MM-YYYY'"
        :value="inputValue"
        @input="updateDate"
        v-on="inputEvents"
      />
      <div
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          v-if="errors.length"
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>

      <Field
        as="div"
        :name="title"
        :rules="rules"
        class="h-6"
        v-model="pickerDate"
        v-slot="{ errors: datePickerError }"
      >
        <div v-if="datePickerError.length" class="h-6 mt-px">
          <p class="text-sm text-red-600">
            {{ datePickerError[0] }}
          </p>
        </div>
      </Field>
    </template>
  </v-date-picker>
</template>

<script>
import { format } from "date-fns";
import { Field } from "vee-validate";
export default {
  name: "DatePicker",
  components: { Field },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: "",
    },
    locale: {
      type: String,
      default: "en",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerDate: null,
      formattedDate: null,
      mode: "single",
      errors: [],
    };
  },
  watch: {
    modelValue: {
      handler(val) {
        this.pickerDate = new Date(val);
      },
      deep: true,
    },
    pickerDate: {
      handler(val) {
        if (val instanceof Date && !isNaN(val)) {
          this.formattedDate = format(new Date(val), "yyyy-MM-dd");
          this.$emit("update", this.formattedDate);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.pickerDate = this.modelValue ?? null;
  },
  methods: {
    updateDate(e) {
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>

<style>
.date-picker .is-interactive {
  margin-top: -110px;
}
</style>
