<template>
  <div class="w-full">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-700"
      >
        {{ title }}
      </label>
      <span v-if="requiredInput" class="text-sm leading-5 text-gray-500">
        {{ $t("game.required") }}
      </span>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <textarea
        v-on="validationListeners"
        class="form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        :class="{
          'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
            errorMessage,
        }"
        :rows="rows"
        :placeholder="placeholder"
        :value="inputValue"
        @focusin="setFocus(true)"
        @focusout="setFocus(false)"
        ref="input"
      />
      <div
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          v-if="errorMessage"
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="h-6 mt-px">
      <p class="text-sm text-red-600">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { computed } from "vue";
import useValidation from "@/helpers/useValidation";

export default {
  name: "TextArea",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 3,
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: "",
    },
    cy: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { validationListeners, errorMessage, inputValue } = useValidation(
      props,
      emit
    );

    return {
      validationListeners,
      errorMessage,
      inputValue,
    };
  },
  data() {
    return {
      focus: false,
    };
  },
  methods: {
    setFocus(boolean) {
      this.focus = boolean;
    },
  },
};
</script>
