const state = {
  showModal: false,
  showStartGameInfoModal: false,
  startGameInfoModal: {
    type: "",
    download_url: "",
  },
  message: "",
  title: "",
  type: "", // succes, error, warning
  required: false,
  accept: () => {},
  cancel: () => {},
};

const getters = {};

const actions = {
  SHOW_MODAL: ({ commit, dispatch }, payload) => {
    commit("SHOW_MODAL", payload);
  },
  HIDE_MODAL: ({ commit, dispatch }) => {
    commit("HIDE_MODAL", payload);
  },

  SHOW_GAME_INFO_MODAL: ({ commit }, payload) => {
    commit("SHOW_GAME_INFO_MODAL", payload);
  },
  HIDE_GAME_INFO_MODAL: ({ commit }) => {
    commit("HIDE_GAME_INFO_MODAL");
  },
};

const mutations = {
  SHOW_MODAL: (state, payload) => {
    state.showModal = true;
    state.required = payload.required ? payload.required : false;
    state.message = payload.message;
    state.title = payload.title;
    state.type = payload.type;
  },
  HIDE_MODAL: (state) => {
    state.showModal = false;
    state.required = false;
    state.message = "";
    state.title = "";
    state.type = "";
  },
  SHOW_GAME_INFO_MODAL: (state, payload) => {
    state.showStartGameInfoModal = true;
    state.startGameInfoModal.type = payload.type;
    state.startGameInfoModal.download_url = payload.download_url;
  },
  HIDE_GAME_INFO_MODAL: (state) => {
    state.showStartGameInfoModal = false;
    state.startGameInfoModal.type = "";
    state.startGameInfoModal.download_url = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
