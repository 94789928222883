import { getOS } from "@/helpers/osCheck";

export function getDownloadLinkForPlatform(gamedata) {
  return new Promise(function (resolve, reject) {
    const app = gamedata.find((app) => app.platform_slug === getOS());
    if (app) {
      resolve(`${app.file}`);
    } else {
      resolve("empty");
    }
  });
}

export async function getAlternativeDownloadableFiles(gamedata) {
  return new Promise(function (resolve, reject) {
    const alternativeGames = [];
    gamedata.map((app) => {
      if (app.platform_slug !== getOS()) {
        alternativeGames.push(app);
      }
    });
    resolve(alternativeGames);
  });
}

export async function formatTraining(training) {
  return {
    id: training.id,
    appUri: training.app_uri,
    flipped: false,
    imageUrl: training.image.uri,
    active: training.active,
    description: training.intro,
    introduction: training.intro,
    title: training.name,
    version: training.latest_version ? training.latest_version.data.id : null,
    versions: training.versions?.data,
    storeLinks: {
      playstore: training.googlePlayUrl,
      appstore: training.appStoreUrl,
    },
    downloadableFile: training.latest_version
      ? training.latest_version.data.applications.data.length > 0
        ? await getDownloadLinkForPlatform(
            training.latest_version.data.applications.data
          )
        : null
      : null,
    downloadAvailable: training.latest_version
      ? training.latest_version.data.applications.data.length > 0 &&
        (await getDownloadLinkForPlatform(
          training.latest_version.data.applications.data
        )) !== "empty"
      : null,
    alternativeDownloadableFiles: training.latest_version
      ? training.latest_version.data.applications.data.length > 0
        ? await getAlternativeDownloadableFiles(
            training.latest_version.data.applications.data
          )
        : null
      : null,
    oauth_client: training.oauth_client ? training.oauth_client.data : null,
  };
}
