<template>
  <div
    class="w-gameCardWidth h-gameCardHeight shadow-darkBg rounded-15 flex flex-col"
    :class="[activeColor]"
  >
    <div class="flex-1 flex flex-row">
      <div class="bg-grey">
        <p class="ml-5 mt-5 font-bold text-20 text-white">
          {{ $t("home.description") }}
        </p>
        <p class="ml-5 mt-1 text-14 text-white font-normal">
          {{ game.description ? game.description : "No description" }}
        </p>
      </div>
      <div class="flex-1 flex justify-end mr-5">
        <font-awesome-icon
          :icon="['fa', 'undo']"
          class="ml-5 mt-25 text-24 text-lightAlineaColor cursor-pointer hover:text-lightAlineaColor"
          @click="$emit('backFlip')"
        />
      </div>
    </div>
    <div class="flex-1 flex flex-col">
      <p class="ml-5 font-bold text-20 text-white">
        {{ $t("home.downloads") }}
      </p>
      <div class="flex">
        <a
          v-if="game.storeLinks.playstore"
          :href="game.storeLinks.playstore"
          rel="noopener noreferrer"
        >
          <font-awesome-icon
            :icon="['fab', 'google-play']"
            class="ml-5 mt-5 text-24 text-primaryColor cursor-pointer hover:text-lightAlineaColor"
          />
        </a>
        <a
          v-if="game.storeLinks.appstore"
          :href="game.storeLinks.appstore"
          rel="noopener noreferrer"
        >
          <font-awesome-icon
            :icon="['fab', 'app-store-ios']"
            class="ml-5 mt-5 text-24 text-primaryColor cursor-pointer hover:text-lightAlineaColor"
          />
        </a>
        <a
          v-for="item in game.alternativeDownloadableFiles"
          :key="item.platform.data.id"
          :href="item.file"
          rel="noopener noreferrer"
          download
        >
          <font-awesome-icon
            :icon="['fab', `${item.platform.data.icon.substr(6)}`]"
            class="ml-5 mt-5 text-24 text-primaryColor cursor-pointer hover:text-lightAlineaColor"
          />
        </a>
        <p
          v-if="
            !game.storeLinks.playstore &&
            !game.storeLinks.appstore &&
            (!game.alternativeDownloadableFiles ||
              !game.alternativeDownloadableFiles.length)
          "
          class="ml-5 mt-1 text-14 text-white font-normal"
        >
          {{ $t("home.no_alternative_downloads") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBack",
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activeColor: function () {
      if (this.game.active) return "bg-secondaryColor border-primaryColor";
      else return "bg-gray-400 border-gray-600 opacity-50";
    },
  },
};
</script>
