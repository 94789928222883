<template>
  <div
    id="nav-mobile"
    class="flex px-1 flex-row-reverse justify-between items-center h-loginPaddingTopBottom bg-secondaryColor"
  >
    <font-awesome-icon
      :icon="'bars'"
      class="fa-lg mr-1 text-white"
      @click="toggleMenu = !toggleMenu"
    />

    <transition name="slide-fade">
      <div
        v-if="toggleMenu"
        class="menu-panel bg-bgColor flex flex-col justify-between bg-primaryColor"
      >
        <div class="flex justify-end items-center h-60 pr-1">
          <font-awesome-icon
            :icon="'times'"
            class="fa-lg mr-1 text-white"
            @click="toggleMenu = !toggleMenu"
          />
        </div>
        <div
          id="nav-button-container-student"
          class="flex-col flex-1 mt-5 px-1"
        >
          <NavButton
            :title="$t('navigation.home')"
            icon="home"
            :link="$router.resolve({ name: 'home' }).href"
          ></NavButton>
          <NavButton
            :title="$t('navigation.results')"
            icon="poll-h"
            :link="$router.resolve({ name: 'results' }).href"
          ></NavButton>
          <NavButton
            :title="$t('navigation.highscore')"
            icon="list-alt"
            :link="$router.resolve({ name: 'highscores' }).href"
          ></NavButton>
          <NavButton
            v-if="currentUser"
            :title="currentUser.firstname"
            icon="user-circle"
            :link="$router.resolve({ name: 'my-profile' }).href"
          ></NavButton>
          <div
            v-if="showActivationButton"
            class="nav-btn w-full group flex flex-col items-start px-2 py-2 mb-1 transition ease-in-out duration-150 border-0.5 border-lightAlineaColor rounded-md"
          >
            <span
              class="text-lightAlineaColor leading-6 text-sm mb-2 font-light"
            >
              {{
                $t(
                  "layout.You_are_working_in_demo_mode_to_activate_your_account_press_the_button_below"
                )
              }}
            </span>
            <Button
              :title="$t('profile.activate')"
              button-type="editor-secondary"
              hover="blue"
              @clickAction="toggleActivationModal"
            />
          </div>
        </div>

        <div
          id="nav-button-container-admin"
          v-if="showAdminPanel"
          class="flex-col flex-1 mt-5 px-1"
        >
          <p class="text-lightAlineaColor pl-2 py-2 text-navTitle font-bold">
            ADMIN
          </p>
          <NavButton
            :title="$t('navigation.reporting')"
            icon="chart-pie"
            :link="$router.resolve({ name: 'playtime' }).href"
          ></NavButton>
          <NavButton
            :title="$t('navigation.users')"
            icon="users"
            :external="true"
            :link="`${baseUrl}admin/user`"
          ></NavButton>
          <NavButton
            :title="$t('navigation.game')"
            icon="gamepad"
            :external="true"
            :link="`${baseUrl}admin/training`"
          ></NavButton>
        </div>

        <div
          id="nav-button-container-logout"
          class="flex flex-row w-full items-center text-lightAlineaColor hover:bg-navButtonBackground px-1 ml-10 py-10 rounded"
        >
          <font-awesome-icon icon="sign-out-alt" class="fa-lg" />
          <a class="text-navButtonText ml-15" @click="logout">{{
            $t("navigation.logout")
          }}</a>
        </div>
      </div>
    </transition>

    <img
      :src="require(`@/assets/theme/logo.png`)"
      :alt="$t('auth.virtualstudio')"
      class="h-mobileLogo flex self-center center"
    />
    <Badge title="EN" rounded="corners" />
  </div>
</template>

<script>
import NavButton from "./NavButton";
import Badge from "../helpers/Badge";
import { adminRoles } from "../../config";
import { mapState } from "vuex";
import Button from "@/components/form/Button";

export default {
  name: "NavigationMobile",
  components: {
    NavButton,
    Badge,
    Button,
  },
  props: {
    username: {
      type: String,
      default: "Your name",
    },
    roles: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      width: screen.width.toString(),
      project: localStorage.getItem("project"),
      toggleMenu: false,
      showAdminPanel: false,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    showActivationButton: function () {
      return this.currentUser.demo;
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        this.toggleMenu = false;
      },
      deep: true,
    },
    roles: {
      handler(val) {
        this.showAdminPanel = adminRoles.some((role) => val.includes(role));
      },
      deep: true,
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("authStore/AUTH_LOGOUT").then(() => {
        this.$router.push("/login");
      });
    },
    toggleActivationModal() {
      this.toggleMenu = false;
      this.$store.dispatch("userStore/TOGGLE_ACTIVATION_MODAL");
    },
  },
};
</script>

<style>
.menu-panel {
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
}
</style>
