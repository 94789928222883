<template>
  <div>
    <Loader v-if="loading" />
    <div v-else>
      <ScoreDetails
        :game-session="gameSession"
        :avg-passing-percentage="avgPassingPercentage"
        :avg-time-spend="avgTimeSpend"
        :failed="failed"
        :passed="passed"
      />

      <div class="mx-10 pt-24 pb-18 mobile:ml-1 items-center">
        <p class="text-26 text-lightAlineaColor font-bold">
          {{ $t("results.game_results") }}
        </p>
      </div>

      <GameResultRow
        v-for="step in stepScores"
        :key="step.id"
        :title="step.stepName"
        :failed="step.failed"
        :step-actions="step.action_scores"
        :total-score="step.startScore"
        :total-penalty="step.penalty"
        class="mb-10"
      />
    </div>
  </div>
</template>

<script>
import GameResultRow from "@/components/results/GameResultRow";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import ScoreDetails from "@/components/results/ScoreDetails";
import Loader from "@/components/helpers/Loader";

const gameSessionRepository = RepositoryFactory.get("gameSession");

export default {
  name: "GameDetails",
  components: {
    GameResultRow,
    ScoreDetails,
    Loader,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      gameSession: {},
      stepScores: null,
      avgPassingPercentage: "",
      avgTimeSpend: "",
      loading: true,
    };
  },
  computed: {
    failed: function () {
      if (this.stepScores) {
        const stepsFailed = this.stepScores.filter((score) => {
          return score.failed;
        });
        return stepsFailed.length;
      }
      return 0;
    },
    passed: function () {
      if (this.stepScores) {
        const stepsPassed = this.stepScores.filter((score) => {
          return !score.failed;
        });
        return stepsPassed.length;
      }
      return 0;
    },
  },
  mounted() {
    gameSessionRepository
      .getGameSession(this.id)
      .then((result) => {
        this.gameSession = result.data.data;
        this.stepScores = result.data.data.step_scores.data.filter(function (
          stepScore
        ) {
          return stepScore.startScore > 0;
        });
        this.avgPassingPercentage =
          result.data.data.lesson_averages.averagePassingPercentage;
        this.avgTimeSpend = result.data.data.lesson_averages.averageTimeSpent;
      })
      .catch((err) => {
        errorHandlingAndMessage(
          err,
          this.$t("notifications.something_went_wrong_receiving_game_details")
        );
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    isMobile() {
      return screen.width <= 820;
    },
  },
};
</script>
