<template>
  <ModalContainer @cancel="close">
    <div
      class="flex flex-col justify-between w-1/2 bg-gray-50 p-4 rounded-md"
      v-click-outside="close"
    >
      <p
        class="flex self-start text-gray-700 font-normal text-20 tracking-widest pb-4"
      >
        {{ $t("editor.edit_subchapter").toUpperCase() }}
      </p>
      <Form
        class="flex flex-col h-full justify-between"
        v-slot="{ errors }"
        @submit="editChapter"
      >
        <div v-if="newSubChapter" class="flex flex-col">
          <InputField
            :title="$t('editor.name')"
            placeholder="Name"
            v-model="newSubChapter.name"
            required-input
            rules="required|min:4|max:255"
          />
          <InputField
            :title="$t('editor.introduction')"
            placeholder="Intro"
            v-model="newSubChapter.intro"
            required-input
            rules="required|min:4|max:255"
          />
          <InputField
            :title="$t('editor.description')"
            placeholder="Description"
            v-model="newSubChapter.description"
            required-input
            rules="required|min:4|max:255"
          />
          <CheckBox
            :title="$t('editor.active')"
            v-model="newSubChapter.active"
          ></CheckBox>
        </div>
        <div class="flex justify-end">
          <Button
            class="mr-3"
            type="submit"
            :loading="loading"
            button-type="primary"
            :title="$t('editor.save')"
            :invalid="Object.keys(errors).length !== 0"
          />
          <Button
            type="button"
            button-type="tertiary"
            :title="$t('editor.cancel')"
            @clickAction="close"
          />
        </div>
      </Form>
    </div>
  </ModalContainer>
</template>

<script>
import InputField from "@/components/form/InputField";
import Button from "@/components/form/Button";
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import CheckBox from "@/components/form/Checkbox";
import { showModal } from "@/services/modalService";
import { showNotification } from "@/services/notificationService";
import ModalContainer from "@/components/helpers/ModalContainer";

export default {
  name: "EditSubChapter",
  components: { InputField, Button, ModalContainer, CheckBox },
  data() {
    return {
      showMediaModal: false,
      newActive: true,
      newSort: 0,
      loading: false,
      newSubChapter: {},
    };
  },
  computed: {
    ...mapState("trainingStore", [
      "trainingId",
      "versionId",
      "subchapter",
      "chapter",
    ]),
  },
  mounted() {
    this.newSubChapter = { ...this.subchapter };
  },
  methods: {
    editChapter() {
      this.loading = true;
      const subChapter = {
        name: this.newSubChapter.name,
        introduction: this.newSubChapter.intro,
        description: this.newSubChapter.description,
        active: this.newSubChapter.active,
        sort: this.newSubChapter.sort,
        parent: this.chapter.id,
      };
      trainingRepository
        .editChapter(
          this.trainingId,
          this.versionId,
          this.subchapter.id,
          subChapter
        )
        .then(() => {
          this.loading = false;
          showNotification(
            this.$t("notifications.chapter_edited"),
            this.$t("notifications.chapter_is_successfully_edited"),
            "success"
          );
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
          this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "none");
        });
    },
    close() {
      if (this.dataChanged) {
        showModal(
          this.$t("warning.there_is_data_changed"),
          this.$t("warning.are_you_sure_you_want_to_cancel"),
          "warning",
          true
        );
      } else {
        this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "none");
      }
    },
  },
};
</script>

<style></style>
