<template>
  <PageContentContainer>
    <div>
      <transition-group
        name="fade"
        class="flex desktop:flex-row mobile:flex-col flex-wrap justify-start"
        tag="span"
      >
        <Card
          class="mr-5 mb-5"
          v-for="game in trainings"
          :key="game.id"
          :game="game"
          :data-cy="game.title"
          @setId="setId"
        />
        <div
          v-if="adminRole"
          key="addGame"
          class="w-gameCardWidth h-gameCardHeight object-contain shadow-darkBg bg-primaryColor rounded-15 flex flex-col bg-cover bg-no-repeat bg-center hover:shadow-none cursor-pointer"
          :style="{
            'background-image':
              'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.30))',
          }"
          @click="showModal('create', true)"
        >
          <div class="flex-1 flex flex-col justify-end">
            <div class="flex h-10 w-5/6 bg-primaryColor mb-12">
              <div class="flex flex-7 flex-row ml-5 items-center">
                <font-awesome-icon
                  icon="plus"
                  class="fa-sm"
                  style="color: white"
                  data-cy="createGame"
                />
                <p class="ml-5 font-bold text-18 text-white uppercase">
                  {{ $t("game.add_game").toUpperCase() }}
                </p>
              </div>
              <div class="flex flex-1 skew-20 -mr-3 bg-primaryColor"></div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <DeleteModal
      v-if="showDeleteTraining"
      :title="`${$t('game.are_you_sure_you_want_to_delete_this_game')}?`"
      :body="`${$t('game.this_game_will_be_deleted_permanently')}`"
      @cancel="hideModal('delete', false)"
    />
    <CreateGameModal
      v-if="showCreateTraining"
      @cancel="hideModal('create', false)"
    />
    <EditGameModal v-if="showEditTraining" @cancel="hideModal('edit', false)" />
    <div v-if="showImportModal">
      <MediaModal
        media-modal-type="translation"
        :required="true"
        @toggleMediaModal="closeTranslationModal('import')"
        @finishUpload="finishUpload"
      />
    </div>
    <div v-if="showTranslationFilesModal">
      <FilesModal
        media-modal-type="recent-translation-files"
        :title="$t('media.upload_translation_file')"
        :required="true"
        @toggleMediaModal="closeTranslationModal('files')"
      />
    </div>
    <div v-if="showSelectLocaleModal">
      <SelectLocaleModal
        :file-name="fileName"
        @cancel="showSelectLocaleModal = false"
        @confirm="finishTranslationImport"
      />
    </div>
    <div v-if="showExportModal">
      <ExportModal
        :header-text="$t('editor.export_translations')"
        target="translation"
        :sub-header-text="$t('editor.export_translations_as_csv')"
        @close="closeTranslationModal('export')"
      />
    </div>
    <StartGameInfoModal
      v-if="showStartGameInfoModal"
      :content="startGameInfoModal"
    />
  </PageContentContainer>
</template>

<script>
import DeleteModal from "@/components/helpers/modals/DeleteModal";
import CreateGameModal from "@/components/helpers/modals/gameModals/CreateGameModal";
import EditGameModal from "@/components/helpers/modals/gameModals/EditGameModal";
import { mapState } from "vuex";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import Card from "@/components/helpers/gameCard/Card";
import { adminRoles } from "@/config";
import ExportModal from "@/views/editor/components/modals/ExportModal";
import uploadTranslationHelper from "@/mixins/uploadTranslationHelper";
import StartGameInfoModal from "@/components/helpers/modals/StartGameInfoModal";
import FilesModal from "@/views/editor/components/modals/filesModal/FilesModal";

export default {
  components: {
    FilesModal,
    PageContentContainer,
    Card,
    CreateGameModal,
    EditGameModal,
    DeleteModal,
    ExportModal,
    StartGameInfoModal,
  },
  mixins: [uploadTranslationHelper],
  data: () => {
    return {
      selectedId: "",
    };
  },
  computed: {
    ...mapState("trainingStore", [
      "trainings",
      "showEditTraining",
      "showCreateTraining",
      "showDeleteTraining",
    ]),
    ...mapState("userStore", ["currentUser"]),
    ...mapState("modalStore", ["showStartGameInfoModal", "startGameInfoModal"]),
    adminRole: function () {
      return adminRoles.some((role) => this.currentUser.roles.includes(role));
    },
  },
  methods: {
    hideModal(type, val) {
      this.$store.dispatch("trainingStore/HIDE_MODAL", {
        val: val,
        type: type,
      });
    },
    showModal(type, val) {
      this.$store.dispatch("trainingStore/SHOW_MODAL", {
        val: val,
        type: type,
      });
    },
    setId(id) {
      this.selectedId = id;
    },
  },
};
</script>

<style></style>
