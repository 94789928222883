<template>
  <div
    id="container"
    class="flex justify-center items-center h-screen w-screen lg:py-loginPaddingTopBottom lg:px-loginPaddingLeftRight bg-loginBackgroundColor"
  >
    <div
      id="card"
      class="flex flex-row mobile:h-full w-full max-w-800 h-550 bg-white shadow-lightBg"
    >
      <!-- left side of card -->
      <LeftContainer />
      <!-- right side of card -->
      <div
        id="right"
        class="flex flex-1 flex-col bg-standardBgColor"
        @submit.prevent
        @keypress.enter.prevent="activateUser"
      >
        <div class="flex flex-2 flex-row w-full justify-center items-center">
          <p class="text-title text-darkAlineaColor mobile:hidden">
            {{ $t("auth.account_activation").toUpperCase() }}
          </p>
          <p
            class="text-title text-darkAlineaColor mobile:items-center mobile:justify-center mobile:font-bold mobile:-mt-6 desktop:items-end desktop:mb-inputPadding desktop:ml-inputPadding desktop:hidden"
          >
            {{ $t("auth.virtualstudio") }}
          </p>
        </div>

        <div class="flex flex-4 justify-center items-center desktop:hidden">
          <img
            class="object-contain h-100p"
            src="../../assets/theme/logo.png"
            :alt="$t('auth.virtualstudio')"
          />
        </div>

        <div
          id="form-container"
          class="flex flex-9 mobile:flex-3 justify-center items-center flex-col px-inputPaddingLeftRight"
        >
          <p v-if="response" class="text-17 text-darkAlineaColor text-center">
            {{
              response === "succeed"
                ? $t("auth.activation_succes_message")
                : $t("auth.activation_error_message")
            }}
          </p>
          <Button
            v-if="response === 'succeed'"
            class="mb-2 mt-60"
            :title="$t('auth.login')"
            button-type="primary"
            :loading="loading"
            @clickAction="toLogin"
          />
          <Button
            v-if="response === 'failed'"
            class="mb-inputPadding mt-60"
            :title="$t('auth.register')"
            button-type="primary"
            :loading="loading"
            @clickAction="toRegister"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftContainer from "@/components/authentication/LeftContainer";
import Button from "@/components/form/Button";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const authRepository = RepositoryFactory.get("auth");

export default {
  name: "ActivationCode",
  components: { LeftContainer, Button },
  props: {
    token: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    response: false,
    loading: false,
  }),
  mounted() {
    this.activateUser();
  },
  methods: {
    toRegister() {
      this.loading = true;
      this.$router.push({ name: "register" });
    },
    toLogin() {
      this.loading = true;
      this.$router.push({ name: "login" });
    },
    activateUser() {
      authRepository
        .confirmActivationToken(this.token)
        .then((result) => {
          this.resetPassword(result.data.data.email);
        })
        .catch((err) => {
          this.response = "failed";
        });
    },

    resetPassword(email) {
      authRepository
        .resetPassword(email)
        .then(() => {
          this.response = "succeed";
        })
        .catch((err) => {
          this.response = "failed";
        });
    },
  },
};
</script>

<style scoped></style>
