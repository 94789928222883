<template>
  <div
    class="flex flex-1 bg-modalBackground absolute top-0 left-0 h-full justify-center items-center w-full"
  >
    <div
      class="bg-white flex flex-col overflow-hidden shadow rounded-lg w-3/5 z-50"
      v-click-outside="close"
    >
      <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
        <div
          class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("editor.action_selector") }}
            </h3>
          </div>
          <div class="ml-4 mt-2 flex-shrink-0">
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                class="form-input block w-full text-gray-700 border-gray-300 pr-10 sm:text-sm sm:leading-5"
                placeholder="search"
                v-model="search"
                ref="searchBox"
              />
              <div
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <font-awesome-icon
                  icon="search"
                  class="text-gray-700 text-16 absolute right-0 mr-3 flex items-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        class="flex flex-row px-3 py-4"
        style="height: 500px"
      >
        <div class="flex flex-1">
          <nav class="w-full border-r border-gray-200 pr-5">
            <a
              v-for="category in categories"
              :key="category.name"
              href="#"
              class="group flex items-center justify-between px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
              @click="activeCategory = category.name"
            >
              <span class="truncate">
                {{ category.name }}
              </span>
              <div
                class="h-6 w-6 bg-blue-600 flex justify-center items-center rounded-full"
              >
                <font-awesome-icon
                  :icon="category.icon"
                  class="text-blue-100 text-12"
                />
              </div>
            </a>
            <a
              href="#"
              class="group flex items-center justify-between px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
              @click="activeCategory = 'all'"
            >
              <span class="truncate"> All </span>
              <div
                class="h-6 w-6 bg-blue-600 flex justify-center items-center rounded-full"
              >
                <font-awesome-icon icon="list" class="text-blue-100 text-12" />
              </div>
            </a>
          </nav>
        </div>
        <div class="flex flex-3 pl-3 scrollable">
          <ul class="w-full">
            <li v-for="action in actions" :key="action.id">
              <a
                href="#"
                class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
              >
                <div
                  class="flex items-center px-4 py-4 sm:px-6"
                  @click="setActiveAction(action)"
                >
                  <div class="min-w-0 flex-1 flex items-center">
                    <div class="flex-shrink-0">
                      <div
                        class="h-8 w-8 bg-blue-600 flex justify-center items-center rounded-full"
                      >
                        <font-awesome-icon
                          :icon="
                            action.unity_package_action_type.data.icon.substring(
                              action.unity_package_action_type.data.icon.indexOf(
                                'fas'
                              ) >= 0
                                ? 7
                                : 6
                            )
                          "
                          class="text-blue-100 text-16"
                        />
                      </div>
                    </div>
                    <div class="min-w-0 flex-1 px-4">
                      <div>
                        <div
                          class="text-sm leading-5 font-medium text-indigo-600 truncate"
                        >
                          {{ action.name }}
                        </div>
                        <div
                          class="flex items-center text-sm leading-5 text-gray-500"
                        >
                          <span class="truncate">
                            {{ action.description }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <svg
                      class="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-else
        class="flex flex-row px-3 py-4 justify-center items-center"
        style="height: 500px"
      >
        <Loader />
      </div>
      <div class="border-t border-gray-200 px-4 py-4 sm:px-6">
        <div class="flex-shrink-0 flex justify-end">
          <Button
            @clickAction="$emit('close')"
            :title="$t('editor.cancel')"
            type="button"
            button-type="secondary"
          >
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const actionRepository = RepositoryFactory.get("action");
import Button from "@/components/form/Button";
import { mapState } from "vuex";
import Loader from "@/views/editor/components/Loader";

export default {
  name: "ActionSelectModal",
  components: {
    Button,
    Loader,
  },
  props: {
    stepId: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      search: "",
      activeCategory: "all",
      loading: true,
      categories: [],
      actionList: [],
    };
  },
  computed: {
    ...mapState("trainingStore", [
      "trainingId",
      "versionId",
      "level",
      "subchapter",
    ]),
    actions: function (val) {
      if (!this.search && !this.activeCategory) {
        return this.actionList;
      }
      return this.actionList.filter((action) => {
        if (this.activeCategory === "all") {
          return (
            action.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
          );
        } else {
          return (
            action.unity_package_action_type.data.name
              .toLowerCase()
              .indexOf(this.activeCategory.toLowerCase()) !== -1
          );
        }
      });
    },
  },
  watch: {
    actions: {
      handler(val) {
        if (this.activeCategory === "all") this.getCategories(val);
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.activeCategory = "all";
      },
      deep: true,
    },
  },
  mounted() {
    this.focusSearch();
    actionRepository
      .getAvailableActions(this.trainingId, this.versionId)
      .then((result) => {
        this.actionList = result.data.data;
        this.loading = false;
      })
      .catch((err) => {});
  },
  methods: {
    close() {
      this.$emit("close");
    },
    focusSearch() {
      this.$refs.searchBox.focus();
    },
    setActiveAction(action) {
      this.$store.dispatch("editorStore/SET_ACTIVE_ACTION", {
        id: action.id,
        type: "create",
        activeType: "action",
        stepId: this.stepId,
      });
      this.close();
    },
    getCategories(actions) {
      const categoryList = [];
      this.categories = [];
      actions.forEach((element) => {
        if (
          categoryList.includes(element.unity_package_action_type.data.name)
        ) {
        } else {
          categoryList.push(element.unity_package_action_type.data.name);
          this.categories.push({
            name: element.unity_package_action_type.data.name,
            icon: element.unity_package_action_type.data.icon.substring(
              element.unity_package_action_type.data.icon.indexOf("fas") >= 0
                ? 7
                : 6
            ),
          });
        }
      });
    },
  },
};
</script>

<style>
.scrollable {
  width: 100%;
  min-height: 100px;
  max-height: 600px;
  max-width: 900px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
