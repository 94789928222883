<template>
  <div class="w-full">
    <div v-if="!activeAction" class="flex items-center justify-center mt-2/3">
      <Loader />
    </div>
    <div v-else>
      <Form
        v-if="action"
        class="mt-3 mr-2"
        @keypress.enter.stop="preventSubmitOnEnter ? null : creatOrEditAction"
        @submit="creatOrEditAction"
        v-slot="{ errors }"
      >
        <p
          class="text-white text-18 font-bold tracking-widest mt-2 mb-4 pl-4 pr-4"
          style="word-wrap: break-word"
        >
          {{ action.name }}
        </p>
        <div class="pb-5 bg-gray-700 pl-4 pr-4 pt-1">
          <TextArea
            :title="$t('editor.name')"
            v-model="action.value"
            active-color="#D1D5DB"
            type=""
            is-required
            rules="required|min:5|max:255"
            @preventSubmit="preventSubmit"
          />
        </div>
        <div class="pl-4 pr-4 my-2">
          <boolean
            :title="$t('editor.enabled')"
            :hidden="false"
            :required="true"
            v-model="action.enabled"
            @input="updateAction"
          />
        </div>
        <div
          v-for="(property, index) in action_properties"
          :key="
            property.action_property_id
              ? property.action_property_id
              : property.unity_package_action_property_id
          "
        >
          <div :class="backgroundColor(index)">
            <div
              class="pl-4 pr-4 pt-1"
              :class="{
                'border-red-600 border-2 m-1':
                  property.error_state !== 0 && isPropertyVisible(property),
              }"
            >
              <component
                v-if="isPropertyVisible(property)"
                :is="
                  checkValue(
                    property.value_type,
                    property.media_type ? property.media_type : null
                  )
                "
                :title="property.name"
                :options="property.options"
                :hint="property.description"
                :required-input="property.required"
                :rules="validationRules(property)"
                :default-value="property.default_value"
                :constraint-type="property.constraint_type"
                :constraint-properties="property.constraint_properties"
                :unity-package-action-property-id="
                  property.unity_package_action_property_id
                "
                :voice-relation="property.voice_relation"
                :sibling-data="siblingData"
                :default-tts-voice="defaultTtsVoice"
                :media="property.media"
                :hidden="property.hidden"
                :error-state="property.error_state"
                :is-text-area="property.is_text_area"
                :error-message="setErrorMessage(property.error_state)"
                active-color="#D1D5DB"
                v-model="property.value"
                @update="setSiblingData"
                @addLoading="addLoading"
                @removeLoading="removeLoading"
              >
              </component>
            </div>
          </div>
        </div>
        <div class="flex flex-row w-full justify-between p-5">
          <div>
            <Button
              :title="buttonStatus"
              type="submit"
              button-type="editor-secondary"
              :invalid="Object.keys(errors).length !== 0 || saveButtonLoading"
            />
            <Button
              :title="$t('button.cancel')"
              button-type="editor-primary"
              class="ml-3"
              @click.native="close()"
            />
          </div>
          <Button
            v-if="activeAction.type !== 'create'"
            icon="trash"
            size="m"
            hover="red"
            button-type="trashcan"
            class="ml-3"
            @click.native="showDeleteModal = true"
          />
        </div>
        <AcceptModal
          v-if="showDeleteModal"
          :header-text="$t('editor.delete_action')"
          :sub-header-text="
            $t('editor.are_you_sure_you_want_to_delete_this_action')
          "
          :accept-button-text="$t('general.accept')"
          :cancel-button-text="$t('general.cancel')"
          @accept="deleteAction"
          @cancel="showDeleteModal = false"
        />
      </Form>
    </div>
  </div>
</template>

<script>
import boolean from "@/views/editor/components/ActionProperties/Boolean";
import string from "@/views/editor/components/ActionProperties/String";
import float from "@/views/editor/components/ActionProperties/Float";
import int from "@/views/editor/components/ActionProperties/Int";
import choice from "@/views/editor/components/ActionProperties/Choice";
import color from "@/views/editor/components/ActionProperties/Color";
import answerArray from "@/views/editor/components/ActionProperties/AnswerArray";
import fileInput from "@/views/editor/components/ActionProperties/File";
import imageInput from "@/views/editor/components/ActionProperties/Image";
import audioInput from "@/views/editor/components/ActionProperties/Audio";
import videoInput from "@/views/editor/components/ActionProperties/Video";
import stringArray from "@/views/editor/components/ActionProperties/StringArray";
import tts from "@/views/editor/components/ActionProperties/Tts";
import InputField from "@/views/editor/components/inputs/InputField";
import Button from "@/components/form/Button";
import AcceptModal from "@/components/helpers/modals/AcceptModal";
import { mapState } from "vuex";
import Loader from "@/views/editor/components/Loader";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { showNotification } from "@/services/notificationService";
import TextArea from "@/views/editor/components/inputs/TextArea";
const actionRepository = RepositoryFactory.get("action");

export default {
  name: "ActionProperties",
  components: {
    TextArea,
    imageInput,
    boolean,
    string,
    int,
    float,
    choice,
    audioInput,
    videoInput,
    Button,
    InputField,
    fileInput,
    color,
    answerArray,
    stringArray,
    tts,
    Loader,
    AcceptModal,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      properties: [],
      unity_package_action_id: null,
      action: null,
      mediaTypes: ["image", "file", "video", "audio"],
      action_properties: null,
      loadingComponents: [],
      siblingData: {},
      defaultTtsVoice: "",
      showDeleteModal: false,
      previousActionPropertyColor: "bg-gray-700",
      nextActionPropertyColor: "",
      preventSubmitOnEnter: false,
    };
  },
  computed: {
    ...mapState("editorStore", ["activeAction", "activeLesson", "activeStep"]),
    saveButtonLoading: function () {
      return this.loadingComponents.length !== 0;
    },
    buttonStatus: function () {
      if (this.activeAction.type === "edit" && !this.saveButtonLoading) {
        return this.$t("button.update");
      }
      if (this.activeAction.type === "create" && !this.saveButtonLoading) {
        return this.$t("button.save");
      }
      if (this.activeAction.type === "edit" && this.saveButtonLoading) {
        return this.$t("button.updating");
      }
      if (this.activeAction.type === "create" && this.saveButtonLoading) {
        return this.$t("button.saving");
      } else {
        return this.$t("button.save");
      }
    },
  },
  watch: {
    "activeAction.id": {
      handler(newVal, oldVal) {
        if (this.activeAction.type === "create") {
          this.createNewActionTemplate(
            this.activeLesson.id,
            this.activeAction.stepId,
            newVal
          );
        } else if (this.activeAction.type === "edit") {
          if (this.activeAction) {
            this.action = this.activeAction.action;
            this.action_properties =
              this.activeAction.action.action_properties.data;
            this.unity_package_action_id =
              this.activeAction.action.unity_package_action_id;
            this.setDefaultTtsVoice();
          }
        }
      },
      deep: false,
    },
    "activeAction.action": {
      handler(val) {
        this.action_properties = val.action_properties.data;
        this.action = val;
      },
      deep: false,
    },
  },
  mounted() {
    if (this.activeAction.type) {
      if (this.activeAction.type === "create") {
        this.createNewActionTemplate(
          this.activeLesson.id,
          this.activeAction.stepId,
          this.activeAction.id
        );
      } else if (this.activeAction.type === "edit") {
        this.action = this.activeAction.action;
        this.action_properties =
          this.activeAction.action.action_properties.data;
        this.unity_package_action_id =
          this.activeAction.action.unity_package_action_id;
        this.setDefaultTtsVoice();
      }
    }
  },
  methods: {
    validationRules(property) {
      let rules = {};

      if (property.value_type === "int") {
        rules.integer = true;
      }

      if (property.constraint_type === "GreaterThanOrEqual") {
        rules.min_value = property.constraint_properties.value;
      }

      if (property.constraint_type === "Range") {
        rules.min_value = property.constraint_properties.min;
        rules.max_value = property.constraint_properties.max;
      }

      if (property.constraint_type === "Length") {
        rules.min = property.constraint_properties.min;
        rules.max = property.constraint_properties.max;
      }

      if (property.required) {
        rules.required = true;
      }

      return rules;
    },

    isPropertyVisible(property) {
      return !(
        property.value_type === "Mode" ||
        property.name === "Id" ||
        property.hidden
      );
    },

    checkValue(property, media_type) {
      if (media_type) {
        if (this.mediaTypes.includes(media_type)) {
          return media_type + "Input";
        }
      } else {
        if (property === "Answer[]") {
          // TODO Change the type naming in the backend so this if can be removed
          return "answerArray";
        }
        if (property === "String[]") {
          // TODO Change the type naming in the backend so this if can be removed
          return "stringArray";
        } else {
          return property;
        }
      }
    },

    createNewActionTemplate(lessonId, stepId, actionId) {
      this.loading = true;
      actionRepository
        .newActionTemplate(lessonId, stepId, actionId)
        .then((result) => {
          this.action = result.data.data;
          this.action_properties = result.data.data.action_properties.data;
          this.unity_package_action_id =
            result.data.data.unity_package_action_id;
          this.setDefaultTtsVoice();
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async creatOrEditAction() {
      if (this.activeAction.type === "edit") {
        // const isValid = await this.$refs.observer.validate();
        //if (isValid) {
        this.addLoading(this.activeAction.id);

        const editActionProperty = this.action_properties.map((property) => {
          return {
            id: property.action_property_id,
            value: property.value,
          };
        });
        const editAction = {
          ...this.action,
          action_properties: editActionProperty,
        };
        editAction.name = editAction.value;
        delete editAction.action_id;
        delete editAction.error_state;
        delete editAction.type;
        delete editAction.value;
        actionRepository
          .updateActionProperties(
            this.activeLesson.id,
            this.activeAction.stepId,
            this.activeAction.id,
            editAction
          )
          .then((result) => {
            this.action = result.data.data;
            this.action_properties = this.action.action_properties.data;

            showNotification(
              this.$t("notifications.actions_edited"),
              this.$t("notifications.actions_was_successfully_edited"),
              "success"
            );
          })
          .catch((err) => {
            if (err.response.status === 422) {
              if (typeof err.response.data === "object") {
                err.response.data.violations.data.forEach((violation) => {
                  showNotification(
                    this.$t("notifications.add_action"),
                    violation.message,
                    "error"
                  );
                });
              } else if (typeof err.response.data === "string") {
                showNotification(
                  this.$t("notifications.add_action"),
                  err.response.data,
                  "error"
                );
              }
            } else {
              showNotification(
                this.$t("notifications.edit_action"),
                this.$t(
                  "notifications.something_went_wrong_while_editing_this_action"
                ),
                "error"
              );
            }
          })
          .finally(() => {
            this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
            this.removeLoading(this.activeAction.id);
          });
        // }
      } else {
        // const isValid = await this.$refs.observer.validate();
        // if (isValid) {
        const activeAction = this.activeAction.id;
        this.addLoading(activeAction);
        await this.change("Modes");
        await this.change("Id");
        this.action.unity_package_action_id = this.unity_package_action_id;
        this.action.sort = 1000;
        this.action.enabled =
          this.action.enabled === "" ? true : this.action.enabled;
        const newActionProperty = this.action.action_properties.data.map(
          (property) => {
            return {
              unity_package_action_property_id:
                property.unity_package_action_property_id,
              value: property.value,
            };
          }
        );
        const newAction = {
          ...this.action,
          action_properties: newActionProperty,
        };
        newAction.name = newAction.value;

        delete newAction.action_id;
        delete newAction.error_state;
        delete newAction.type;
        delete newAction.value;
        actionRepository
          .addActionToStep(
            this.activeLesson.id,
            this.activeAction.stepId,
            newAction
          )
          .then((result) => {
            this.action = result.data.data;

            this.$store.dispatch("editorStore/SET_ACTIVE_ACTION", {
              id: this.action.action_id,
              type: "edit",
              activeType: "action",
              stepId: this.action.step_id,
              action: this.action,
            });

            showNotification(
              this.$t("notifications.actions_added"),
              this.$t("notifications.actions_was_successfully_added"),
              "success"
            );
          })
          .catch((err) => {
            if (err.response.status === 422) {
              if (typeof err.response.data === "object") {
                err.response.data.violations.data.forEach((violation) => {
                  showNotification(
                    this.$t("notifications.add_action"),
                    violation.message,
                    "error"
                  );
                });
              } else if (typeof err.response.data === "string") {
                showNotification(
                  this.$t("notifications.add_action"),
                  err.response.data,
                  "error"
                );
              }
            } else {
              showNotification(
                this.$t("notifications.add_action"),
                this.$t(
                  "notifications.something_went_wrong_while_adding_this_action"
                ),
                "error"
              );
            }
          })
          .finally(() => {
            this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
            this.removeLoading(activeAction);
          });
        // }
      }
    },
    change(value) {
      return new Promise((resolve) => {
        this.action.action_properties.data.forEach((i) => {
          if (i.name === value) {
            i.value = "empty";
            resolve();
          }
        });
      });
    },
    close() {
      this.$store.dispatch("editorStore/SET_ACTIVE_ACTION", {
        id: "",
        type: "",
        activeType: "",
        stepId: "",
      });
    },
    addLoading(unityPackageActionPropertyId) {
      this.loadingComponents.push(unityPackageActionPropertyId);
    },
    removeLoading(unityPackageActionPropertyId) {
      this.loadingComponents = this.loadingComponents.filter(
        (loadingComponent) => loadingComponent !== unityPackageActionPropertyId
      );
    },
    setSiblingData(data) {
      this.siblingData = data;
    },
    setDefaultTtsVoice() {
      const actionPropertyRelation = this.action.action_properties.data.filter(
        (actionProperty) => actionProperty.value_type === "tts"
      );
      const actionPropertyChoice = actionPropertyRelation.length
        ? this.action.action_properties.data.filter(
            (actionProperty) =>
              actionProperty.name === actionPropertyRelation[0].voice_relation
          )
        : null;
      this.defaultTtsVoice = actionPropertyChoice
        ? actionPropertyChoice[0].default_value
        : "";
    },
    deleteAction() {
      this.$store
        .dispatch("editorStore/DELETE_ACTION", {
          lessonId: this.activeLesson.id,
          actionStepId: this.activeAction.stepId,
          actionId: this.activeAction.id,
        })
        .then(() => (this.showDeleteModal = false));
    },
    backgroundColor(index) {
      const blue = "bg-blue";
      const gray = "bg-gray-700";
      if (
        this.action_properties[index]?.property_group ===
        this.action_properties[index - 1]?.property_group
      ) {
        return this.previousActionPropertyColor;
      } else {
        this.nextActionPropertyColor =
          this.previousActionPropertyColor === gray ? blue : gray;

        return this.nextActionPropertyColor;
      }
    },
    setErrorMessage(errorState) {
      if (errorState === 1) {
        return this.$t(
          "editor.type_error_the_value_of_this_action_property_does_not_have_the_correct_type"
        );
      } else if (errorState === 2) {
        return this.$t(
          "editor.constraint_error_the_value_of_this_action_property_does_not_meet_the_constraint"
        );
      } else return "";
    },
    updateAction() {
      this.$store.dispatch("editorStore/SET_ACTION", this.action);
    },
    preventSubmit(boolean) {
      this.preventSubmitOnEnter = boolean;
    },
  },
};
</script>

<style></style>
