<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <ListHeader
        :buttons="buttonSettings"
        @buttonAction="createDealer"
        @searchAction="searchAction"
      />
      <div class="backgroundRow shadow overflow-hidden sm:rounded-md">
        <div class="flex flex-col">
          <div
            class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="align-middle inline-block min-w-full overflow-hiddensm:rounded-lg"
            >
              <div
                v-if="loading"
                class="py-10 flex items-center justify-center"
              >
                <Loader />
              </div>
              <table v-else class="min-w-full" data-cy="dealersTable">
                <thead class="backgroundRow">
                  <tr>
                    <th
                      v-for="(header, index) in columnHeaders"
                      :key="`columnHeaders-${index}`"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      :class="{
                        'text-gray-100': header === 'name',
                        'text-gray-400': header !== 'name',
                        'cursor-pointer': isSortable(header),
                      }"
                      @click="isSortable(header) ? sort(header) : ''"
                    >
                      <div
                        class="flex flex-row justify-between align-center"
                        :class="{
                          'hover:text-white': isSortable(header),
                        }"
                      >
                        <p>{{ $t(`dealers.${header}`) }}</p>
                        <font-awesome-layers class="ml-1 mr-1">
                          <font-awesome-icon
                            v-if="header === currentSort"
                            icon="sort-up"
                            class="fa-lg"
                            :class="
                              currentSortDir === 'DESC'
                                ? 'text-white'
                                : 'text-gray-400'
                            "
                          />
                          <font-awesome-icon
                            v-if="header === currentSort"
                            icon="sort-down"
                            class="fa-lg"
                            :class="
                              currentSortDir === 'ASC'
                                ? 'text-white'
                                : 'text-gray-400'
                            "
                          />
                        </font-awesome-layers>
                      </div>
                    </th>
                    <th class="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRow
                    v-for="dealer in dealers"
                    :key="dealer.id"
                    :id="dealer.id"
                    :name="dealer.name"
                    :client-id="dealer.client_id"
                    :owner="dealer.owner"
                    :startdate="formatDate(dealer.start_date)"
                    :stopdate="formatDate(dealer.stop_date)"
                    @edit="editDealer(dealer.id)"
                    @delete="deleteDealer(dealer.id)"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        class="justify-self-end"
        :total-pages="totalPages"
        :total-amount="totalEntries"
        :page-amount="dealers.length"
        :current-page="currentPage"
        :max-result="params.maxResult"
        @next="nextPage"
        @previous="previousPage"
        @toPage="toPage"
        @changeAmountToShow="changeAmountToShow"
      />
    </div>
  </PageContentContainer>
</template>

<script>
import { format } from "date-fns";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import TableRow from "./components/TableRowDealers";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { showNotification } from "@/services/notificationService";
const dealerRepository = RepositoryFactory.get("dealer");
import Loader from "@/components/helpers/Loader";
import ListHeader from "@/components/helpers/ListHeader";
import { mapState } from "vuex";
import paginationHelper from "@/mixins/paginationHelper";
import Pagination from "@/components/helpers/Pagination";

export default {
  name: "DealersOverview",
  components: {
    PageContentContainer,
    TableRow,
    Loader,
    ListHeader,
    Pagination,
  },
  mixins: [paginationHelper],
  data: () => {
    return {
      dealers: [],
      loading: true,
      columnHeaders: [
        "name",
        "client_id",
        "enabled",
        "owner",
        "start_date",
        "stop_date",
      ],
      sortableColumnHeaders: ["name", "enabled", "start_date", "stop_date"],
      params: {
        page: "1",
        sort: "ASC",
        sortfield: "name",
        maxResult: localStorage.getItem("maxresult-dealers") || "7",
        query: "",
      },
      buttonSettings: [
        {
          title: "dealers.add_dealer",
          action: "buttonAction",
        },
      ],
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  created() {
    this.getDealers();
  },
  methods: {
    getEntries() {
      this.getDealers();
    },
    formatDate(date) {
      return format(
        new Date(date),
        this.$i18n.locale === "en" ? "dd MMM yyyy" : "dd-MM-yyyy"
      );
    },

    editDealer(id) {
      this.$router.push({
        name: "dealersEdit",
        params: { id },
      });
    },
    deleteDealer(id) {
      dealerRepository.deleteDealer(id).then(() => {
        showNotification(
          this.$t("notifications.dealer_deleted"),
          this.$t("notifications.dealer_is_successfully_deleted"),
          "success"
        );
        this.getDealers();
      });
    },
    getDealers() {
      const { page, sort, sortfield, maxResult, query } = this.params;
      const urlParams = `?page=${page}&max_results=${maxResult}&sort_direction=${sort}&sort_by_field=${sortfield}&search_query=${query}`;
      dealerRepository
        .getDealers(urlParams)
        .then((result) => {
          this.dealers = result.data.data;
          const { total, total_pages } = result.data.meta.pagination;
          this.totalEntries = total;
          this.totalPages = total_pages;
          this.loading = false;
        })
        .catch((err) => {});
    },
    createDealer() {
      this.$router.push({
        name: "dealersCreate",
      });
    },
    setMaxResults(val) {
      localStorage.setItem("maxresult-dealers", val);
    },
    isSortable(header) {
      return this.sortableColumnHeaders.includes(header);
    },
  },
};
</script>

<style></style>
