<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <div class="backgroundRow shadow overflow-hidden sm:rounded-md">
        <div class="flex flex-col">
          <div
            class="-mt-2 mb-8 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg"
            >
              <div
                v-if="loading"
                class="py-10 flex items-center justify-center"
              >
                <Loader />
              </div>
              <table v-else class="min-w-full" data-cy="dealersTable">
                <thead class="backgroundRow">
                  <tr>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("versions.version") }}
                    </th>
                    <th
                      v-for="platform in platforms"
                      :key="platform.id"
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-200 uppercase tracking-wider"
                    >
                      {{ platform.name }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-200 uppercase tracking-wider"
                    >
                      {{ $t("versions.status") }}
                    </th>
                    <th class="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  <VersionRow
                    v-for="version in versions"
                    :key="version.id"
                    :id="version.id"
                    :platforms="platforms"
                    :version="version.version_number"
                    :applications="version.applications.data"
                    :published="version.published"
                    :synced="version.synced"
                    :training-id="id"
                    @getVersions="getVersions"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageContentContainer>
</template>

<script>
import PageContentContainer from "@/components/helpers/PageContentContainer";
import Loader from "@/components/helpers/Loader";
import VersionRow from "@/components/versions/VersionRow";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { mapState } from "vuex";
const trainingRepository = RepositoryFactory.get("training");

export default {
  name: "Versions",
  components: { PageContentContainer, Loader, VersionRow },
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      platforms: [],
    };
  },
  computed: {
    ...mapState("trainingStore", ["versions", "trainings"]),
  },
  mounted() {
    this.getPlatforms();
    this.getVersions();
  },
  methods: {
    getPlatforms() {
      trainingRepository
        .getPlatforms()
        .then((result) => {
          this.platforms = result.data.data;
        })
        .catch((err) => {});
    },

    getVersions() {
      this.$store.dispatch("trainingStore/GET_VERSIONS", this.id).then(() => {
        this.$store.dispatch("trainingStore/REFRESH_TRAINING");
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
