<template>
  <div class="w-full">
    <div v-if="loading" class="flex items-center justify-center mt-2/3">
      <Loader />
    </div>
    <div v-else class="w-full">
      <Form
        class="flex flex-col h-full w-full justify-between mt-3 p-4"
        @keypress.enter.stop="preventSubmitOnEnter ? null : saveStep"
        @submit="saveStep"
        v-slot="{ errors }"
      >
        <div class="mt-3">
          <TextArea
            :title="$t('editor.name')"
            :hint="$t('editor.enter_a_name_for_this_step')"
            active-color="#D1D5DB"
            v-model="step.name"
            rules="min:1|max:150"
            type=""
            is-required
            @preventSubmit="preventSubmit"
          />
        </div>
        <div class="mt-3">
          <InputField
            :title="$t('editor.repeatable')"
            :hint="$t('editor.number_of_possible_repeats')"
            active-color="#D1D5DB"
            type="number"
            min="0"
            max="255"
            rules="required|min_value:0|max_value:255"
            required-input
            v-model="step.repeatable"
          />
        </div>
        <div class="mt-3">
          <InputField
            :title="$t('editor.non_linear_random')"
            :hint="$t('editor.non_linear_random_number')"
            active-color="#D1D5DB"
            type="number"
            min="0"
            max="255"
            rules="required|min_value:0|max_value:255"
            required-input
            v-model="step.non_linear_randomization"
          />
        </div>
        <div class="mt-3">
          <InputField
            :title="$t('editor.max_score')"
            :hint="$t('editor.maximum_score')"
            active-color="#D1D5DB"
            type="number"
            v-model="step.max_score"
            rules="min_0_or_null"
          />
        </div>
        <div class="mt-3">
          <TextArea
            :title="$t('editor.remark')"
            active-color="#D1D5DB"
            v-model="step.remark"
            rules="max:255"
            type=""
            @preventSubmit="preventSubmit"
          ></TextArea>
        </div>
        <CheckBox
          :title="$t('editor.required')"
          v-model="step.is_required"
          :hint="$t('editor.required_this_is_a_required_step_to_execute')"
        ></CheckBox>
        <CheckBox
          :title="$t('editor.opportunity')"
          v-model="step.is_opportunity"
          :hint="
            $t(
              'editor.opportunity_the_player_will_be_rewarded_with_points_when_finishing_this_step'
            )
          "
        ></CheckBox>
        <CheckBox
          :title="$t('editor.correct_behaviour')"
          v-model="step.is_intended"
          :hint="
            $t(
              'editor.correct_behavior_the_player_will_be_rewarded_with_score_when_finishing_this_step_otherwise_the_player_will_lose_the_score'
            )
          "
        ></CheckBox>
        <CheckBox
          v-model="step.add_to_checklist"
          :title="$t('editor.add_to_checklist')"
          :hint="$t('editor.is_on_checklist')"
        ></CheckBox>

        <div class="flex flex-row justify-between mt-5">
          <ToggleButton
            class="flex-1"
            v-model="step.enabled"
            :title="$t('editor.enabled')"
            :hint="$t('editor.enabled_or_disable_this_step')"
            @toggle="toggle"
          />
        </div>
        <div class="flex flex-row w-full justify-between">
          <div>
            <Button
              :title="
                data.type === 'edit' ? $t('button.update') : $t('button.save')
              "
              type="submit"
              button-type="editor-secondary"
              :invalid="Object.keys(errors).length !== 0"
            />
            <Button
              :title="$t('button.cancel')"
              button-type="editor-primary"
              class="ml-3"
              @clickAction="cancel()"
            />
          </div>
          <Button
            v-if="data.type === 'edit'"
            icon="trash"
            size="m"
            hover="red"
            button-type="trashcan"
            class="ml-3"
            @clickAction="showDeleteModal = true"
          />
        </div>
        <AcceptModal
          v-if="showDeleteModal"
          :header-text="$t('editor.delete_step')"
          :sub-header-text="
            $t('editor.are_you_sure_you_want_to_delete_this_step')
          "
          :accept-button-text="$t('general.accept')"
          :cancel-button-text="$t('general.cancel')"
          @accept="deleteStep"
          @cancel="showDeleteModal = false"
        />
      </Form>
    </div>
  </div>
</template>

<script>
import InputField from "@/views/editor/components/inputs/InputField";
import Button from "@/components/form/Button";
import ToggleButton from "@/views/editor/components/inputs/ToggleButton";
import TextArea from "@/views/editor/components/inputs/TextArea";
import CheckBox from "@/views/editor/components/inputs/CheckBox";
import { mapState } from "vuex";
import Loader from "@/views/editor/components/Loader";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import AcceptModal from "@/components/helpers/modals/AcceptModal";
const stepRepository = RepositoryFactory.get("step");

export default {
  name: "StepProperties",
  components: {
    InputField,
    Button,
    ToggleButton,
    TextArea,
    CheckBox,
    Loader,
    AcceptModal,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      showDeleteModal: false,
      step: {
        name: "",
        type: "",
        repeatable: 1,
        non_linear_randomization: 0,
        is_required: true,
        add_to_checklist: false,
        enabled: true,
        max_score: 0,
        is_opportunity: true,
        is_intended: false,
        remark: "",
      },
      preventSubmitOnEnter: false,
    };
  },
  computed: {
    ...mapState("editorStore", ["activeStep", "activeLesson", "setupSteps"]),
  },
  watch: {
    activeStep: {
      handler() {
        if (this.data.type === "edit" && this.activeStep.id) {
          this.getStep(this.activeStep.id);
        }
      },
      deep: true,
    },
    "activeStep.id": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getStep(newVal);
        }
      },
    },
    "activeStep.type": {
      handler(newVal, oldVal) {
        if (newVal === "create") {
          this.step = {
            name: "",
            type: "",
            repeatable: 0,
            sort: this.activeStep.sort,
            non_linear_randomization: 0,
            is_required: true,
            add_to_checklist: false,
            enabled: true,
            max_score: 1,
            is_opportunity: true,
            is_intended: false,
            remark: "",
          };
        }
      },
    },
  },
  mounted() {
    if (this.activeStep.id) {
      this.getStep(this.activeStep.id);
    }
  },
  methods: {
    type: function (kind) {
      if (kind === "setup" || kind === "listener" || kind === "nonLinear") {
        return "nonLinear";
      } else if (kind === "linear") {
        return "linear";
      }
    },
    saveStep() {
      if (this.data.type === "edit") {
        this.step.repeatable = parseInt(this.step.repeatable);
        this.step.non_linear_randomization = parseInt(
          this.step.non_linear_randomization
        );
        this.step.max_score = parseInt(this.step.max_score);

        this.$store.dispatch("editorStore/EDIT_STEP", this.step);
      } else {
        this.step.type = this.type(this.data.kind);
        this.step.repeatable = parseInt(this.step.repeatable);
        this.step.non_linear_randomization = parseInt(
          this.step.non_linear_randomization
        );
        this.step.max_score = parseInt(this.step.max_score);
        if (this.data.kind === "setup") {
          this.step.sort = this.setupSteps.length
            ? this.setupSteps[this.setupSteps.length - 1].sequence + 1
            : 0;
        } else {
          this.step.sort = this.activeStep.sort;
        }
        this.$store.dispatch("editorStore/CREATE_STEP", this.step);
      }
    },
    getStep: function (id) {
      this.loading = true;
      stepRepository
        .getStepById(this.activeLesson.id, id)
        .then((result) => {
          this.step = {
            name: result.data.data.name,
            sort: result.data.data.sequence,
            type: result.data.data.type,
            repeatable: result.data.data.repeatable,
            non_linear_randomization: result.data.data.randomization,
            is_required: result.data.data.is_required,
            add_to_checklist: result.data.data.add_to_checklist,
            enabled: result.data.data.enabled,
            max_score: result.data.data.max_score,
            is_opportunity: result.data.data.is_opportunity,
            is_intended: result.data.data.is_intended,
            remark: result.data.data.remark,
          };
          this.loading = false;
        })
        .catch((err) => {});
    },
    deleteStep() {
      this.$store.dispatch("editorStore/DELETE_STEP");
    },
    cancel() {
      this.$store.dispatch("editorStore/SET_ACTIVE_STEP", {
        id: "",
        kind: "",
        type: "",
        activeType: "",
      });
    },
    toggle() {
      this.step.enabled = !this.step.enabled;
    },
    preventSubmit(boolean) {
      this.preventSubmitOnEnter = boolean;
    },
  },
};
</script>

<style></style>
