/* eslint-disable no-unused-vars */
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import router from "../../router";
import i18n from "../../i18n";
const UserRepository = RepositoryFactory.get("user");

const state = {
  currentUser: {},
  passwordReset: false,
  showActivationModal: false,
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },
};

const actions = {
  GET_CURRENT_USER: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      UserRepository.getCurrentUser()
        .then((resp) => {
          const response = resp.data.data;

          const user = {
            created_at: response.created_at,
            email: response.email,
            firstname: response.firstname,
            lastname: response.lastname,
            image: response.image,
            id: response.id,
            roles: response.roles,
            roles_reachable: response.roles_reachable,
            demo: response.customer ? response.customer.data.demo : false,
            updated_at: response.updated_at,
            played_games: response["played-games"],
          };
          commit("CURRENT_USER", user);
          resolve(resp);
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_getting_the_current_user"
            )
          );
          localStorage.removeItem("user-token");
          localStorage.removeItem("refresh-user-token");
          router.push({ name: "login" });
          reject(err);
        });
    });
  },

  GET_USER_PINCODE: ({ commit, dispatch, state }, payload) => {
    return new Promise((resolve, reject) => {
      UserRepository.getUserPincode({
        id: state.currentUser.id,
        training_id: payload,
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_getting_the_current_user_pincode"
            )
          );
          reject(err);
        });
    });
  },

  SET_CURRENT_USER: ({ commit, dispatch }, payload) => {
    const user = {
      created_at: payload.created_at,
      email: payload.email,
      firstname: payload.firstname,
      lastname: payload.lastname,
      image: payload.image,
      id: payload.id,
      roles: payload.roles,
      roles_reachable: payload.roles_reachable,
      demo: payload.customer ? payload.customer.data.demo : false,
      updated_at: payload.updated_at,
    };
    commit("CURRENT_USER", user);
  },

  SHOW_PASSWORD_RESET: ({ commit, dispatch }, payload) => {
    commit("PASSWORD_RESET", payload);
  },

  TOGGLE_ACTIVATION_MODAL: ({ commit, dispatch }, payload) => {
    commit("TOGGLE_ACTIVATION_MODAL", payload);
  },
};

const mutations = {
  CURRENT_USER: (state, payload) => {
    state.currentUser = payload;
  },
  PASSWORD_RESET: (state, payload) => {
    state.passwordReset = payload;
  },
  TOGGLE_ACTIVATION_MODAL: (state, payload) => {
    state.showActivationModal = !state.showActivationModal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
