<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <div class="flex flex-row justify-end">
        <Button
          :title="$t('gamecharacters.add_game_character')"
          @click.native="createCharacter"
          button-type="editor-secondary"
        />
      </div>
      <div class="overflow-hidden sm:rounded-md">
        <div class="flex flex-col">
          <div
            class="-mt-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="align-center inline-block min-w-full overflow-hidden sm:rounded-lg"
            >
              <div
                v-if="loading"
                class="py-10 flex items-center justify-center"
              >
                <Loader />
              </div>
              <table
                v-else
                class="min-w-full bg-gray-800"
                data-cy="gameCharactersTable"
              >
                <thead class="bg-blue-800">
                  <tr>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider"
                    >
                      {{ $t("general.name") }}
                    </th>
                    <th
                      v-for="(locale, index) in locales"
                      :key="`${locale.description}-${index}`"
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider"
                    >
                      {{ locale.description }}
                    </th>
                    <th class="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRow
                    v-for="gameCharacterData in gameCharacters"
                    :game-character="gameCharacterData"
                    :key="gameCharacterData.id"
                    :locales="locales"
                    @edit="editGameCharacter(gameCharacterData)"
                    @delete="deleteGameCharacter(gameCharacterData.id)"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GameCharacterModal
      v-if="showModal"
      :create="create"
      :game-character="gameCharacter"
      @close="close()"
    />
  </PageContentContainer>
</template>

<script>
import PageContentContainer from "@/components/helpers/PageContentContainer";
import Loader from "@/components/helpers/Loader";
import TableRow from "./components/TableRowGameCharacters";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import Button from "@/components/form/Button";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { showNotification } from "@/services/notificationService";
import { mapState } from "vuex";
import GameCharacterModal from "@/views/editor/components/modals/GameCharacterModal";
const gameCharacterRepository = RepositoryFactory.get("gameCharacters");

export default {
  name: "GameCharacters",
  components: {
    Loader,
    PageContentContainer,
    TableRow,
    Button,
    GameCharacterModal,
  },

  data: () => {
    return {
      loading: true,
      gameCharacters: null,
      gameCharacter: null,
      create: false,
      showModal: false,
      buttonSettings: [
        {
          title: "gamecharacters.add_game_character",
          cy: "createCharacter",
          action: "buttonAction",
        },
      ],
    };
  },

  computed: {
    ...mapState("translationStore", ["locales"]),
  },

  mounted() {
    this.getGameCharacters();
    this.$store.dispatch("translationStore/GET_LOCALES");
  },

  methods: {
    getGameCharacters() {
      gameCharacterRepository
        .getGameCharacterSettings()
        .then((result) => {
          this.gameCharacters = result.data.data;
          this.loading = false;
        })
        .catch(() => {});
    },

    createCharacter() {
      this.create = true;
      this.showModal = true;
    },

    deleteGameCharacter(gameCharacterId) {
      gameCharacterRepository
        .deleteGameCharacter(gameCharacterId)
        .then(() => {
          showNotification(
            this.$t("notifications.character_deleted"),
            this.$t("notifications.character_is_successfully_deleted"),
            "success"
          );
          this.getGameCharacters();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_deleting_this_character"
            )
          );
        });
    },

    editGameCharacter(gameCharacter) {
      this.create = false;
      this.gameCharacter = gameCharacter;
      this.showModal = true;
    },

    close() {
      this.showModal = false;
      this.getGameCharacters();
    },
  },
};
</script>

<style></style>
