import Repository from "./Repository";

const resource = "/api/v3/gamesession";

export default {
  getGameSession(payload) {
    return Repository.get(
      `${resource}/${payload}?include=step_scores.action_scores,lesson_averages`
    );
  },

  getImage(payload) {
    return Repository.get(payload);
  },
};
