<template>
  <div>
    <p class="mt-30 mb-15 text-lightAlineaColor font-medium text-22">
      {{ $t("download.generate_user_activity_report") }}
    </p>
    <div
      class="flex flex-row mobile:flex-col justify-start mobile:justify-center items-center flex-wrap"
    >
      <div
        class="flex w-260 flex-col h-260 mobile:h-120 items-center desktop:mr-30 mobile:my-5"
      >
        <p
          class="text-20 font-normal text-lightAlineaColor ml-15 mt-15 flex self-start"
        ></p>
        <div class="flex justify-center items-center flex-1 mb-5 mobile:mt-10">
          <DatePicker
            :value="selectedDate"
            :locale="this.$i18n.locale"
            orientation="col"
            @return="updateSelectedDate"
            size="w-auto"
          />
        </div>
      </div>

      <font-awesome-icon
        v-if="status === 'downloadable'"
        icon="chevron-right"
        class="fa-4x mobile:hidden"
        style="color: #ffffff45"
      />

      <font-awesome-icon
        v-if="status === 'downloadable'"
        icon="chevron-down"
        class="fa-4x desktop:hidden"
        style="color: #ffffff45"
      />

      <div
        v-if="status === 'downloadable'"
        class="backgroundCard flex w-260 flex-col h-260 mobile:h-120 desktop:ml-30 mobile:my-5"
      >
        <p class="text-20 font-normal text-lightAlineaColor ml-15 mt-15">
          {{ $t("download.here_is_your_report") }}
        </p>
        <div class="flex justify-center items-center flex-1 mb-5 mobile:mt-10">
          <Button
            :title="$t('download.download')"
            button-type="primary"
            :loading="loading"
            :invalid="false"
            @clickAction="download(downloadableFile)"
          />
        </div>
      </div>
    </div>
    <DownloadWarningModal
      v-if="showModal"
      @cancel="showModal = false"
      @accept="accept"
      :title="$t('download.user_activity_report_download')"
      :message="modalDescription"
    />
  </div>
</template>

<script>
import DownloadWarningModal from "@/components/helpers/modals/DownloadWarningModal";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const reportingRepository = RepositoryFactory.get("reporting");
import Button from "@/components/form/Button";
import { subDays } from "date-fns";
import DatePicker from "@/components/reporting/DatePicker";
import { errorHandlingAndMessage } from "@/services/ErrorService";

export default {
  name: "Download",
  components: {
    Button,
    DatePicker,
    DownloadWarningModal,
  },
  data() {
    return {
      selectedDate: {
        start: subDays(new Date(), 30),
        end: new Date(),
      },
      modalDescription: "",
      downloadableFile: null,
      loading: false,
      showModal: false,
      status: null,
      accept: () => {},
    };
  },
  watch: {
    selectedDate: {
      handler() {
        if (this.status === "downloadable") {
          this.showModal = true;

          this.modalDescription = this.$t(
            "download.you_did_not_download_your_file"
          );

          this.accept = () => {
            this.status = null;
            this.showModal = false;
            this.generate();
          };
        } else {
          this.generate();
        }
      },
      deep: true,
    },
  },

  methods: {
    generate() {
      reportingRepository
        .getActivityDataCSV(this.selectedDate.start, this.selectedDate.end)
        .then((result) => {
          this.downloadableFile = result.data.download_url;
          this.status = "downloadable";
        })
        .catch(() => {});
    },

    download(url) {
      this.loading = true;
      reportingRepository
        .downloadCSV(url)
        .then((result) => {
          window.open(result.data.data.uri, "_blank");
          this.status = null;
          this.downloadableFile = null;
          this.loading = false;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_downloading_the_csv")
          );
        });
    },

    cancel() {
      this.showModal = false;
    },

    updateSelectedDate(date) {
      this.selectedDate = date;
    },
  },
};
</script>
