import { RepositoryFactory } from "@/repository/RepositoryFactory";
const TrainingRepository = RepositoryFactory.get("training");
import { formatTraining } from "@/services/trainingService";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import i18n from "@/i18n";

const state = {
  baseDomain:
    process.env.VUE_APP_API_BASE_URL ||
    process.env.VUE_APP_DEFAULT_API_BASE_URL,
  training_versions: [],
  trainings: [],
  activeTraining: null,
  activeEditorTraining: {},
  chapter: {},
  chapters: null,
  showLevelModal: "none",
  subchapter: {},
  level: {},
  trainingId: localStorage.getItem("trainingId"),
  versionId: localStorage.getItem("versionId"),
  showCreateTraining: false,
  showEditTraining: false,
  showDeleteTraining: false,
  toEditTraining: null,
  deleteId: null,
  loading: false,
  versions: null,
  current_version: null,
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },
  subchapter(state) {
    return state.subchapter;
  },
  chapter(state) {
    return state.chapter;
  },
};

const actions = {
  SET_ACTIVE_TRAINING: ({ commit, dispatch }, payload) => {
    commit("ACTIVE_TRAINING", payload);
  },
  SET_ACTIVE_EDITOR_TRAINING: ({ commit, dispatch }, payload) => {
    commit("ACTIVE_EDITOR_TRAINING", payload);
    dispatch("SElECT_TRAINING", payload);
  },
  SET_ACTIVE_CHAPTER: ({ commit, dispatch }, payload) => {
    commit("CHAPTER", payload);
  },
  SET_ACTIVE_SUBCHAPTER: ({ commit, dispatch }, payload) => {
    commit("SUBCHAPTER", payload);
  },
  SET_ACTIVE_LEVEL: ({ commit, dispatch }, payload) => {
    commit("LEVEL", payload);
  },
  SET_TRAINING_ID: ({ commit, dispatch }, payload) => {
    localStorage.setItem("trainingId", payload.trainingId);
    commit("ID", payload);
  },
  SHOW_MODAL: ({ commit, dispatch }, payload) => {
    commit("LOADING", false);
    commit("SHOW_MODAL", payload);
  },
  HIDE_MODAL: ({ commit, dispatch }, payload) => {
    commit("LOADING", false);
    commit("HIDE_MODAL", payload);
  },
  SHOW_LEVEL_MODAL: ({ commit, dispatch }, payload) => {
    commit("SHOW_LEVEL_MODAL", payload);
  },
  CREATE_TRAINING: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      TrainingRepository.createTraining(payload.training)
        .then((result) => {
          if (payload.image) {
            let formData = new FormData();
            formData.append("image", payload.image, payload.image.name);
            TrainingRepository.addImageToTraining(result.data.data.id, formData)
              .then((result) => {
                commit("HIDE_MODAL", { type: "create", val: false });
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            commit("HIDE_MODAL", { type: "create", val: false });
            resolve();
          }
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_creating_this_training"
            )
          );
          reject(err);
        });
    });
  },
  EDIT_TRAINING: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      TrainingRepository.editTraining(payload.training, payload.id)
        .then((training) => {
          if (payload.image) {
            let formData = new FormData();
            formData.append("image", payload.image, payload.image.name);
            TrainingRepository.addImageToTraining(
              training.data.data.id,
              formData
            )
              .then((result) => {
                commit("HIDE_MODAL", { type: "edit", val: false });
                dispatch("REFRESH_TRAINING");

                resolve();
              })
              .catch((err) => {
                reject(err);
                // show user that image isnt uploaded
              });
          } else {
            commit("HIDE_MODAL", { type: "edit", val: false });
            dispatch("REFRESH_TRAINING");

            resolve();
          }
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_editing_this_training"
            )
          );
          reject(err);
        });
    });
  },
  DELETE_TRAINING: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("LOADING", true);
      TrainingRepository.deleteTraining(payload)
        .then((result) => {
          commit("LOADING", false);
          commit("DELETE_TRAINING", payload);
          commit("HIDE_MODAL", { type: "delete", val: false });
          resolve();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_deleting_this_training"
            )
          );
          reject(err);
        });
    });
  },
  REFRESH_TRAINING: ({ commit, state, dispatch }) => {
    state.trainings = [];
    dispatch("GET_TRAININGS");
  },
  GET_TRAININGS: ({ commit, dispatch }) => {
    TrainingRepository.getTrainings().then((resp) => {
      resp.data.data.forEach(async (game) => {
        const training = await formatTraining(game);
        commit("TRAINING", training);
        if (game.latest_version) {
          commit("TRAINING_VERSIONS", {
            version: game.latest_version.data.id,
            id: game.id,
            name: game.name,
          });
        }
      });
    });
  },
  GET_CHAPTERS: ({ commit, state }) => {
    TrainingRepository.showVersionContent(state.trainingId, state.versionId)
      .then((resp) => {
        const gameId = resp.data.data[0].id;
        const chapters = resp.data.data[0].children;
        commit("CHAPTERS", chapters);
        commit("LOADING", false);
      })
      .catch((err) => {});
  },
  GET_VERSIONS: ({ commit, state }, payload) => {
    const trainingId = payload ? payload : state.trainingId;
    TrainingRepository.showVersions(trainingId)
      .then((resp) => {
        const currentVersion =
          resp.data.data.find((version) => version.id === state.versionId) ??
          "latest_version";
        commit("VERSIONS", resp.data.data);
        commit("CURRENT_VERSION", currentVersion.id);
      })
      .catch((err) => {
        errorHandlingAndMessage(
          err,
          i18n.global.t(
            "notifications.something_went_wrong_while_selecting_this_version"
          )
        );
      });
  },
  SET_CURRENT_VERSION: ({ commit, dispatch }, payload) => {
    commit("LOADING", true);
    localStorage.setItem("versionId", payload);
    commit("CURRENT_VERSION", payload);
  },
  SElECT_TRAINING: ({ commit, dispatch }, payload) => {
    const trainingId = payload.id;
    const currentVersionId = localStorage.getItem("versionId");
    const versionId = currentVersionId
      ? currentVersionId
      : payload.latest_version.data.id;

    if (!currentVersionId) {
      localStorage.setItem("versionId", versionId);
    }

    dispatch("SET_CURRENT_VERSION", versionId);

    dispatch("SET_TRAINING_ID", {
      trainingId: trainingId,
    });
    dispatch("GET_VERSIONS");
    dispatch("GET_CHAPTERS");

    localStorage.setItem("trainingId", trainingId);
  },
};

const mutations = {
  TRAINING_VERSIONS: (state, payload) => {
    if (
      !state.training_versions.some((elem) => elem.version === payload.version)
    ) {
      state.training_versions.push(payload);
    }
  },
  TRAINING: (state, payload) => {
    if (!state.trainings.some((elem) => elem.id === payload.id)) {
      state.trainings.push(payload);
    }
  },
  DELETE_TRAINING: (state, payload) => {
    state.trainings = state.trainings.filter(
      (training) => training.id !== payload
    );
  },
  UPDATE_GAME: async (state, payload) => {
    state.trainings = state.trainings.filter(
      (training) => training.id !== payload.id
    );
    state.trainings.push(await formatTraining(payload));
  },
  ACTIVE_TRAINING: (state, payload) => {
    state.activeTraining = payload;
  },
  ACTIVE_EDITOR_TRAINING: (state, payload) => {
    state.activeEditorTraining = payload;
  },
  CHAPTER: (state, payload) => {
    state.chapter = payload;
  },
  LOADING: (state, payload) => {
    state.loading = payload;
  },
  CHAPTERS: (state, payload) => {
    state.chapters = payload;
  },
  SUBCHAPTER: (state, payload) => {
    state.subchapter = payload;
  },
  LEVEL: (state, payload) => {
    state.level = payload;
  },
  ID: (state, payload) => {
    state.trainingId = payload.trainingId;
  },
  SHOW_MODAL: (state, payload) => {
    return new Promise((resolve, reject) => {
      if (payload.type === "create") state.showCreateTraining = payload.val;
      if (payload.type === "delete") {
        state.showDeleteTraining = payload.val;
        state.deleteId = payload.training.id;
        resolve();
      }
      if (payload.type === "edit") {
        state.showEditTraining = payload.val;
        state.toEditTraining = payload.training;
      }
    });
  },
  HIDE_MODAL: (state, payload) => {
    return new Promise((resolve, reject) => {
      if (payload.type === "create") state.showCreateTraining = payload.val;
      if (payload.type === "delete") {
        state.showDeleteTraining = payload.val;
        resolve();
      }
      if (payload.type === "edit") {
        state.showEditTraining = payload.val;
      }
    });
  },
  SHOW_LEVEL_MODAL: (state, payload) => {
    state.showLevelModal = payload;
  },
  VERSIONS: (state, payload) => {
    state.versions = payload;
  },
  CURRENT_VERSION: (state, payload) => {
    state.versionId = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
