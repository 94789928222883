<template>
  <div>
    <div class="mt-5">
      <div class="flex flex-row mb-1 items-center cursor-pointer z-10">
        <div @click="toggle()" class="flex justify-center items-center">
          <font-awesome-icon
            icon="chevron-up"
            class="text-white text-18 rotate"
            :class="rotate"
          />
          <b
            class="ml-3 text-white text-16"
            :class="chapter.active ? 'text-white' : 'text-gray-600'"
          >
            {{ $t("editor.chapter") }}
          </b>
          <p
            class="ml-1 text-16"
            :class="chapter.active ? 'text-white' : 'text-gray-600'"
          >
            {{ chapter.name }}
          </p>
        </div>
        <div class="ml-6 relative inline-block text-left">
          <div>
            <OptionMenu
              :options="options"
              target="chapterTranslation"
              color="text-gray-400"
              :separation-line="2"
              icon="cog"
              @editChapter="editChapter(chapter)"
              @addSubChapter="addSubChapter(chapter)"
              @recentTranslationFiles="
                showTranslationModal('files', 'category', chapter.id)
              "
              @importChapterTranslations="
                showTranslationModal('import', 'category', chapter.id)
              "
              @exportChapterTranslation="
                showTranslationModal('export', 'category', chapter.id)
              "
              @deleteChapter="showDeleteModal = true"
            />
          </div>
        </div>
      </div>
      <div class="mt-5" v-if="showChapter">
        <draggable
          :id="chapter.id"
          @end="changed($event)"
          class="flex flex-row"
          :list="chapter.children"
          item-key="id"
          :component-data="getComponentData()"
          group="chapters"
        >
          <template #item="{ element }">
            <Subchapter
              :key="element.id"
              :id="element.id"
              :name="element.name"
              :introduction="element.intro"
              :description="element.description"
              :active="element.active"
              :sort="element.sort"
              :chapter="chapter"
              :subchapter="element"
              :parent-active="chapter.active"
            />
          </template>
        </draggable>
      </div>
    </div>

    <div v-if="showImportModal">
      <MediaModal
        media-modal-type="translation"
        :required="true"
        @toggleMediaModal="closeTranslationModal('import')"
        @finishUpload="finishUpload"
      />
    </div>
    <div v-if="showTranslationFilesModal">
      <FilesModal
        media-modal-type="recent-translation-files"
        :required="true"
        :tab="3"
        @toggleMediaModal="closeTranslationModal('files')"
      />
    </div>
    <div v-if="showSelectLocaleModal">
      <SelectLocaleModal
        :file-name="fileName"
        @cancel="showSelectLocaleModal = false"
        @confirm="finishTranslationImport"
      />
    </div>

    <div v-if="showExportModal">
      <ExportModal
        :header-text="$t('editor.export_translations')"
        target="translation"
        :sub-header-text="$t('editor.export_translations_as_csv')"
        @close="closeTranslationModal('export')"
      />
    </div>
    <AcceptModal
      v-if="showDeleteModal"
      :header-text="$t('editor.delete_chapter')"
      :sub-header-text="
        $t('editor.are_you_sure_you_want_to_delete_this_chapter')
      "
      :accept-button-text="$t('general.accept')"
      :cancel-button-text="$t('general.cancel')"
      @accept="deleteChapter(chapter.id)"
      @cancel="showDeleteModal = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Subchapter from "./Subchapter";
import { showNotification } from "@/services/notificationService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import draggable from "vuedraggable";
import OptionMenu from "@/components/helpers/OptionMenu";
import AcceptModal from "@/components/helpers/modals/AcceptModal";
import uploadTranslationHelper from "@/mixins/uploadTranslationHelper";
import FilesModal from "@/views/editor/components/modals/filesModal/FilesModal";

export default {
  name: "Levels",
  components: {
    FilesModal,
    draggable,
    Subchapter,
    OptionMenu,
    AcceptModal,
  },
  mixins: [uploadTranslationHelper],
  props: {
    chapter: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showChapter: this.chapter.active,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapState("trainingStore", ["trainingId", "versionId", "chapters"]),
    options: function () {
      if (this.chapter.children) {
        return [
          {
            option: "importChapterTranslations",
            name: "Import chapter translations",
          },
          {
            option: "exportChapterTranslation",
            name: "Export chapter translations",
          },
          {
            option: "recentTranslationFiles",
            name: "Show recent translation export files",
          },
          { option: "editChapter", name: "Edit chapter" },
          { option: "addSubChapter", name: "Add subchapter" },
        ];
      } else {
        return [
          { option: "editChapter", name: "Edit chapter" },
          { option: "addSubChapter", name: "Add subchapter" },
          { option: "deleteChapter", name: "Delete chapter" },
          {
            option: "importChapterTranslations",
            name: "Import chapter translations",
          },
          {
            option: "exportChapterTranslation",
            name: "Export chapter translations",
          },
          {
            option: "recentTranslationFiles",
            name: "Show recent translation export files",
          },
        ];
      }
    },
    rotate: function () {
      return this.showChapter ? "up" : "down";
    },
  },
  methods: {
    toggle() {
      this.showChapter = !this.showChapter;
    },
    addSubChapter(chapter) {
      this.$store.dispatch("trainingStore/SET_ACTIVE_CHAPTER", chapter);
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "addSubChapter");
    },
    editChapter(chapter) {
      this.$store.dispatch("trainingStore/SET_ACTIVE_CHAPTER", chapter);
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "editChapter");
    },
    deleteChapter(chapterId) {
      trainingRepository
        .deleteChapter(this.trainingId, this.versionId, chapterId)
        .then((resp) => {
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
          showNotification(
            this.$t("notifications.chapter_deleted"),
            this.$t("notifications.chapter_is_successfully_deleted"),
            "success"
          );
        })
        .catch((err) => {
          showNotification(
            err,
            this.$t(
              "notifications.something_went_wrong_while_deleting_this_chapter"
            ),
            "error"
          );
        });
    },
    async changed(event) {
      const oldParentId = event.from.id;
      const newParentId = event.to.id;
      const subChapterId = event.item.id;
      const newIndex = event.newDraggableIndex;
      const oldIndex = event.oldDraggableIndex;
      if (newParentId !== oldParentId || newIndex !== oldIndex) {
        const chapter = {
          sort: newIndex,
          parent: newParentId,
        };
        trainingRepository
          .editChapter(this.trainingId, this.versionId, subChapterId, chapter)
          .then(() => {
            this.$store.dispatch("trainingStore/GET_CHAPTERS");
            showNotification(
              this.$t("notifications.sort_subchapter"),
              this.$t("notifications.subchapter_is_successfully_sorted"),
              "success"
            );
          })
          .catch(() => {
            showNotification(
              this.$t("notifications.sort_chapter"),
              this.$t(
                "notifications.something_went_wrong_while_sorting_this_subchapter"
              ),
              "error"
            );
          });
      }
    },
    handleChange(value) {},
    inputChanged(value) {
      this.activeNames = value;
    },
    // getComponentData() {
    //   return {
    //     onChange: this.handleChange,
    //     onInput: this.inputChanged,
    //     wrap: true,
    //     value: this.introduction
    //   };
    // }
    getComponentData() {
      return {
        onChange: this.handleChange,
        onInput: this.inputChanged,
        wrap: true,
        value: "",
      };
    },
  },
};
</script>
