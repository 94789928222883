import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCirclePlay,
  faPlay,
  faChevronDown,
  faBars,
  faExclamationCircle,
  faListOl,
  faUserCircle,
  faSignOutAlt,
  faAddressCard,
  faGlobe,
  faUsers,
  faAddressBook,
  faCog,
  faGamepad,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faDownload,
  faList,
  faLock,
  faHome,
  faChevronLeft,
  faPollH,
  faListAlt,
  faChartPie,
  faUserClock,
  faUserCheck,
  faCalendar,
  faGem,
  faClock,
  faCheck,
  faTimes,
  faCalendarPlus,
  faHdd,
  faChevronRight,
  faUndo,
  faUserAstronaut,
  faToolbox,
  faSlidersH,
  faPlus,
  faMinus,
  faChevronUp,
  faTrash,
  faEdit,
  faEye,
  faCaretDown,
  faExclamation,
  faLightbulb,
  faBullseye,
  faArrowRight,
  faEyeSlash,
  faEnvelope,
  faVideo,
  faFile,
  faUpload,
  faGlobeEurope,
  faBuilding,
  faCogs,
  faSpinner,
  faExclamationTriangle,
  faBook,
  faBookOpen,
  faLayerGroup,
  faEllipsisV,
  faImage,
  faUserMinus,
  faUserPlus,
  faSortUp,
  faSortDown,
  faPaperPlane,
  faSync,
  faLongArrowAltRight,
  faTags,
  faBookReader,
  faMedkit,
  faComment,
  faSave,
  faCodeBranch,
  faLanguage,
  faTrophy,
  faAsterisk,
  faPhotoVideo,
  faMusic,
  faFileDownload,
  faFileUpload,
  faCircle,
  faFileAudio,
  faSearchPlus,
  faFileExport,
  faQuestionCircle,
  faBolt,
  faTools,
  faExternalLinkAlt,
  faClipboard,
  faCopy,
  faEllipsisH,
  faClone,
  faPlayCircle,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  faAppStoreIos,
  faGooglePlay,
  faWindows,
  faAndroid,
  faHtml5,
  faApple,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faCirclePlay,
  faBars,
  faExclamationCircle,
  faHtml5,
  faListOl,
  faUserCircle,
  faSignOutAlt,
  faAddressCard,
  faGlobe,
  faAddressBook,
  faCog,
  faChevronDown,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faPlay,
  faDownload,
  faList,
  faLock,
  faHome,
  faChevronLeft,
  faPollH,
  faListAlt,
  faTimes,
  faChartPie,
  faUserClock,
  faUserCheck,
  faCalendar,
  faGem,
  faClock,
  faAppStoreIos,
  faGooglePlay,
  faWindows,
  faAndroid,
  faCheck,
  faTimes,
  faCalendarPlus,
  faHdd,
  faUsers,
  faGamepad,
  faChevronRight,
  faUndo,
  faUserAstronaut,
  faToolbox,
  faSlidersH,
  faPlus,
  faMinus,
  faChevronUp,
  faTrash,
  faEdit,
  faEye,
  faCaretDown,
  faExclamation,
  faLightbulb,
  faBullseye,
  faArrowRight,
  faEyeSlash,
  faEnvelope,
  faVideo,
  faFile,
  faUpload,
  faGlobeEurope,
  faBuilding,
  faCogs,
  faSpinner,
  faExclamationTriangle,
  faBook,
  faBookOpen,
  faLayerGroup,
  faEllipsisV,
  faImage,
  faUserMinus,
  faUserPlus,
  faSortUp,
  faSortDown,
  faPaperPlane,
  faSync,
  faLongArrowAltRight,
  faLayerGroup,
  faBookReader,
  faMedkit,
  faComment,
  faSave,
  faTags,
  faEyeSlash,
  faCodeBranch,
  faLanguage,
  faTrophy,
  faAsterisk,
  faPhotoVideo,
  faMusic,
  faFileDownload,
  faFileUpload,
  faCircle,
  faFileAudio,
  faSearchPlus,
  faFileExport,
  faQuestionCircle,
  faTools,
  faExternalLinkAlt,
  faBolt,
  faClipboard,
  faCopy,
  faEllipsisH,
  faClone,
  faPlayCircle,
  faPauseCircle,
  faApple
);

export default library;
