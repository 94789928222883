import Repository from "./Repository";
import i18n from "@/i18n";

const resource = "/api/v3/trainings";

export default {
  getAvailableActions(trainingId, versionId) {
    return Repository.get(
      `${resource}/${trainingId}/version/${versionId}/actions?include=unity_package_action_properties,unity_package_action_type`
    );
  },

  newActionTemplate(lessonId, stepId, actionId) {
    return Repository.get(
      `/api/v3/lessons/${lessonId}/steps/${stepId}/actions/new/${actionId}?include=action_properties`
    );
  },

  addActionToStep(lessonId, stepId, action) {
    return Repository.post(
      `/api/v3/lessons/${lessonId}/steps/${stepId}/actions?include=action_properties.media`,
      { action: action }
    );
  },

  updateAction(lessonId, stepId, actionId, action) {
    return Repository.patch(
      `/api/v3/lessons/${lessonId}/steps/${stepId}/actions/${actionId}?include=unity_package_action.unity_package_action_properties,action_properties.media`,
      { action: action }
      // {
      //   headers: {
      //     "Accept-Language": 'en_US'
      //   }
      // }
    );
  },

  updateActionProperties(lessonId, stepId, actionId, action, locale = "en_US") {
    return Repository.patch(
      `/api/v3/lessons/${lessonId}/steps/${stepId}/actions/${actionId}/properties?include=unity_package_action.unity_package_action_properties,action_properties.media`,
      { action: action },
      {
        headers: {
          "Accept-Language": locale,
        },
      }
    );
  },

  getSingleAction(lessonId, stepId, actionId, locale = "en_US") {
    return Repository.get(
      `/api/v3/lessons/${lessonId}/steps/${stepId}/actions/${actionId}?include=action_properties.media`,
      {
        headers: {
          "Accept-Language": locale,
        },
      }
    );
  },

  deleteAction(lessonId, stepId, actionId) {
    return Repository.delete(
      `/api/v3/lessons/${lessonId}/steps/${stepId}/actions/${actionId}`
    );
  },

  moveAction(lessonId, stepId, actionId, destinationStepId, sort) {
    return Repository.post(
      `/api/v3/lessons/${lessonId}/steps/${stepId}/actions/${actionId}/move?include=unity_package_action.unity_package_action_properties,action_properties`,
      {
        destination_step_id: destinationStepId,
        sort: sort,
      }
    );
  },

  duplicateAction(lessonId, stepId, actionId, destinationStepId, sort) {
    return Repository.post(
      `/api/v3/lessons/${lessonId}/steps/${stepId}/actions/${actionId}/duplicate?include=unity_package_action.unity_package_action_properties,action_properties`,
      {
        destination_step_id: destinationStepId,
        sort: sort,
      }
    );
  },
};
