import Repository from "./Repository";

const resource = "/api/v3/media";

export default {
  getMedia({ category, type, query, page, maxResult, sortfield, sort }) {
    return Repository.get(
      `${resource}?category=${category}&type=${type}&search_query=${query}&page=${page}&max_results=${maxResult}&sort_by_field=${sortfield}&sort_direction=${sort}`
    );
  },
};
