<template>
  <div
    id="container"
    class="flex justify-center items-center h-screen w-screen lg:py-loginPaddingTopBottom lg:px-loginPaddingLeftRight bg-loginBackgroundColor"
  >
    <div
      id="card"
      class="flex flex-row mobile:h-full w-full max-w-800 h-550 bg-white shadow-lightBg"
    >
      <!-- left side of card -->
      <LeftContainer />
      <!-- right side of card -->
      <div
        id="right"
        class="flex flex-1 flex-col bg-standardBgColor"
        @submit.prevent
        @keypress.enter.prevent="reset"
      >
        <div class="flex flex-2 flex-row w-full justify-center items-center">
          <p class="text-title text-darkAlineaColor mobile:hidden">
            {{ $t("auth.reset_password").toUpperCase() }}
          </p>
          <p
            class="text-title text-darkAlineaColor mobile:items-center mobile:justify-center mobile:font-bold mobile:-mt-6 desktop:items-end desktop:mb-inputPadding desktop:ml-inputPadding desktop:hidden"
          >
            {{ $t("auth.virtualstudio") }}
          </p>
        </div>

        <div
          id="form-container"
          class="flex flex-9 mobile:flex-6 justify-center items-center flex-col px-inputPaddingLeftRight"
        >
          <Form @submit="reset" @keypress.enter="reset" v-slot="{ errors }">
            <div
              v-if="success"
              class="text-17 text-darkAlineaColor text-center"
            >
              <p>
                {{ $t("auth.there_is_an_email_send_to") }}
              </p>
              <b>
                {{ email }}
              </b>
              <p>{{ $t("auth.where_you_can_reset_your_password") }}.</p>
            </div>
            <div class="w-full" v-else>
              <InputField
                class="mt-inputPadding w-full"
                :title="$t('auth.email')"
                type="text"
                rules="required|email"
                placeholder=""
                :required-input="true"
                v-model="email"
              />

              <!-- <Checkbox class="mt-inputPadding" :title="$t('auth.remember_me')" /> -->

              <div class="mt-6">
                <Button
                  class="mb-2"
                  :title="$t('auth.reset_password')"
                  button-type="primary"
                  width="full"
                  type="submit"
                  :invalid="Object.keys(errors).length !== 0"
                  :loading="loading"
                />
                <Button
                  width="full"
                  :title="$t('auth.to_login')"
                  button-type="secondary"
                  @clickAction="$router.push({ name: 'login' })"
                />
              </div>

              <ErrorMessage class="mt-2" :error-message="responseError" />
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/form/InputField";
import Button from "@/components/form/Button";
import ErrorMessage from "@/components/helpers/ErrorMessage";
import LeftContainer from "@/components/authentication/LeftContainer";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
const authRepository = RepositoryFactory.get("auth");

export default {
  name: "Login",
  components: { InputField, Button, LeftContainer, ErrorMessage },
  data: () => ({
    email: "",
    responseError: "",
    loading: false,
    enabled: false,
    success: false,
  }),
  watch: {
    email: {
      handler() {
        if (this.email) {
          this.responseError = "";
          this.enabled = true;
        } else {
          this.enabled = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    reset() {
      this.loading = true;
      if (this.email) {
        authRepository
          .resetPassword(this.email)
          .then(() => {
            this.loading = false;
            this.responseError = "";
            this.success = true;
          })
          .catch((err) => {
            errorHandlingAndMessage(
              err,
              this.$t(
                "notifications.something_went_wrong_resetting_the_password"
              )
            );
            this.responseError = this.$t("auth.wrong_email");
            this.loading = false;
            this.email = "";
          });
      } else {
        this.responseError = "";
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
