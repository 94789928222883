<template>
  <tr v-if="selectedTranslation" :class="opacity">
    <td
      class="flex flex-col px-6 py-4 whitespace-normal align-top break-words divide-y divide-gray-300"
    >
      <span
        class="text-left text-md leading-4 font-medium text-gray-100 tracking-wider mb-2"
      >
        {{ name }}
      </span>
      <div v-if="$route.name === 'lessonTranslations'">
        <div class="mb-3 mt-3">
          <span
            class="text-left text-sm leading-4 font-bold text-gray-100 tracking-wider mb-2"
          >
            {{ `${$t("translation.step")}` }}
          </span>
          <p
            class="text-left text-sm leading-4 font-normal text-gray-100 tracking-wider"
          >
            {{ stepName }}
          </p>
        </div>
        <div>
          <span
            class="text-left text-sm leading-4 font-bold text-gray-100 tracking-wider mb-2"
          >
            {{ $t("translation.action") }}
          </span>

          <p
            class="text-left text-sm leading-4 font-normal text-gray-100 tracking-wider"
          >
            {{ actionName }}
          </p>
        </div>
      </div>
    </td>
    <TranslationSelector
      :locales="locales"
      :selected="selectedLocale"
      @selected="setSelected"
    />
    <EditProductField
      v-if="mediaType"
      :product-field="selectedTranslation"
      :required="isRequired"
      :media-type="mediaType"
      @edit="editProductFieldContent"
    />
    <MediaViewer
      v-if="isMedia && mediaType"
      :media="selectedTranslation.media"
      :media-type="mediaType"
      :id="selectedTranslation.id"
      :required="isRequired"
      :selected-locale="selectedLocale"
      :default-locale="defaultLocale.tag"
      @update:modelValue="editTranslationMedia"
      @setLoading="setLoading"
      @deleteMedia="deleteTranslationMedia"
    />
  </tr>
</template>

<script>
import { mapState } from "vuex";
import { showNotification } from "@/services/notificationService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const productFieldRepository = RepositoryFactory.get("productField");
const lessonMediaRepository = RepositoryFactory.get("lessonMedia");
import TranslationSelector from "@/views/editor/components/TranslationSelector";
import MediaViewer from "@/views/editor/components/MediaViewer";
const trainingRepository = RepositoryFactory.get("training");
import EditProductField from "@/views/editor/components/EditProductField";

export default {
  name: "TranslationRow",
  components: { TranslationSelector, MediaViewer, EditProductField },
  props: {
    translation: {
      type: Object,
      required: true,
    },
    locales: {
      type: Array,
      required: true,
    },
    selectedType: {
      type: String,
      required: true,
    },
    defaultLocale: {
      type: Object,
      default: () => {},
    },
    isGameTranslations: {
      type: Boolean,
      required: true,
    },
    isLessonTranslations: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedMedia: null,
      selectedLocale: "",
      newSelectedType: "",
      textType: ["Json", "None"],
      selectedTranslation: {},
    };
  },
  computed: {
    ...mapState("trainingStore", ["trainingId"]),
    opacity: function () {
      if (this.loading) {
        return "opacity-25";
      } else {
        return "opacity-100";
      }
    },
    isMedia: function () {
      const type = this.selectedTranslation.json_type;
      return !this.textType.includes(type);
    },
    isRequired: function () {
      return this.selectedTranslation.unity_package_action_property?.data
        .required;
    },
    mediaType: function () {
      if (this.isLessonTranslations) {
        return this.selectedTranslation.unity_package_action_property?.data
          .media_type;
      }
      if (this.isGameTranslations) {
        return this.selectedTranslation.json_type;
      }
      return "";
    },
    name: function () {
      if (this.isLessonTranslations) {
        return this.selectedTranslation.unity_reference_name;
      }
      if (this.isGameTranslations) {
        return this.selectedTranslation.name;
      }
      return "";
    },
    stepName: function () {
      if (this.isLessonTranslations) {
        return this.selectedTranslation.action?.data.step.data.name;
      }
      return "";
    },
    actionName: function () {
      if (this.isLessonTranslations) {
        return this.selectedTranslation.action?.data.name;
      }
      return "";
    },
  },
  watch: {
    translation: {
      handler(val) {
        this.selectedTranslation = val[this.selectedLocale];
      },
      deep: true,
    },
    selectedType: {
      handler(val) {
        this.newSelectedType = val;
      },
      deep: true,
    },
    selectedLocale: {
      handler(val) {
        this.selectedTranslation = this.translation[val];
      },
      deep: true,
    },
  },
  mounted() {
    this.selectedLocale = this.defaultLocale.tag;
    this.selectedTranslation = this.translation[this.selectedLocale];
    this.newSelectedType = this.selectedType;
  },
  methods: {
    setSelected(locale) {
      this.selectedLocale = locale;
    },

    editProductFieldContent(value) {
      const productField = {
        product_field: {
          content: value.content ?? this.selectedTranslation.content,
          description:
            value.description ?? this.selectedTranslation.description,
        },
      };
      this.editTranslation(productField);
    },

    editTranslationMedia(value) {
      if (this.isGameTranslations) {
        const productField = {
          product_field: {
            media: value,
          },
        };
        this.editTranslation(productField);
      }
      if (this.isLessonTranslations) {
        const media = {
          media: value,
        };
        this.editTranslation(media);
      }
    },

    deleteTranslationMedia() {
      if (this.isGameTranslations) {
        const gameMediaField = {
          product_field: {
            media: null,
          },
        };
        this.editTranslation(gameMediaField);
      }
      if (this.isLessonTranslations) {
        const lessonMediaField = {
          media: null,
        };
        this.editTranslation(lessonMediaField);
      }
    },

    editTranslation(translation) {
      this.loading = true;
      this.repositoryPicker(translation)
        .then(() => {
          this.$emit("updateProductField");
          showNotification(
            this.$t("notifications.product_field"),
            this.$t("notifications.product_field_has_been_successfully_edited"),
            "success"
          );
        })
        .catch(() => {
          showNotification(
            this.$t("notifications.product_field"),
            this.$t(
              "notifications.something_went_wrong_while_editing_this_product_field"
            ),
            "error"
          );
        })
        .finally(() => (this.loading = false));
    },

    repositoryPicker(translation) {
      if (this.isGameTranslations) {
        return productFieldRepository.editProductField(
          this.$route.params.id,
          this.selectedTranslation.id,
          this.selectedLocale,
          translation
        );
      }
      if (this.isLessonTranslations) {
        return lessonMediaRepository.editLessonMedia(
          this.$route.params.id,
          this.selectedTranslation.id,
          this.selectedLocale,
          translation
        );
      }
    },

    setLoading(val) {
      this.loading = val;
    },
  },
};
</script>

<style></style>
