<template>
  <div
    v-if="size === 'L'"
    :ref="id"
    :id="id"
    class="flex flex-row mr-1"
    @click.stop="setActive"
  >
    <div class="relative cursor-default mt-1">
      <FirstArrow
        v-if="isFirst && enabledList.filter(Boolean).length !== 1"
        color="white"
        class="absolute"
        :show-line="enabledList.filter(Boolean).length !== 1"
      />

      <div v-if="(!isLast && isMiddle) || (isFirst && !isLast)">
        <svg
          class="absolute top-3.5 left-0 h-full overflow-hidden"
          width="20"
          height="100%"
        >
          <line
            x1="6"
            y1="0"
            x2="6"
            y2="200"
            stroke="white"
            stroke-width="1.8"
          />
        </svg>
        <svg
          class="absolute top-5 left-0 h-full overflow-hidden"
          width="20"
          height="100%"
        >
          <line
            x1="6"
            y1="0"
            x2="6"
            y2="200"
            stroke="white"
            stroke-width="1.8"
          />
        </svg>
      </div>

      <SecondArrow
        v-if="
          (!isFirst && isEnabled) ||
          (enabledList.filter(Boolean).length === 1 && isEnabled)
        "
        :show-line="enabledList.length !== index + 1 && !isLast"
        class="absolute"
        style="margin-top: -13px"
      />
    </div>
    <div class="flex flex-col mt-1 w-350 ml-6 mt-1" :class="opacity">
      <div
        :class="[color, borderColor]"
        class="flex flex-row border-2 px-1 -mr-1 items-center justify-between w-100"
      >
        <div class="flex flex-row items-center">
          <div class="w-5 flex items-center justify-center">
            <font-awesome-icon
              v-if="icon"
              :icon="icon.split('-')[1]"
              class="text-white text-15"
            />
          </div>
          <p
            class="text-white ml-1 w-225"
            :class="[{ truncate: !showFullTitles }]"
            v-tooltip="{
              content: name,
              boundary: 'window',
              placement: 'right',
            }"
          >
            {{ name }}
          </p>
        </div>
        <div class="flex items-center justify-center">
          <font-awesome-icon
            v-if="errorState === 0"
            v-tooltip="{
              content: enabled ? $t('editor.disable') : $t('editor.enable'),
              boundary: 'window',
            }"
            :icon="!isEnabled ? 'eye-slash' : 'eye'"
            class="text-white text-15 outline-none"
            @click.stop="toggleEnabled"
          />
          <font-awesome-icon
            v-if="errorState === 1"
            v-tooltip="{
              content: $t('general.delete'),
              boundary: 'window',
            }"
            icon="trash"
            class="text-white text-15 outline-none"
            @click.stop="deleteAction"
          />
          <ErrorPopup
            v-if="errorState !== 0"
            :title="$t('editor.this_action_contains_an_error')"
            :message="errorMessage"
            class="flex items-center justify-center"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    :class="[color]"
    class="h-35 w-full mt-1 flex items-center mb-3 cursor-move"
  >
    <div
      class="w-130 ml-15 flex bg-stepbg items-center"
      :class="{
        'bg-linearBlue': kind === 'linear',
      }"
    >
      <p class="ml-3 text-white">
        {{ name }}
      </p>
    </div>
  </div>
</template>

<script>
import FirstArrow from "./assets/FirstArrow";
import SecondArrow from "./assets/SecondArrow";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { mapState } from "vuex";
import scrollToSearchResult from "@/mixins/scrollToSearchResult";
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";

export default {
  name: "Action",
  components: {
    FirstArrow,
    SecondArrow,
    ErrorPopup,
  },
  mixins: [scrollToSearchResult],
  props: {
    kind: {
      type: String, // setup || listener || nonLinear || linear
      default: "setup",
    },
    id: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "S",
    },
    name: {
      type: String,
      default: "Title",
    },
    index: {
      type: Number,
      default: 0,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: Number,
      required: true,
    },
    stepId: {
      type: String,
      default: "",
    },
    lessonId: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    enabledList: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Boolean,
      default: false,
    },
    errorState: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isEnabled: this.enabled,
    };
  },
  computed: {
    ...mapState("editorStore", ["activeAction", "activeType", "activeStep"]),
    ...mapState("keyboardShortcutStore", ["showFullTitles"]),
    opacity: function () {
      if (
        this.isEnabled ||
        (this.id === this.activeAction.id && this.activeType === "action")
      ) {
        return "opacity-100";
      } else {
        return "opacity-25";
      }
    },
    color: function () {
      if (
        (!this.isEnabled && this.id !== this.activeAction.id) ||
        (this.activeType !== "action" && !this.isEnabled)
      ) {
        return "bg-gray-600";
      } else if (
        !this.isEnabled &&
        this.id === this.activeAction.id &&
        this.activeType === "action"
      ) {
        return "bg-activeAction";
      } else {
        if (this.id === this.activeAction.id && this.activeType === "action") {
          return "bg-activeAction";
        } else {
          return "bg-actionBlue";
        }
      }
    },
    borderColor: function () {
      if (this.errorState) {
        return "border-red-600";
      } else if (
        (!this.isEnabled && this.id !== this.activeAction.id) ||
        (this.activeType !== "action" && !this.isEnabled)
      ) {
        return "border-gray-600";
      } else if (!this.isEnabled && this.id === this.activeAction.id) {
        return "border-activeAction";
      } else {
        if (this.id === this.activeAction.id && this.activeType === "action") {
          return "border-activeAction";
        } else {
          return "border-actionBlue";
        }
      }
    },
    isFirst: function () {
      return this.enabledList.indexOf(true) === this.index;
    },
    isLast: function () {
      return this.enabledList.lastIndexOf(true) === this.index;
    },
    isMiddle: function () {
      return (
        this.enabledList.indexOf(true) < this.index &&
        this.enabledList.lastIndexOf(true) > this.index
      );
    },
    errorMessage: function () {
      if (this.errorState === 1) {
        return this.$t("editor.this_game_action_does_not_exist_anymore");
      } else if (this.errorState === 2) {
        return this.$t(
          "editor.there_is_an_problem_with_one_of_the_action_properties"
        );
      } else {
        return "";
      }
    },
  },
  watch: {
    enabled: {
      handler(val) {
        this.isEnabled = val;
      },
      deep: true,
    },
    targetId: {
      handler(val) {
        if (this.id === val) {
          this.setActive();
        }
      },
      deep: true,
    },
  },
  methods: {
    updateValue(e) {
      this.$emit("update:modelValue", e.target.value);
    },
    setActive() {
      if (this.errorState !== 1) {
        if (!this.selected) {
          this.$emit("setActiveStep");
        }
        this.$store.dispatch("editorStore/SET_ACTIVE_ACTION", {
          id: this.id,
          type: "edit",
          activeType: "action",
          stepId: this.stepId,
          sort: this.sort,
        });
      }
    },
    toggleEnabled() {
      this.isEnabled = !this.isEnabled;
      this.$emit("toggleEnabled", this.index);
      const action = {
        sort: this.sort,
        enabled: this.isEnabled,
        name: this.name,
      };

      this.$store.dispatch("editorStore/UPDATE_ACTION", {
        lessonId: this.lessonId,
        stepId: this.stepId,
        id: this.id,
        action: action,
      });
    },
    deleteAction() {
      this.$store.dispatch("editorStore/DELETE_ACTION", {
        lessonId: this.lessonId,
        actionStepId: this.stepId,
        actionId: this.id,
      });
    },
  },
};
</script>
