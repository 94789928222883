<template>
  <div
    id="container"
    class="flex justify-center items-center h-screen w-screen lg:py-loginPaddingTopBottom lg:px-loginPaddingLeftRight bg-loginBackgroundColor"
  >
    <div
      id="card"
      class="flex flex-row mobile:h-full w-full max-w-800 h-720 bg-white shadow-lightBg"
    >
      <!-- left side of card -->
      <LeftContainer />
      <!-- right side of card -->
      <div id="right" class="flex flex-1 flex-col bg-standardBgColor">
        <div
          v-if="!succeeded"
          class="flex flex-2 flex-row w-full justify-center items-center"
        >
          <p class="text-title text-darkAlineaColor mobile:hidden">
            {{ $t("auth.register").toUpperCase() }}
          </p>
          <p
            class="text-title text-darkAlineaColor mobile:items-center mobile:justify-center mobile:font-bold mobile:-mt-6 desktop:items-end desktop:mb-inputPadding desktop:ml-inputPadding desktop:hidden"
          >
            {{ $t("auth.virtualstudio") }}
          </p>
        </div>

        <div
          v-if="!succeeded"
          id="form-container"
          class="flex flex-9 mobile:flex-6 justify-center items-center flex-col px-inputPaddingLeftRight"
        >
          <Form
            @submit="registerUser"
            @keypress.enter="registerUser"
            v-slot="{ errors }"
          >
            <InputField
              :title="$t('auth.firstname')"
              type="text"
              placeholder=""
              id="firstname"
              v-model="firstname"
              :required-input="true"
              rules="min:2|max:255|required"
            />
            <InputField
              :title="$t('auth.lastname')"
              type="text"
              placeholder=""
              id="lastname"
              v-model="lastname"
              :required-input="true"
              rules="min:2|max:255|required"
            />

            <InputField
              class="mt-inputPadding w-full"
              :title="$t('auth.email')"
              type="email"
              id="email"
              placeholder=""
              v-model="email"
              :required-input="true"
              rules="required|email"
            />

            <InputField
              class="mt-inputPadding w-full"
              :title="$t('auth.password')"
              type="password"
              id="password"
              placeholder=""
              rules="required|min:8|min_one_upper_case|min_one_number"
              v-model="password"
              :required-input="true"
            />

            <InputField
              class="mt-inputPadding w-full"
              :title="$t('auth.repeat_password')"
              type="password"
              id="repeat-password"
              placeholder=""
              rules="required|min:8|min_one_upper_case|min_one_number"
              v-model="repeatPassword"
              :required-input="true"
            />

            <div class="mt-2">
              <Button
                class="mb-2"
                :title="$t('auth.register')"
                width="full"
                type="submit"
                button-type="primary"
                :invalid="Object.keys(errors).length !== 0"
                :loading="loading"
              />
              <Button
                :title="$t('auth.login')"
                button-type="secondary"
                width="full"
                @clickAction="$router.push({ name: 'login' })"
              />
            </div>
            <div
              v-for="(responseError, index) in responseErrors"
              :key="`responseErrors-${index}`"
            >
              <ErrorMessage class="mt-2" :error-message="responseError" />
            </div>
          </Form>
        </div>
        <div
          v-if="succeeded"
          class="flex flex-9 mobile:flex-3 justify-center items-center flex-col px-inputPaddingLeftRight"
        >
          <p class="text-22 font-medium text-darkAlineaColor text-center">
            {{ $t("auth.hooray") }} <br />
            {{ $t("auth.registration_succeeded") }}
          </p>
          <p class="text-17 text-darkAlineaColor text-center mt-30 mb-4">
            {{ $t("auth.succeeded_text") }}
          </p>
          <Button
            :title="$t('auth.login')"
            button-type="secondary"
            width="full"
            @clickAction="$router.push({ name: 'login' })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/form/InputField";
import Button from "@/components/form/Button";
import LeftContainer from "@/components/authentication/LeftContainer";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import ErrorMessage from "@/components/helpers/ErrorMessage";
const authRepository = RepositoryFactory.get("auth");

export default {
  name: "Register",
  components: { InputField, Button, LeftContainer, ErrorMessage },
  data: () => ({
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    enabled: false,
    repeatPassword: "",
    responseErrors: [],
    loading: false,
    succeeded: false,
  }),
  methods: {
    registerUser() {
      let { email, firstname, lastname, password, repeatPassword } = this;
      this.loading = true;
      this.responseErrors = [];
      if (this.password === this.repeatPassword) {
        authRepository
          .registerNewUser({ email, firstname, lastname, password })
          .then(() => {
            this.succeeded = true;
            this.loading = false;
          })
          .catch((err) => {
            if (err.response.status === 422) {
              err.response.data.data.violations.data.map((violation) => {
                const property = violation.property_path.replace(
                  /[\[\]']+/g,
                  ""
                );
                this.responseErrors.push(this.$t(`auth.${property}_error`));
              });
            } else {
              this.responseErrors.push(
                this.$t("auth.something_went_wrong_while_registering")
              );
            }
            this.loading = false;
          });
      } else {
        this.responseErrors.push(this.$t("auth.passwords_do_not_match"));
        this.loading = false;
      }
    },
    toLogin() {
      this.loading = true;
      this.$router.push({ name: "login" });
    },
  },
};
</script>
