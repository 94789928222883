import Vue from "vue";
import { showNotification } from "./notificationService";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { sentryDSN } from "@/config";

Sentry.init({
  dsn: sentryDSN,
  integrations: [
    new Integrations.BrowserTracing({
      app,
      attachProps: true,
      logErrors: true,
    }),
  ],
  blacklistUrls: "/^.*(localhost).*$/gi",
  release: JSON.stringify(require("../../package.json").version),
});

export function errorHandlingAndMessage(err, message) {
  Sentry.captureException(new Error(err));
  showNotification("Error", message, "error");
}
