<template>
  <ModalContainer @cancel="close">
    <div class="bg-white shadow sm:rounded-lg" v-click-outside="close">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ headerText }}
        </h3>
        <div
          class="w-96 mt-2 text-sm leading-5 text-gray-500 whitespace-normal"
        >
          <span class="break-words">{{ subHeaderText }}</span>
        </div>
        <div class="mt-5 sm:flex sm:items-center">
          <div class="w-full">
            {{ bodyText }}
          </div>
        </div>
        <div class="flex justify-end mt-5">
          <Button
            @clickAction="$emit('accept')"
            :loading="loading"
            button-type="editor-primary"
            :title="acceptButtonText"
          />
          <Button
            class="ml-3"
            @clickAction="close"
            button-type="editor-secondary"
            :title="cancelButtonText"
          />
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer";
import Button from "@/components/form/Button";

export default {
  name: "AcceptModal",
  components: { ModalContainer, Button },
  props: {
    headerText: {
      type: String,
      default: "",
    },
    subHeaderText: {
      type: String,
      default: "",
    },
    bodyText: {
      type: String,
      default: "",
    },
    acceptButtonText: {
      type: String,
      default: "",
    },
    cancelButtonText: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["accept", "cancel"],
  data: () => {
    return {
      test: "test",
    };
  },
  methods: {
    close() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
