<template>
  <ModalContainer>
    <div
      class="flex flex-col bg-gray-800 p-4 min-w-1/4 rounded-md max-w-3/4 max-h-3/4"
    >
      <SearchInput @getResults="getResults" @close="close" />

      <div v-if="loading" class="py-8 flex items-center justify-center">
        <Loader />
      </div>

      <div v-if="hasResults">
        <div class="flex flex-row justify-between">
          <h3 class="block text-base font-medium text-gray-50 mt-3">
            {{
              searchQuery
                ? `${$t("editor.results_for")} "${searchQuery}"`
                : $t("editor.all_steps_and_actions")
            }}
          </h3>
          <div
            class="block text-base font-light text-gray-50 mt-3 cursor-pointer hover:text-blue-400"
            @click="clearSearch"
          >
            {{ $t("editor.clear") }}
          </div>
        </div>
      </div>

      <div v-if="!hasResults && showResults">
        <h3 class="block text-base font-medium text-gray-50 mt-3">
          {{ $t("editor.no_results") }}
        </h3>
      </div>
      <div class="flex flex-row overflow-y-auto mt-3">
        <div
          v-if="linearSteps.length"
          class="flex flex-1 overflow-y-auto border-0.5 border-linearBlue m-1 scrollbar-thin scrollbar-thumb-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
        >
          <LinearStepResults
            :linear-step-results="linearSteps"
            @pickResult="pickResult"
          />
        </div>
        <div
          v-if="nonLinearSteps.length"
          class="flex flex-1 overflow-y-auto border-0.5 border-nonLinearBlue m-1 scrollbar-thin scrollbar-thumb-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
        >
          <NonLinearStepResults
            :non-linear-step-results="nonLinearSteps"
            @pickResult="pickResult"
          />
        </div>
        <div
          v-if="actions.length"
          class="flex flex-1 overflow-y-auto border-0.5 border-actionBlue m-1 scrollbar-thin scrollbar-thumb-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
        >
          <ActionResults :action-results="actions" @pickResult="pickResult" />
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../../../../../components/helpers/ModalContainer";
import Loader from "@/views/editor/components/Loader";
import SearchInput from "@/views/editor/components/modals/searchModal/components/SearchInput";
import ActionResults from "@/views/editor/components/modals/searchModal/components/ActionResults";
import LinearStepResults from "@/views/editor/components/modals/searchModal/components/LinearStepResults";
import NonLinearStepResults from "@/views/editor/components/modals/searchModal/components/NonLinearStepResults";
import { mapState } from "vuex";

export default {
  name: "ExportModal",
  components: {
    ModalContainer,
    Loader,
    ActionResults,
    LinearStepResults,
    NonLinearStepResults,
    SearchInput,
  },
  props: {
    activeTrack: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showResults: false,
    };
  },
  computed: {
    ...mapState("editorStore", ["activeLesson", "setupSteps", "mainSteps"]),
    ...mapState("searchStore", [
      "actions",
      "linearSteps",
      "nonLinearSteps",
      "searchQuery",
    ]),
    hasResults: function () {
      return (
        this.actions.length ||
        this.linearSteps.length ||
        this.nonLinearSteps.length
      );
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$store.dispatch("searchStore/HIDE_SEARCH_MODAL");
    },
    getResults(value) {
      this.clearSearch();

      this.loading = true;

      const search = {
        lessonId: this.activeLesson.id,
        query: { query: value },
      };

      this.$store.dispatch("searchStore/SEARCH", search).finally(() => {
        this.showResults = true;
        this.loading = false;
      });
    },
    pickResult(result) {
      this.$store.dispatch("searchStore/CLEAR_PICKED_RESULT");
      const isSetup = this.setupSteps.some((step) => {
        if (result.searchTarget === "action") {
          return step.id === result.searchStepId;
        } else {
          return step.id === result.searchTargetId;
        }
      });

      const isMain = this.mainSteps.some((step) => {
        return step.linear.id === result.searchStepId;
      });

      const trackNumber = isSetup ? 1 : 2;
      if (this.activeTrack !== trackNumber) {
        this.$emit("setTrack", trackNumber);
      }

      const target = {
        ...result,
        searchKind: isSetup ? "setup" : isMain ? "linear" : "nonLinear",
      };

      this.$nextTick(() => {
        this.$store.dispatch("searchStore/SET_PICKED_RESULT", target);
      });
      this.$store.dispatch("searchStore/HIDE_SEARCH_MODAL");
    },
    clearSearch() {
      this.$store.dispatch("searchStore/CLEAR_SEARCH");
    },
  },
};
</script>

<style></style>
