<template>
  <div class="w-full mt-1">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div
      class="mt-1 relative rounded-md shadow-sm"
      @focusin="disableShortcuts(true)"
      @focusout="disableShortcuts(false)"
    >
      <chrome-picker v-model="colorValue" disable-alpha="true"></chrome-picker>
      <Field
        :name="title"
        :rules="`${requiredInput ? 'required' : ''}||min:1`"
        v-model="colorValue"
        v-slot="{ handleChange, handleBlur }"
      >
        <input hidden type="text" @change="handleChange" @blur="handleBlur" />
      </Field>
      <div
        v-if="errors.length"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="h-6 mt-px">
      <p v-if="errors.length" class="text-sm text-red-600">
        {{ errors }}
      </p>
    </div>
  </div>
</template>

<script>
import { Chrome } from "@ckpack/vue-color";
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import { disableShortcuts } from "@/services/disableShortcutsService";
import { Field } from "vee-validate";
export default {
  name: "Color",
  components: { "chrome-picker": Chrome, ErrorPopup, Field },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Title",
    },
    defaultValue: {
      type: String,
      default: "000000",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      colorValue: "",
      errors: [],
    };
  },
  watch: {
    colorValue: {
      handler(val) {
        this.$emit("update:modelValue", val.hex);
      },
    },
  },
  mounted() {
    this.colorValue = this.modelValue ?? this.defaultValue;
  },

  methods: {
    disableShortcuts(boolean) {
      disableShortcuts(boolean);
    },
  },
};
</script>

<style>
.vc-chrome {
  width: 100% !important;
  box-shadow: 0 0 black;
  border-color: rgb(107, 114, 128);
  background-color: #252525 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  border: 1.1px solid #6b7280 !important;
}

.vc-chrome-body {
  background-color: #252525 !important;
}
</style>
