export default {
  "auth": {
    "account_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account activation"])},
    "activation_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!! Something went wrong, the activation code is no longer valid."])},
    "activation_succes_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeah!! Your account has been activated, we've sent you an email to reset your password. Have fun playing!"])},
    "check_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check code"])},
    "eight_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eight characters minimum"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address not available."])},
    "email_in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This e-mail address is already in use, try the 'forgot password' option on the login page."])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "firstname_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name does not meet the constraints. It should be between 2 and 255 characters"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "hooray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOORAY!"])},
    "invalid_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination of username and password do not match"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "lastname_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name does not meet the constraints. It should be between 2 and 255 characters"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "one_lowercase_letter_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One lowercase letter required"])},
    "one_number_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One number required"])},
    "one_or_more_fields_do_not_meet_the_constraints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more fields do not meet the constraints"])},
    "one_uppercase_letter_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One uppercase letter required"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is not valid"])},
    "password_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password errors"])},
    "password_is_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is not valid"])},
    "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "register_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register account"])},
    "registration_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration succeeded"])},
    "remember_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "some_required_fields_are_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some required fields are empty"])},
    "something_went_wrong_while_registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while registering"])},
    "succeeded_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an email send to you with the activation link, please activate your account within 24 hours. Have fun gaming!"])},
    "the_reset_link_is_no_longer_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reset link is no longer valid"])},
    "there_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are"])},
    "there_is_an_email_send_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an email sent to"])},
    "to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
    "virtualstudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VirtualStudio©"])},
    "welcome_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome to"])},
    "where_you_can_reset_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["where you can reset your password."])},
    "wrong_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong email"])},
    "you_have_entered_an_invalid_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have entered an invalid username or password"])}
  },
  "breadcrumbs": {
    "active-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active users"])},
    "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
    "customers/": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customers/"])},
    "customers/create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customers/create"])},
    "customers/edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customers/edit"])},
    "customersCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create customer"])},
    "customersDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer details"])},
    "customersEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit customer"])},
    "dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealers"])},
    "dealersCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create dealer"])},
    "dealersDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer details"])},
    "dealersEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit dealer"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "gameCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game characters"])},
    "gameCharactersCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add game character"])},
    "gameCharactersEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit game character"])},
    "highscores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highscores"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
    "lesson-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level scores"])},
    "lesson-times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level times"])},
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
    "playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playtime"])},
    "playtime-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playtime scores"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])},
    "reporting/": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])},
    "reporting/active-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting/active-user"])},
    "reporting/download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting/download"])},
    "reporting/lesson-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting/level-score"])},
    "reporting/lesson-times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting/level-times"])},
    "reporting/playtime-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting/playtime-score"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "results-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results/details"])},
    "results/": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "settings/parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings/parameters"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "usersDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User details"])},
    "versions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versions"])}
  },
  "button": {
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "delete_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOWNLOAD"])},
    "existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add existing user"])},
    "new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add new user"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating..."])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading..."])}
  },
  "customers": {
    "activation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation code"])},
    "add_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add customer"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers Overview"])},
    "customers_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create customer"])},
    "customers_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer details"])},
    "customers_details_and_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer details and application"])},
    "customers_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit customer"])},
    "customers_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers' users"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "nr_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr. Customer"])},
    "nr_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nr. users"])},
    "number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of users"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "stop_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop date"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
  },
  "dealers": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "add_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add dealer"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "add_user_to_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user to customer"])},
    "add_user_to_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user to dealer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealership customers"])},
    "dealer_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create dealer"])},
    "dealer_details_and_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer details and application"])},
    "dealer_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit dealer"])},
    "dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealers Overview"])},
    "dealership_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealership users"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "generate_user_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate user activity report"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "no_available_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available users"])},
    "nr_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr. Customer"])},
    "number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of users"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "select_a_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date range"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "stop_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop date"])}
  },
  "download": {
    "Download_failed_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download failed, please try again."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "download_succeeded_check_your_download_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download succeeded, check your download folder."])},
    "generate_user_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate user activity report"])},
    "here_is_your_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your report"])},
    "select_a_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date range"])},
    "the_download_link_can_be_found_in_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The download link can be found in Slack"])},
    "this_form_will_close_in_5_seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This dialog will close in 5 seconds..."])},
    "user_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User activity report"])},
    "user_activity_report_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User activity report download"])},
    "you_did_not_download_your_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not download your file"])}
  },
  "editor": {
    "action_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action deleted"])},
    "action_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action is successfully deleted"])},
    "action_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action properties"])},
    "action_property_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action property translations"])},
    "action_selector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection action"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "add_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Action"])},
    "add_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Chapter"])},
    "add_first_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add first action"])},
    "add_game_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add game character"])},
    "add_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add item"])},
    "add_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add level"])},
    "add_new_linear_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new linear step"])},
    "add_new_setup_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new setup step"])},
    "add_non_linear_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Non Linear Step"])},
    "add_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subchapter"])},
    "add_to_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to checklist"])},
    "all_steps_and_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All steps and actions"])},
    "are_you_sure_you_want_to_delete_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this action?"])},
    "are_you_sure_you_want_to_delete_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this chapter?"])},
    "are_you_sure_you_want_to_delete_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this level?"])},
    "are_you_sure_you_want_to_delete_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this step?"])},
    "are_you_sure_you_want_to_delete_this_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this subchapter?"])},
    "behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behaviour"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "constraint_error_the_value_of_this_action_property_does_not_meet_the_constraint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constraint error: The value of this action property does not meet the constraint"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard!"])},
    "correct_behavior_the_player_will_be_rewarded_with_score_when_finishing_this_step_otherwise_the_player_will_lose_the_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct behavior, the player will be rewarded with score when finishing this step otherwise the player will lose the score"])},
    "correct_behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct behaviour"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "create_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create step"])},
    "delete_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete action"])},
    "delete_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete chapter"])},
    "delete_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete level"])},
    "delete_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete step"])},
    "delete_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete subchapter"])},
    "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
    "edit_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Chapter"])},
    "edit_game_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit game character"])},
    "edit_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit level"])},
    "edit_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit mode"])},
    "edit_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Subchapter"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
    "enabled_or_disable_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable or disable this step"])},
    "enter_a_name_for_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a name for this step"])},
    "enter_edit_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter edit mode"])},
    "error_file_has_not_been_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Something went wrong while uploading this file, please try again"])},
    "exit_edit_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit edit mode"])},
    "export_lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export lesson"])},
    "export_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export step"])},
    "export_this_lesson_as_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export this lesson as a JSON file"])},
    "export_this_step_as_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export this step as a JSON file"])},
    "export_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export translations"])},
    "export_translations_as_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export translations as CSV"])},
    "go_to_game_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to game characters"])},
    "go_to_game_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to game translations"])},
    "go_to_score_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to score overview"])},
    "go_to_trophies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to trophies"])},
    "import_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import completed"])},
    "import_lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import lesson"])},
    "import_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import step"])},
    "import_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import translations"])},
    "intended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intended"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
    "is_on_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is on the checklist"])},
    "is_vr_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Reality ready"])},
    "jump_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump to"])},
    "level_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level translations"])},
    "linear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear"])},
    "linear_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear properties"])},
    "linear_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear steps"])},
    "listen_to_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen to preview"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "main_steps_container_number_steps": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Main steps container (", _interpolate(_named("number")), " steps)"])},
    "max_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max score"])},
    "maximum_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum score"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "new_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New audio"])},
    "new_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New video"])},
    "no_action_or_step_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No action or step selected"])},
    "no_game_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you can edit the game content you have to add a game and sync the game content.."])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "non_linear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Linear"])},
    "non_linear_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non linear properties"])},
    "non_linear_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non linear random"])},
    "non_linear_random_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non linear random number"])},
    "non_linear_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non linear steps"])},
    "number_of_possible_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of possible repeats"])},
    "opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity"])},
    "opportunity_the_player_will_be_rewarded_with_points_when_finishing_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity, the player will be rewarded with points when finishing this step"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "passing_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passing percentage"])},
    "preparing_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...preparing download"])},
    "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Properties"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "regenerate_tts_confirmation_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regenerating all text to speech for a level will incur costs."])},
    "regenerate_tts_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay attention!"])},
    "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
    "repeatable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeatable"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "required_this_is_a_required_step_to_execute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required, this is a required step to execute"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "results_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results for"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "score_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score overview"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "select_a_valid_json_and_upload_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a valid JSON and upload it"])},
    "select_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select game"])},
    "select_game_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select game version"])},
    "selected_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected game"])},
    "selected_game_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected game version"])},
    "setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup"])},
    "setup_steps_container_number_steps": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Setup steps container (", _interpolate(_named("number")), " steps)"])},
    "something_went_wrong_deleting_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while deleting this action"])},
    "sort_chapters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort chapters"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
    "step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step name"])},
    "synced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synced"])},
    "the_step_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The step is successfully created"])},
    "there_is_an_problem_with_one_of_the_action_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a problem with one of the action properties. Open this action to inspect this issue"])},
    "this_action_contains_an_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action contains an error!"])},
    "this_action_property_contains_an_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action property contains an error!"])},
    "this_game_action_does_not_exist_anymore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This game action doesn't exist anymore, delete this action to fix this issue"])},
    "this_lesson_contains_an_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This lesson contains an error"])},
    "this_step_contains_an_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step contains an error!"])},
    "total_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total score"])},
    "total_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total steps"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "type_error_the_value_of_this_action_property_does_not_have_the_correct_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field contains an invalid value (type or options have change)"])},
    "upload_csv_to_import_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a CSV file to import translations"])},
    "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPLOAD"])},
    "upload_json_to_import_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a valid JSON file to import a step"])},
    "upload_json_to_lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload JSON to import a lesson"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...uploading"])},
    "value_must_be_either_empty_or_0_or_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value should be either empty or 0 or more"])},
    "your_download_is_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your download is ready!"])}
  },
  "error": {
    "oops_something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Something went wrong, please contact us."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "to_home_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To home page"])},
    "you_dont_access_to_this_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have access to this page."])}
  },
  "fields": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "repeatpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])}
  },
  "game": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "add_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New game"])},
    "app_store_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App store url"])},
    "are_you_sure_you_want_to_delete_this_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this game"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "create_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create game"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "edit_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit game"])},
    "editors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editors"])},
    "game_started_in_a_new_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game has been opened in a new window!"])},
    "google_play_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google play url"])},
    "if_nothing_happens_please_make_sure_the_game_has_been_downloaded_on_your_computer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If nothing happens, please make sure the game has been downloaded on your computer."])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "online_gameplay_is_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing the game in your browser is not availabe right now"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am sure"])},
    "the_game_will_automatically_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game will automatically open"])},
    "this_game_will_be_deleted_permanently": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This game will be deleted permanently"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload image"])},
    "web_gl_memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web GL memory"])}
  },
  "gamecharacters": {
    "add_game_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add game character"])},
    "press_enter_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select this voice"])},
    "remove_this_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this voice"])},
    "select_a_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a voice"])}
  },
  "general": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "download_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the game"])},
    "enter_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name..."])},
    "exported_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exported on"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename"])},
    "file_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish now"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "uploaded_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded on"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])}
  },
  "highscore": {
    "choose_different_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose different game"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "highscores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highscores"])},
    "no_highscores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No highscores"])},
    "no_level_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No level selected"])},
    "no_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No levels"])},
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select level"])},
    "something_went_wrong_while_getting_highscores_for_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while getting highscores for this level"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])}
  },
  "home": {
    "alternative_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative downloads"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])},
    "download_and_play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download & play"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "no_alternative_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No alternative downloads"])},
    "not_available_on_this_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available on this device"])},
    "play_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play now"])}
  },
  "layout": {
    "You_are_working_in_demo_mode_to_activate_your_account_press_the_button_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are working in Demo mode, to activate your account press the button below"])},
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activate"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "generate_user_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate user activity report"])},
    "please_fill_in_the_required_activation_code_you_received_from_your_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the required activation code you received from your company"])},
    "reset_password_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email has been sent to you. Please check your inbox!"])},
    "reset_password_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are going to send you an email to reset your password. Are you sure you want to reset your current password?"])},
    "reset_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "select_a_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date range"])},
    "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "you_dont_have_a_student_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have a student role"])},
    "you_have_been_redirected_to_our_new_application_please_use_this_link_in_the_future_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been redirected to our new application, please use this link in the future to login"])},
    "you_have_entered_an_invalid_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have entered an invalid username or password"])}
  },
  "media": {
    "finalizing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizing"])},
    "no_media_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No media yet"])},
    "or_drag_and_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop"])},
    "recent_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent files"])},
    "select_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select media"])},
    "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
    "upload_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Media"])},
    "upload_translation_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload translation file (csv or json)"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading"])}
  },
  "navigation": {
    "add_game_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add game character"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMIN"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
    "dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealers"])},
    "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
    "game_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game characters"])},
    "highscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highscore"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My results"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SETTINGS"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
  },
  "notifications": {
    "action_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action deleted"])},
    "action_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action is successfully deleted"])},
    "actions_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action added"])},
    "actions_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action edited"])},
    "actions_was_successfully_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added action"])},
    "actions_was_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action was successfully edited"])},
    "add_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add action"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "chapter_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter created"])},
    "chapter_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter deleted"])},
    "chapter_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter edited"])},
    "chapter_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter is successfully created"])},
    "chapter_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter is succesfully deleted"])},
    "chapter_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter is successfully edited"])},
    "chapter_is_successfully_sorted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter is successfully sorted"])},
    "character_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character saved"])},
    "character_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character deleted"])},
    "character_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character saved"])},
    "character_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character successfully created"])},
    "character_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character successfully deleted"])},
    "character_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character successfully edited"])},
    "code_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code created"])},
    "code_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code deleted"])},
    "code_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code edited"])},
    "code_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code is successfully created"])},
    "code_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code is successfully edited"])},
    "copied_id_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID copied to clipboard"])},
    "copy_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy ID"])},
    "copy_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy step"])},
    "create_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create level"])},
    "create_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create step"])},
    "customer_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer created"])},
    "customer_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer deleted"])},
    "customer_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer edited"])},
    "customer_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer is successfully created"])},
    "customer_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer is successfully edited"])},
    "cut_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut step"])},
    "dealer_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer created"])},
    "dealer_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer deleted"])},
    "dealer_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer edited"])},
    "dealer_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer is successfully created"])},
    "dealer_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer is successfully edited"])},
    "delete_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted build"])},
    "delete_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Step"])},
    "delete_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete version"])},
    "domain_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain created"])},
    "domain_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain deleted"])},
    "domain_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain edited"])},
    "duplicate_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate step"])},
    "edit_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit action"])},
    "edit_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit level"])},
    "edit_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit step"])},
    "editing_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing went wrong"])},
    "email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent"])},
    "filereader_api_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filereader API not supported"])},
    "game_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game created"])},
    "game_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game deleted"])},
    "game_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game edited"])},
    "game_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is successfully created"])},
    "game_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is successfully deleted"])},
    "game_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is successfully edited"])},
    "import_lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import lesson"])},
    "import_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import step"])},
    "invalid_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination of username and password do not match"])},
    "lesson_has_been_successfully_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson has been successfully imported"])},
    "level_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level created"])},
    "level_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level deleted"])},
    "level_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level edited"])},
    "level_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level is successfully created"])},
    "level_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level is successfully deleted"])},
    "level_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level is successfully edited"])},
    "level_is_successfully_sorted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level is successfully sorted"])},
    "login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed!"])},
    "no_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user"])},
    "no_user_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user selected"])},
    "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset"])},
    "paste_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste action"])},
    "paste_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste step"])},
    "pasted_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste step"])},
    "please_select_an_image_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an image file"])},
    "product_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Field"])},
    "product_field_has_been_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This product field has been successfully edited"])},
    "publish_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training published"])},
    "publish_training_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish training error"])},
    "regenerate_tts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regenerate TTS"])},
    "something_went_while_sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while sorting"])},
    "something_went_wrong_activating_your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong activating your account"])},
    "something_went_wrong_creating_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong creating customers"])},
    "something_went_wrong_creating_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong creating the game"])},
    "something_went_wrong_creating_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong creating this chapter"])},
    "something_went_wrong_creating_this_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while creating this game character"])},
    "something_went_wrong_creating_this_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong creating this dealer"])},
    "something_went_wrong_creating_this_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong creating this training"])},
    "something_went_wrong_creating_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while creating this user"])},
    "something_went_wrong_deleting_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong deleting customer"])},
    "something_went_wrong_deleting_the_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while deleting this build"])},
    "something_went_wrong_deleting_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong deleting the game"])},
    "something_went_wrong_deleting_the_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong deleting the user"])},
    "something_went_wrong_deleting_the_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while deleting this version"])},
    "something_went_wrong_deleting_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while deleting this action"])},
    "something_went_wrong_deleting_this_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong deleting this game character"])},
    "something_went_wrong_deleting_this_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong deleting this training"])},
    "something_went_wrong_downloading_the_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong downloading the csv"])},
    "something_went_wrong_editing_the_TTS_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong editing the TTS voice"])},
    "something_went_wrong_editing_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong editing the game"])},
    "something_went_wrong_editing_the_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while editing this user"])},
    "something_went_wrong_editing_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong editing this chapter"])},
    "something_went_wrong_editing_this_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong editing this training"])},
    "something_went_wrong_editing_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while editing your data"])},
    "something_went_wrong_fetching_dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong fetching dealers"])},
    "something_went_wrong_fetching_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while fetching media"])},
    "something_went_wrong_getting_the_activation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong getting the activation code"])},
    "something_went_wrong_getting_the_current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong getting the current user"])},
    "something_went_wrong_publishing_the_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while publishing this training"])},
    "something_went_wrong_receiving_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong receiving customers"])},
    "something_went_wrong_receiving_dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong receiving dealers"])},
    "something_went_wrong_receiving_game_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong receiving game details"])},
    "something_went_wrong_receiving_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong receiving user"])},
    "something_went_wrong_receiving_user_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong receiving user details"])},
    "something_went_wrong_receiving_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong receiving users"])},
    "something_went_wrong_refreshing_the_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong refreshing the token"])},
    "something_went_wrong_resetting_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong resetting the password"])},
    "something_went_wrong_saving_the_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong saving the user data"])},
    "something_went_wrong_updating_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong updating customers"])},
    "something_went_wrong_updating_this_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while saving this game character"])},
    "something_went_wrong_updating_this_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong updating this dealer"])},
    "something_went_wrong_uploading_the_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while uploading this version"])},
    "something_went_wrong_uploading_this_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while uploading this file"])},
    "something_went_wrong_uploading_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while importing translations"])},
    "something_went_wrong_while_adding_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while adding this action"])},
    "something_went_wrong_while_attempting_to_start_this_game_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while attempting to start this game, please try again"])},
    "something_went_wrong_while_converting_text_to_speech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while converting text to speech"])},
    "something_went_wrong_while_creating_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while creating this level"])},
    "something_went_wrong_while_deleting_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while deleting this chapter"])},
    "something_went_wrong_while_deleting_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while deleting this step"])},
    "something_went_wrong_while_duplicating_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while duplicating this action"])},
    "something_went_wrong_while_duplicating_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while duplicating this step"])},
    "something_went_wrong_while_editing_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while editing this action"])},
    "something_went_wrong_while_editing_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while editing this level"])},
    "something_went_wrong_while_editing_this_product_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while editing this product field"])},
    "something_went_wrong_while_editing_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while editing this step"])},
    "something_went_wrong_while_exporting_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while exporting this translation"])},
    "something_went_wrong_while_fetching_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while fetching the product fields"])},
    "something_went_wrong_while_fetching_product_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while fetching the product fields"])},
    "something_went_wrong_while_fetching_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while fetching this level"])},
    "something_went_wrong_while_getting_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while getting this action"])},
    "something_went_wrong_while_importing_this_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while importing this JSON"])},
    "something_went_wrong_while_importing_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while importing this translation"])},
    "something_went_wrong_while_moving_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while moving this action"])},
    "something_went_wrong_while_moving_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while moving this step"])},
    "something_went_wrong_while_regenerating_tts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while regenerating TTS"])},
    "something_went_wrong_while_selecting_this_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while selecting this version"])},
    "something_went_wrong_while_sorting_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while sorting this chapter"])},
    "something_went_wrong_while_sorting_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while sorting this level"])},
    "something_went_wrong_while_sorting_this_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while sorting this subchapter"])},
    "something_went_wrong_while_uploading_this_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while uploading this file"])},
    "sort_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort chapter"])},
    "sort_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort level"])},
    "sort_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort subchapter"])},
    "step_copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step copied to clipboard"])},
    "step_cut_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step cut to clipboard"])},
    "step_has_been_successfully_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step has been successfully imported"])},
    "step_pasted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step pasted"])},
    "step_property_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step property updated"])},
    "step_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step updated"])},
    "sub_chapter_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub chapter created"])},
    "sub_chapter_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Chapter deleted"])},
    "sub_chapter_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub chapter edited"])},
    "sub_chapter_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub chapter is successfully created"])},
    "sub_chapter_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Chapter is successfully deleted"])},
    "sub_chapter_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub chapter is successfully edited"])},
    "subchapter_is_successfully_sorted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter is successfully sorted"])},
    "successfully_activated_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You account is now activated. All levels are unlocked!"])},
    "successfully_activated_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully activated!"])},
    "successfully_deleted_the_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build successfully deleted"])},
    "successfully_deleted_the_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version successfully deleted"])},
    "successfully_published_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training successfully published"])},
    "successfully_uploaded_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version successfully uploaded"])},
    "text_to_speech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text to speech"])},
    "the_step_is_succesfully_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step is successfully moved"])},
    "the_step_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step has been successfully created"])},
    "the_step_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The step is successfully deleted"])},
    "the_step_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step has been successfully edited"])},
    "the_user_is_successfully_added_to_the_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user is successfully added to dealer"])},
    "the_user_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user is successfully edited"])},
    "there_are_no_users_to_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no users to add"])},
    "there_is_no_customer_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is no customer selected"])},
    "there_is_no_user_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no user selected"])},
    "this_action_has_been_successfully_pasted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this action has been successfully pasted"])},
    "this_step_has_been_successfully_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step has been successfully duplicated"])},
    "this_step_has_been_successfully_pasted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step has been successfully pasted"])},
    "translation_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation import"])},
    "translation_successfully_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation successfully imported"])},
    "trophy_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophy created"])},
    "trophy_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophy deleted"])},
    "trophy_editted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophy edited"])},
    "trophy_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophy is successfully created"])},
    "trophy_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophy is successfully deleted"])},
    "trophy_is_successfully_editted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophy is successfully edited"])},
    "tts_regeneration_has_been_initialized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text to speech is being regenerated for this level"])},
    "unable_to_start_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to start game"])},
    "upload_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload version"])},
    "user_added_to_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added to dealer"])},
    "user_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User deleted"])},
    "user_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User edited"])},
    "user_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User invited"])},
    "user_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is successfully deleted"])},
    "user_is_successfully_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is successfully invited"])},
    "user_is_successfully_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is successfully updated"])},
    "user_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User updated"])}
  },
  "optionsmenu": {
    "addAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add action"])},
    "addLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add level"])},
    "addLinearStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new linear step"])},
    "addNonLinearStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new non linear step"])},
    "addSetupStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new setup step"])},
    "addSubChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add subchapter"])},
    "codeBranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game versions"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Game"])},
    "deleteChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete chapter"])},
    "deleteLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete level"])},
    "deleteSubChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete subchapter"])},
    "downloadExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download export"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit game"])},
    "editChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit chapter"])},
    "editLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit level"])},
    "editSubChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit subchapter"])},
    "exportChapterTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export chapter translations"])},
    "exportLevelTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Translations"])},
    "exportTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export translations"])},
    "importChapterTranslations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import chapter translations"])},
    "importLesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import lesson"])},
    "importLevelTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Translations"])},
    "importLinearStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import linear step"])},
    "importNonLinearStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import non linear step"])},
    "importSetupStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import setup step"])},
    "importTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import translations"])},
    "keyboardShortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyboard shortcuts"])},
    "levelTranslations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media translations"])},
    "pasteAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste action"])},
    "pasteStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste step"])},
    "recentTranslationFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently exported translation files"])},
    "regenerateTts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regenerate TTS"])}
  },
  "pagination": {
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
    "total_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total items"])}
  },
  "profile": {
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "cancel_change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel password change "])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "change_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change picture"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crop"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "member_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member for"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "rotate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotate"])},
    "set_profile_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set profile picture"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "reporting": {
    "active_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active users"])},
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottom"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "hours_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours:Minutes"])},
    "level_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level scores"])},
    "level_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level times"])},
    "loading_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading content"])},
    "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
    "my_playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My playtime"])},
    "name_a_z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (A-Z)"])},
    "name_z_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (Z-A)"])},
    "number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of users"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
    "playtime_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playtime scores"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort:"])},
    "the_end_date_is_before_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid range: The end date is before the start date"])},
    "the_start_date_is_before_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid range: The start date is before the end date"])},
    "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
    "total_playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total playtime"])},
    "user_playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User playtime"])},
    "value_high_low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value (High-Low)"])},
    "value_low_high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value (Low-High)"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version:"])}
  },
  "results": {
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "attemps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts"])},
    "avg_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average time"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "days_since_last_play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since last play"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "filter_failed_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed games"])},
    "filter_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "filter_passed_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed games"])},
    "filter_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "filter_unfinished_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfinished games"])},
    "game_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game results"])},
    "games_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games completed"])},
    "games_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games failed"])},
    "games_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games played"])},
    "highscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highscore"])},
    "hours_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours played"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "level_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level name"])},
    "level_passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level passed"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
    "max_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max score"])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "no_time_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time set"])},
    "no_trophies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No trophies"])},
    "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
    "passing_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passing percentage"])},
    "penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalty"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
    "personal_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal results"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "score_of_the_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score of the required"])},
    "score_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score percentage"])},
    "scored_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scored points"])},
    "search_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search level"])},
    "the_average_points_earned_in_this_level_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The average points earned in this level are"])},
    "the_average_score_of_this_level_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The average score of this level is"])},
    "the_average_time_spent_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The average time spent is"])},
    "the_original_lesson_where_this_result_was_scored_has_been_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: The original lesson where this result was scored has been removed."])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "total_penalties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total penalties"])},
    "total_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total score"])},
    "trophies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophies"])},
    "well_done_youve_completed_the_test_within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done! you’ve completed the test within"])},
    "where_the_average_score_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where the average score is"])},
    "you_scored_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You scored a"])}
  },
  "roles": {
    "ROLE_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "ROLE_CUSTOMER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Admin"])},
    "ROLE_DEALER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer Admin"])},
    "ROLE_EDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
    "ROLE_STUDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
    "ROLE_SUPER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
    "ROLE_TEACHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "ROLE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "ROLE_USER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Admin"])}
  },
  "settings": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accept"])},
    "add_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add voice"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "default_text_to_speech_voices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Text to Speech voices"])},
    "default_text_to_speech_voices_descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a default voice per locale"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "header_voices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete voices"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "subheader_voices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete all the voices?"])}
  },
  "shortcuts": {
    "collapse_all_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse all visible steps"])},
    "copy_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy ID"])},
    "copy_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy step"])},
    "copy_step_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy step/action"])},
    "cut_step_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut step/action"])},
    "duplicate_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate step"])},
    "editor_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor shortcuts"])},
    "expand_all_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand all visible steps"])},
    "keyboard_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyboard shortcuts"])},
    "paste_step_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste step/action"])},
    "quick_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick actions"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "show_full_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle full title"])},
    "timeline_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline shortcuts"])},
    "toggle_shortcut_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle shortcuts menu"])}
  },
  "streaming": {
    "navigate_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
    "navigate_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigating away will stop your current play session."])},
    "navigate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you navigate away to another page, your session will be lost."])}
  },
  "sync": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
    "copy_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy actions"])},
    "copy_lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy lessons"])},
    "copy_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy steps"])},
    "diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diff"])},
    "diff_against_unity_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparing against Unity package"])},
    "diff_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diff status"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done!"])},
    "finish_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish sync"])},
    "incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor"])},
    "start_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start sync"])},
    "starting_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting sync"])},
    "sync_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync level"])},
    "sync_phases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync phases"])},
    "sync_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync ready"])},
    "sync_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync status"])}
  },
  "translation": {
    "JsonAudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "JsonImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "JsonVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "are_you_sure_you_want_to_delete_this_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you can to delete this file?"])},
    "are_you_sure_you_want_to_delete_this_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this media?"])},
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "delete_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete file"])},
    "delete_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete media"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "last_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last changed"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "select_locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select locale"])},
    "showing_start_to_end_of_total_results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("start")), " to ", _interpolate(_named("end")), " of ", _interpolate(_named("total")), " results"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
    "this_media_translation_belongs_to_the_default_language_deleting_this_will_delete_all_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This media translation belongs to the default language. Deleting this will also delete all media from the other languages."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])}
  },
  "trophies": {
    "add_new_trophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this as a new trophy"])},
    "add_trophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add trophy"])},
    "edit_trophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit trophy"])},
    "remove_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to remove"])},
    "search_for_trophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a trophy"])},
    "select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to select"])},
    "selected_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "trophies_earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophies earned"])},
    "trophies_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trophies required"])}
  },
  "tts": {
    "aws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS"])},
    "gamecharacter_change_regenerate_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you change a voice for a game character, the voice will only be used when the text to speech is regenerated."])},
    "google_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Cloud"])},
    "neural_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a voice has a 'neural' option, it will be used."])}
  },
  "users": {
    "a_user_has_to_be_assigned_to_either_a_customer_or_a_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user has to be assigned to either a customer or a dealer"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "create_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create user"])},
    "created_at_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["User created on ", _interpolate(_named("date"))])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
    "dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealers"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this account? All of the data will be permanantly removed. This action cannot be undone"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "edit_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email_adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "game_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game results"])},
    "hold_shift_for_multiple_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hold shift for multiple select"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "last_played_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last played at"])},
    "last_time_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last played at"])},
    "last_version_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last version played"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member since"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "users_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users overview"])}
  },
  "validation": {
    "messages": {
      "min_one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 number required"])},
      "min_one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 uppercase character required"])}
    }
  },
  "versions": {
    "are_you_sure_you_want_to_delete_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this build?"])},
    "are_you_sure_you_want_to_delete_this_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this version?"])},
    "delete_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete build"])},
    "delete_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete version"])},
    "deleting_versions_happens_in_the_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting a version will process in the background"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
  },
  "warning": {
    "are_you_sure_you_want_to_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am sure"])},
    "there_is_data_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is data changed"])}
  }
}