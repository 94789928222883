<template>
  <div id="furioos">
    <div id="furioos_container"></div>
    <AcceptModal
      class="text-left"
      v-if="showNavigateModal"
      :header-text="$t('streaming.navigate_header')"
      :sub-header-text="$t('streaming.navigate_subheader')"
      :body-text="$t('streaming.navigate_text')"
      :accept-button-text="$t('general.accept')"
      :cancel-button-text="$t('general.cancel')"
      @accept="navigate"
      @cancel="cancelNavigate"
    />
  </div>
</template>

<script>
import { Player, FS_SDK_EVENTS_NAME, FS_QUALITY_VALUES } from "furioos-sdk";
import AcceptModal from "@/components/helpers/modals/AcceptModal";
import { showNotification } from "@/services/notificationService";
import GameplayRepository from "../../../repository/GameplayRepository";

export default {
  name: "Play",
  components: { AcceptModal },

  beforeRouteLeave(to, from, next) {
    if (this.navigateTo) {
      next();
    } else {
      this.navigateTo = to;
      if (!this.disableNavigationWarningModal) {
        this.showNavigateModal = true;
      }
    }
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      canResumeSession: false,
      player: null,
      pin: 0,
      showNavigateModal: false,
      navigateTo: null,
      gameSession: null,
      disableNavigationWarningModal: false,
    };
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNavigation);
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.preventNavigation);
  },

  mounted() {
    this.startSession();
  },

  methods: {
    startSession() {
      GameplayRepository.create(this.id, this.versionId).then((response) => {
        this.gameSession = response.data.data;
        if (this.gameSession.furioos_share.link_id) {
          this.disableNavigationWarningModal = false;
          this.retrievePin();
        } else {
          this.disableNavigationWarningModal = true;
          showNotification(
            this.$t("general.not_available"),
            this.$t("game.online_gameplay_is_not_available"),
            "error"
          );
          // Not available fallback scenario
          setTimeout(() => {
            window.location = "/";
          }, 3000);
        }
      });
    },

    initializePlayer(furioosLinkId) {
      this.player = new Player(furioosLinkId, "furioos_container", {
        whiteLabel: true,
        hideTitle: true,
        hideToolbar: false,
        hidePlayButton: true,
        debugAppMode: false,
        inactiveTimeout: 120000, // 2 minutes
      });

      const self = this;

      // Resume session when possible
      self.player.on(
        FS_SDK_EVENTS_NAME.ON_RESUME_SESSION,
        function ({ canResumeSession }) {
          self.canResumeSession = canResumeSession;
          if (canResumeSession) {
            self.player.resumeSession();
          }
        }
      );

      // Start game on load
      self.player.on(FS_SDK_EVENTS_NAME.LOAD, function () {
        setTimeout(() => {
          if (!self.canResumeSession) {
            self.player.start();
          }
        }, 3000);
      });

      // Bind SDK messages
      self.player.on(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE, function (data) {
        var message = JSON.parse(data);
        if (message.login_available) {
          if (self.pin) {
            self.player.sendSDKMessage({
              login_token: self.pin,
            });
          }
        }
      });
    },

    retrievePin: function () {
      this.$store
        .dispatch("userStore/GET_USER_PINCODE", this.id)
        .then((res) => {
          this.pin = res.data.pin;
          this.initializePlayer(this.gameSession.furioos_share.link_id);
        })
        .catch(() => {
          showNotification(
            this.$t("notifications.automatic_login_not_available"),
            this.$t("notifications.please_login_manually"),
            "error"
          );
        });
    },

    preventNavigation(event) {
      if (!this.disableNavigationWarningModal) {
        event.preventDefault();
        event.returnValue = "";
      }
    },

    cancelNavigate: function () {
      this.showNavigateModal = false;
      this.navigateTo = null;
    },

    navigate: function () {
      this.showNavigateModal = false;
      this.player.stop();
      this.$router.push(this.navigateTo);
    },
  },
};
</script>

<style scoped>
#furioos,
#furioos_container {
  width: 100%;
  height: 100%;
}
</style>
